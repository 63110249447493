import * as React from 'react';

export const AddDataIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <g>
      <g>
        <path
          d="m11.55,9.75C5.59,9.75.75,7.74.75,5.25S5.59.75,11.55.75s10.8,2.01,10.8,4.5c0,1.33-1.38,2.52-3.57,3.34"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="m22.35,10.53v-5.28"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="m.75,5.25v6c0,1.97,3.03,3.64,7.26,4.25"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="m.75,11.25v6c0,2.17,3.7,3.99,8.62,4.41"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="m16.59,23.25c3.18,0,5.76-2.69,5.76-6s-2.58-6-5.76-6-5.76,2.69-5.76,6,2.58,6,5.76,6Z"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="m16.59,14.25v6"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="m13.71,17.25h5.76"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </g>
  </svg>
);
