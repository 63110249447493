import { Operator } from 'react-querybuilder';

export const defaultOperators = [
  { name: '=', label: 'EQUALS_TO' },
  { name: '!=', label: 'NOT_EQUAL_TO' },
  { name: '<', label: 'LESS_THAN' },
  { name: '>', label: 'GREATER_THAN' },
  { name: '<=', label: 'LESS_THAN_OR_EQUAL_TO' },
  { name: '>=', label: 'GREATER_THAN_OR_EQUAL_TO' },
  { name: 'null', label: 'IS_BLANK' },
  { name: 'notNull', label: 'IS_NOT_BLANK' },
  { name: 'in', label: 'INCLUDING' },
  { name: 'between', label: 'BETWEEN' },
  { name: 'notBetween', label: 'IS_NOT_BETWEEN' },
  { name: 'notIn', label: 'DOES_NOT_INCLUDE' },
];

export const getOperators = (): Operator[] => defaultOperators;
