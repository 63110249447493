import { useCallback } from 'react';
import { ArrayControlProps } from '@jsonforms/core';
import type { AnnotatedJsonSchema } from '@web-config-app/core';
import type { RelationshipValue } from '../../types/controls';
import { createDefaultValueForSchema } from '../../utils/create-default-value-for-schema/create-default-value-for-schema';

export interface UseEntityReferenceArrayItemActionsProps
  extends Pick<
    ArrayControlProps,
    'moveUp' | 'moveDown' | 'removeItems' | 'addItem'
  > {
  schema: AnnotatedJsonSchema;
  relationshipDataSchema: AnnotatedJsonSchema | undefined;
  addRelationshipItem: (item: Partial<RelationshipValue>) => void;
  removeRelationshipItem: (
    item: Partial<RelationshipValue>,
    index: number,
  ) => void;
  moveRelationshipItemUp: (
    item: Partial<RelationshipValue>,
    index: number,
  ) => void;
  moveRelationshipItemDown: (
    item: Partial<RelationshipValue>,
    index: number,
  ) => void;
}

/**
 * Each of the actions here needs to be able to handle a reference that is a string ID, a relationship, or
 * both. So there's a lot of if statements in each handler.
 */

export const useEntityReferenceArrayItemActions = ({
  schema,
  relationshipDataSchema,
  moveUp,
  moveDown,
  removeItems,
  addItem,
  addRelationshipItem,
  removeRelationshipItem,
  moveRelationshipItemUp,
  moveRelationshipItemDown,
}: UseEntityReferenceArrayItemActionsProps) => {
  const handleAddItem = useCallback(
    (path: string) => {
      if (relationshipDataSchema) {
        addRelationshipItem(
          createDefaultValueForSchema(relationshipDataSchema),
        );
      }
      if (schema.type === 'string') {
        addItem(path, '')();
      }
    },
    [addItem, addRelationshipItem, schema, relationshipDataSchema],
  );

  const handleMoveUp = useCallback(
    (item: any, itemPath: string, index: number) => {
      if (relationshipDataSchema) {
        moveRelationshipItemUp(item, index);
      }
      if (schema.type === 'string') {
        moveUp?.(itemPath, index)();
      }
    },
    [moveUp, moveRelationshipItemUp, schema, relationshipDataSchema],
  );
  const handleMoveDown = useCallback(
    (item: any, itemPath: string, index: number) => {
      if (relationshipDataSchema) {
        moveRelationshipItemDown(item, index);
      }
      if (schema.type === 'string') {
        moveDown?.(itemPath, index)();
      }
    },
    [moveDown, moveRelationshipItemDown, schema, relationshipDataSchema],
  );

  const handleRemoveItem = useCallback(
    (item: any, itemPath: string, index: any) => {
      if (relationshipDataSchema) {
        removeRelationshipItem(item, index);
      }
      if (schema.type === 'string') {
        removeItems?.(itemPath, [index])();
      }
    },
    [removeItems, removeRelationshipItem, schema, relationshipDataSchema],
  );

  return {
    handleAddItem,
    handleMoveUp,
    handleMoveDown,
    handleRemoveItem,
  };
};
