import { RequiredEntityMetadataFieldValues } from '@web-config-app/core';
/**
 * requiredEntityMetadataFieldValues can be declared on an operation to be included with the operation API request.
 * If a requiredEntityMetadataFieldValue is a dynamic value, it is declared with $ before, so that we know to perform a map look up to replace the placeholder $ value with the real dynamic value. Values without the $ before are static and will not be replaced.
 */

export const requiredEntityMetadataFieldValuesVariableMap = {
  $currentDate: new Date().toISOString(),
};

export const replaceRequiredValuesVariables = (
  requiredEntityMetadataFieldValues?: RequiredEntityMetadataFieldValues,
  variableMap: Record<
    string,
    any
  > = requiredEntityMetadataFieldValuesVariableMap,
): Record<string, any> | undefined => {
  if (!requiredEntityMetadataFieldValues) {
    return requiredEntityMetadataFieldValues;
  }

  return Object.fromEntries(
    Object.entries(requiredEntityMetadataFieldValues).map(([key, value]) => [
      key,
      variableMap[value] ?? value,
    ]),
  );
};
