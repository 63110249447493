import { Category, Domain } from '@web-config-app/core';
import { mockEntity } from './mock-entities';

export const mockDomain = {
  name: 'Journey',
  id: 'journey',
  icon: 'illustrativeCheckBadge',
  category: Category.Default,
  entities: new Map(
    [
      { id: 'activities', name: 'Activities' },
      { id: 'campaigns', name: 'Campaigns' },
    ].map((e) => [e.id, { ...e, ...mockEntity }]),
  ),
};

export const mockDomains: Domain[] = [
  mockDomain,
  {
    name: 'Masonry',
    id: 'masonry',
    icon: 'illustrativeCompose',
    category: Category.Default,
    entities: new Map(
      [
        {
          name: 'Containers',
          id: 'containers',
        },
        { name: 'Widgets', id: 'widgets' },
      ].map((e) => [e.id, { ...e, ...mockEntity }]),
    ),
  },
  {
    name: 'Management',
    id: 'management',
    icon: 'interfaceStar',
    category: Category.Hidden,
    entities: new Map(
      [
        {
          name: 'Assets',
          id: 'assets',
          category: Category.Hidden,
        },
      ].map((e) => [e.id, { ...e, ...mockEntity }]),
    ),
  },
  {
    name: 'A domain with hidden entities',
    id: 'domain',
    icon: 'illustrativeStar',
    category: Category.Default,
    entities: new Map(
      [
        {
          name: 'Assets',
          id: 'assets',
          category: Category.Hidden,
        },
      ].map((e) => [e.id, { ...e, ...mockEntity }]),
    ),
  },
];
