import { useState } from 'react';
import { EntityRootData } from '@web-config-app/core';

export type UseEntityDetailsDataResult = ReturnType<
  typeof useEntityDetailsData
>;

export interface UseEntityDetailsDataProps {
  data: EntityRootData | undefined;
}

export const useEntityDetailsData = ({ data }: UseEntityDetailsDataProps) => {
  /**
   * `entityRootData` stores the entire EntityDetail data object which is eventually
   * sent to the server on save.
   */
  const [entityRootData, setEntityRootData] =
    useState<UseEntityDetailsDataProps['data']>(data);

  return {
    entityRootData,
    setEntityRootData,
  };
};
