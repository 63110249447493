import { useMemo } from 'react';
import { Field } from 'react-querybuilder';
import { useGetDataPoints } from './use-get-data-points.hook';

export const useGetDataPointFields = () => {
  const { dataPoints } = useGetDataPoints();

  const filteredDataPoints = useMemo(
    // TODO :: fix ts-ignore
    // @ts-ignore
    () => dataPoints.filter((points) => !points.isCategory),
    [dataPoints],
  );
  // https://react-querybuilder.js.org/docs/components/querybuilder#fields
  // make first one empty so that the input is not pre-populated
  const dataPointFields = useMemo<Field[]>(
    () => [
      {
        name: '',
        label: '',
        dataType: undefined,
        valueSources: ['value', 'field'],
      },
      ...filteredDataPoints.map(
        // TODO :: fix ts-ignore
        // @ts-ignore
        (point) =>
          ({
            name: point.id || '',
            label: point.id || '',
            dataType: point.dataType,
            valueSources: ['value', 'field'],
          } as Field),
      ),
    ],
    [filteredDataPoints],
  );

  return { dataPointFields };
};
