import type { EntityDetailSchemaWithRelationships } from '@web-config-app/core';
import { getRelationshipSchemaItemType } from '@web-config-app/schema-utils';
import { RelationshipValue } from '../../types/controls';

export const getCreateRelationshipValueFromSchema = (
  relationship: string,
  schema: EntityDetailSchemaWithRelationships,
) => {
  const { type } = getRelationshipSchemaItemType(relationship, schema);
  return function createRelationshipValue(
    relationshipValue: Partial<RelationshipValue>,
  ) {
    return {
      id: relationshipValue.id,
      type,
    };
  };
};
