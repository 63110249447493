import * as React from 'react';
import { GroupLayout } from '@jsonforms/core';
import { Box, ParagraphText, StatusBanner } from '@leagueplatform/genesis-core';
import { EntityFormLayout } from '../../components/entity-form-layout/entity-form-layout.component';
import { EntityFormDispatchChildren } from '../../components/entity-form-dispatch-children/entity-form-dispatch-children.component';
import { EntityFormLayoutProps, LayoutComponent } from '../../types/layouts';

/**
 * ObjectPrimitiveInArrayLayoutContents is used for when a PrimitiveObject is rendered as an array.item and is rendered when a PrimitiveObject and uiType is `PrimitiveObjectArrayItem`
 *
 * It has different spacing than a regular primitive object, and does not render a fieldset or label
 */

const ObjectPrimitiveArrayItemLayoutContents = ({
  hint,
  banner,
  schema,
  path,
  enabled,
  uischema,
  renderers,
  cells,
}: EntityFormLayoutProps) => {
  const group = uischema as GroupLayout;
  const showBanner = banner?.title || banner?.description;
  return (
    <Box>
      <Box css={{ marginBottom: '$one' }}>
        {showBanner && (
          <StatusBanner
            status={banner.status}
            title={banner.title}
            css={{ width: '100%', marginY: '$half' }}
          >
            {banner.description && (
              <ParagraphText>{banner.description}</ParagraphText>
            )}
          </StatusBanner>
        )}
        {hint && (
          <ParagraphText size="sm" emphasis="subtle">
            {hint}
          </ParagraphText>
        )}
      </Box>
      <EntityFormDispatchChildren
        layout={group}
        schema={schema}
        path={path}
        enabled={enabled}
        renderers={renderers}
        cells={cells}
      />
    </Box>
  );
};

const renderLayout = (formContentsProps: EntityFormLayoutProps) => (
  <ObjectPrimitiveArrayItemLayoutContents {...formContentsProps} />
);

export const ObjectPrimitiveArrayItemLayout: LayoutComponent = (props) => (
  <EntityFormLayout {...props} renderLayout={renderLayout} />
);
