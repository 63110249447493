/* eslint-disable react/jsx-props-no-spreading */
import { Box } from '@leagueplatform/genesis-core';

import * as React from 'react';
import {
  RuleGroupProps as BaseRuleGroupProps,
  useRuleGroup,
  useStopEventPropagation,
  RuleGroupBodyComponents,
} from 'react-querybuilder';
import { RuleGroupHeader } from '../rule-group-header/rule-group-header.component';

const groupContainerCss = {
  borderRadius: '$extraLarge',
  borderStyle: 'solid',
  borderWidth: 'thin',
  borderColor: '$onSurfaceBorderSubdued',
  marginTop: '$one',
};

export interface RuleGroupProps extends BaseRuleGroupProps {}

export const RuleGroup = (props: RuleGroupProps) => {
  // this implementation of combining props with what useRuleGroups hook returns is taken directly from the ReactQueryLibrary RuleGroup component.Replicating to ensure all needed props are passed.
  // https://github.com/react-querybuilder/react-querybuilder/blob/e0f2f9f4059c3f68f6c309220d5ff24c3f35b7e7/packages/react-querybuilder/src/components/RuleGroup.tsx#L8

  // we want to render our own RuleGroup component instead of using reactQueryBuilder's because
  // 1. Give focus to the New Group on creation
  // 2. Render our own Group Header components so we can match designs and render a Group title
  const rg = { ...props, ...useRuleGroup(props) };

  const { addRule, addGroup, removeGroup } = rg;
  const methodsWithoutEventPropagation = useStopEventPropagation({
    addRule,
    addGroup,
    removeGroup,
  });

  const subComponentProps = { ...rg, ...methodsWithoutEventPropagation };

  const rootHeader = rg.path.length === 0;
  const containerStyles = !rootHeader ? groupContainerCss : undefined;

  return (
    <Box
      id={rg.id}
      tabIndex={-1}
      data-testid="rule-group-container"
      ref={rg.previewRef}
      data-rule-group-id={rg.id}
      data-level={rg.path.length}
      data-path={JSON.stringify(rg.path)}
      className="rule-group-container"
      css={containerStyles}
    >
      <RuleGroupHeader {...subComponentProps} rootHeader={rootHeader} />
      <RuleGroupBodyComponents {...subComponentProps} />
    </Box>
  );
};
