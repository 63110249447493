import { useMemo } from 'react';
import { useIntl } from '@leagueplatform/locales';
import { DATA_TYPES } from '../constants/data-types.constants';
import { useGetDataType } from './use-get-data-type.hook';

const { BOOLEAN, DATETIME, INTEGER, NUMBER, STRING } = DATA_TYPES;

// Input types reference can be found on MDN here:
// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#input_types
export const fieldTypes = {
  [DATETIME]: 'datetime-local',
  [INTEGER]: 'number',
  [NUMBER]: 'number',
  [STRING]: 'text',
};

export const dataTypeHintKeys = {
  [BOOLEAN]: 'FLOW_RULE_ENGINE_DATA_TYPE_HINT_BOOLEAN',
  [DATETIME]: 'FLOW_RULE_ENGINE_DATA_TYPE_HINT_DATETIME',
  [INTEGER]: 'FLOW_RULE_ENGINE_DATA_TYPE_HINT_INT',
  [NUMBER]: 'FLOW_RULE_ENGINE_DATA_TYPE_HINT_NUMBER',
  [STRING]: 'FLOW_RULE_ENGINE_DATA_TYPE_HINT_STRING',
};

export const useValueFieldType = (fieldValue: string = '') => {
  const { dataType } = useGetDataType(fieldValue);
  const { formatMessage } = useIntl();

  const fieldType: string = useMemo(
    () =>
      dataType && fieldTypes[dataType]
        ? fieldTypes[dataType]
        : fieldTypes[STRING],
    [dataType],
  );

  const fieldHint: string | undefined = useMemo(
    () =>
      dataType && dataTypeHintKeys[dataType]
        ? formatMessage({ id: dataTypeHintKeys[dataType] })
        : undefined,
    [dataType, formatMessage],
  );

  return { fieldType, fieldHint };
};
