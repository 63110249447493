import { GDSStatusTokens } from '@leagueplatform/genesis-core';

const critical: GDSStatusTokens = {
  background: {
    subdued: '#F9DEE3',
    secondary: '#FFFFFF',
  },
  border: {
    default: '#BA1B3B',
  },
  icon: '#BA1B3B',
};

const warning: GDSStatusTokens = {
  background: {
    subdued: '#FFE4BB',
    secondary: '#FFFFFF',
  },
  border: {
    default: '#FD9833',
  },
  icon: '#FD9833',
};

const success: GDSStatusTokens = {
  background: {
    subdued: '#E5F6F1',
    secondary: '#FFFFFF',
  },
  border: {
    default: '#00A876',
  },
  icon: '#00A876',
};

const highlight: GDSStatusTokens = {
  background: {
    subdued: '#EAEEFB',
    secondary: '#FFFFFF',
  },
  border: {
    default: '#2E5CDF',
  },
  icon: '#2E5CDF',
};

export const statusTokens = {
  critical,
  warning,
  success,
  highlight,
};
