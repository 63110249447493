import * as React from 'react';
import {
  ControlComponent,
  EntityFormControlProps,
} from '../../../types/controls';
import { EntityFormControl } from '../../entity-form-control/entity-form-control.component';
import { AddItemModalTriggers } from '../../add-item-modal-triggers/add-item-modal-triggers.component';
import { AddItemByIdModal } from '../../add-item-by-id-modal/add-item-by-id-modal.component';
import { SelectedImage } from './selected-image/selected-image.component';
import { UploadImageModal } from './upload-image-modal/upload-image-modal.component';
import { useImageInputControl } from './use-image-input-control.hook';

const ImageControlContents = ({
  data,
  handleChange,
  path,
  schema,
}: EntityFormControlProps) => {
  const {
    isImageURL,
    addItemTriggerList,
    addItemModalProps,
    uploadImageModalProps,
  } = useImageInputControl({ handleChange, path, schema });

  return data ? (
    <SelectedImage
      imageData={data}
      onClear={() => handleChange(path, '')}
      isImageURL={isImageURL}
    />
  ) : (
    <>
      <AddItemModalTriggers triggerList={addItemTriggerList} />
      <AddItemByIdModal {...addItemModalProps} />
      <UploadImageModal {...uploadImageModalProps} />
    </>
  );
};

export const ImageInputControl: ControlComponent = (props) => (
  <EntityFormControl {...props} renderControl={ImageControlContents} />
);
