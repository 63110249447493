import { useState } from 'react';
import { RuleGroupType } from 'react-querybuilder';

const initialQuery: RuleGroupType = {
  id: 'root',
  combinator: 'and',
  rules: [],
};

export const useCreateRule = () => {
  const [query, setQuery] = useState(initialQuery);

  const emptyRules = !query.rules.length;

  return { query, setQuery, emptyRules };
};
