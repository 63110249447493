import { ChangeEvent, useCallback, useMemo, useEffect } from 'react';
import { RuleGroupType, joinWith, toArray, update } from 'react-querybuilder';
import { PLACEHOLDER_MIN_VALUE } from '../constants/special-values.constants';
import { useValidateDataPoints } from './use-validate-data-points.hook';

export interface ValueInputProps {
  handleOnChange(value: string): void;
  mustBeDataPoint: boolean;
  value: string | undefined;
  query: RuleGroupType;
  setQuery(value: any): void;
  path: number[];
  operator?: string;
}

export const useValueInput = ({
  handleOnChange,
  mustBeDataPoint,
  query,
  setQuery,
  path,
  value,
  operator,
}: ValueInputProps) => {
  const { getDataType } = useValidateDataPoints();

  const updateQuery = useCallback(
    // TODO :: ts-ignore fix
    // @ts-ignore
    async (inputValue, queryArrayString?) => {
      // do not want to update anything if this is being called when the field must be a data point
      if (mustBeDataPoint) return;

      const dataType = await getDataType(inputValue);

      // if a queryArrayString is passed, use that over the input value
      const valueAdd = queryArrayString || inputValue;
      const valueSourceType = dataType ? 'field' : 'value';

      // This checks if the input is on the right (Value field), and a valid datatype. If the value is a data point, updates the valueSource to 'Field' on the rule query (which in turn wraps it in the var object by react query builder jsonLogic formatQuery)
      setQuery(
        update(
          update(query, 'valueSource', valueSourceType, path),
          'value',
          valueAdd,
          path,
        ),
      );
    },
    [getDataType, mustBeDataPoint, path, query, setQuery],
  );

  useEffect(() => {
    if (
      !['between', 'notBetween', 'in', 'notIn'].includes(operator as string) &&
      ((typeof value === 'string' && value.includes(',')) ||
        Array.isArray(value))
    ) {
      handleOnChange(toArray(value)[0] ?? '');
    }
  }, [handleOnChange, operator, value]);

  const handleFieldChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value;
      handleOnChange(inputValue);
      updateQuery(inputValue);
    },
    [handleOnChange, updateQuery],
  );

  const handleDataPointSelect = useCallback(
    // TODO :: ts-ignore fix
    // @ts-ignore
    async (dataPoint) => {
      handleOnChange(dataPoint);
      updateQuery(dataPoint);
    },
    [handleOnChange, updateQuery],
  );

  const valueAsArray = useMemo(() => (value ? toArray(value) : ['']), [value]);

  const handleMultipleValues = useCallback(
    // TODO :: ts-ignore fix
    // @ts-ignore
    async (dataPoint, index) => {
      const val = [...valueAsArray];
      val[index] = dataPoint;

      if (
        index === 0 &&
        (operator === 'between' || operator === 'notBetween') &&
        !val[1]
      ) {
        val[1] = '';
      }

      if (
        index === 0 &&
        (operator === 'between' || operator === 'notBetween') &&
        dataPoint === ''
      ) {
        val[0] = PLACEHOLDER_MIN_VALUE;
      }

      const valueString = joinWith(val, ',');
      handleOnChange(valueString);
      // will update the query for the first field
      if (index === 0) {
        updateQuery(dataPoint, valueString);
      }
    },
    [valueAsArray, operator, handleOnChange, updateQuery],
  );

  const handleChangeMultipleValues = useCallback(
    (e: ChangeEvent<HTMLInputElement>, inputIndex: number) => {
      handleMultipleValues(e.target.value, inputIndex);
    },
    [handleMultipleValues],
  );

  return {
    handleFieldChange,
    handleDataPointSelect,
    updateQuery,
    handleMultipleValues,
    handleChangeMultipleValues,
    valueAsArray,
  };
};
