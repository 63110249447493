import * as React from 'react';
import {
  StackLayout,
  Button,
  Modal,
  HeadingText,
  ParagraphText,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { DeleteConfirmationModalProps } from '../../types/components';

/**
 Delete confirmation modal where the child is the modal trigger button. Takes in onDelete function of what to call on confirmation.
*/

export const DeleteConfirmationModal: React.FC<
  React.PropsWithChildren<DeleteConfirmationModalProps>
> = ({ children, onDelete, modalDescription }) => {
  const { formatMessage } = useIntl();

  const [modalOpen, setModalOpen] = React.useState(false);

  const onApplyDelete = React.useCallback(() => {
    onDelete();
    setModalOpen(false);
  }, [onDelete]);

  const onModalOpenChange = React.useCallback(() => {
    setModalOpen((previous) => !previous);
  }, []);

  return (
    <Modal.Root open={modalOpen} onOpenChange={onModalOpenChange}>
      <Modal.Trigger>{children}</Modal.Trigger>
      <Modal.Content showCloseButton={false}>
        <Modal.Title>
          <HeadingText level="2" size="lg">
            {formatMessage({ id: 'DELETE_CONFIRMATION_MODAL_TITLE' })}
          </HeadingText>
        </Modal.Title>
        <Modal.Description>
          <ParagraphText css={{ marginTop: '$threeQuarters' }}>
            {modalDescription}
          </ParagraphText>
        </Modal.Description>
        <StackLayout
          orientation="horizontal"
          horizontalAlignment="end"
          spacing="$half"
          css={{ marginTop: '$one' }}
        >
          <Modal.Close>
            <Button priority="tertiary" quiet size="medium" type="button">
              {formatMessage({ id: 'CANCEL' })}
            </Button>
          </Modal.Close>
          <Button size="medium" destructive onClick={onApplyDelete}>
            {formatMessage({ id: 'DELETE' })}
          </Button>
        </StackLayout>
      </Modal.Content>
    </Modal.Root>
  );
};
