import * as React from 'react';
import type { Entity, EntityDetail } from '@web-config-app/core';
import {
  useEntityOperations,
  UseEntityOperationsProps,
  useEntityDetailsData,
} from '@web-config-app/core-react';
import {
  EntityDetailsProvider,
  createDefaultValueForSchema,
  useSyncDataSources,
  computeSchema,
} from '@web-config-app/entity-form';
import { applyDefaultSchemaAnnotations } from '@web-config-app/schema-utils';

export interface EntityDetailsPageContainerProps {
  entity: Entity;
  instanceId: string;
  path: string;
  options?: UseEntityOperationsProps['options'];
  createDefaultEntityData?: boolean;
}

export const EntityDetailsPageContainer = ({
  entity,
  instanceId,
  options,
  path,
  children,
  createDefaultEntityData,
}: React.PropsWithChildren<EntityDetailsPageContainerProps>) => {
  const defaultSchema = React.useMemo(
    () => applyDefaultSchemaAnnotations(entity.schema),
    [entity.schema],
  );
  const defaultEntityData = React.useMemo(
    () =>
      createDefaultEntityData
        ? createDefaultValueForSchema<EntityDetail>(defaultSchema)
        : undefined,
    [defaultSchema, createDefaultEntityData],
  );

  const { entityRootData, setEntityRootData } = useEntityDetailsData({
    data: defaultEntityData,
  });

  const rootSchema = React.useMemo(
    () => computeSchema(defaultSchema, entityRootData),
    [defaultSchema, entityRootData],
  );

  useSyncDataSources(rootSchema, entityRootData);

  const operations = useEntityOperations({
    entity,
    instanceId,
    options,
    entityRootData,
    onEntityGetSuccess: setEntityRootData,
  });

  return (
    <EntityDetailsProvider
      entityRootData={entityRootData}
      setEntityRootData={setEntityRootData}
      operations={operations}
      rootSchema={rootSchema}
      path={path}
      instanceId={instanceId}
      entityType={entity.type}
      entity={entity}
    >
      {children}
    </EntityDetailsProvider>
  );
};
