import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  Box,
  HeadingText,
  InputStatusMessage,
  ParagraphText,
  StatusBanner,
} from '@leagueplatform/genesis-core';
import { EntityHeadingProps } from '../../types/components';

/**    
 * 
This heading is shared between the Object Compound Layout and the Array of Objects Layout. It appears at the top of every new "page" within the property editor.
 * 
*/

export const CompoundComplexHeading = ({
  label,
  hint,
  banner,
  error,
}: EntityHeadingProps) => {
  const { formatMessage } = useIntl();
  const showBanner = Boolean(banner?.title || banner?.description);

  return (
    <>
      <Box
        css={{
          borderBottom: '$borderWidths$thin solid $onSurfaceBorderSubdued',
          margin: '$oneAndQuarter 0',
        }}
      >
        {banner && showBanner && (
          <StatusBanner
            status={banner.status}
            title={banner.title}
            css={{
              width: '100%',
              margin: '$twoAndHalf 0',
            }}
          >
            {banner.description && (
              <ParagraphText>{banner.description}</ParagraphText>
            )}
          </StatusBanner>
        )}
        <HeadingText size="xl" level="2" css={{ paddingBottom: '$half' }}>
          {label}
        </HeadingText>
        {hint && (
          <ParagraphText
            emphasis="subtle"
            css={{ marginBottom: '$oneAndHalf' }}
          >
            {hint}
          </ParagraphText>
        )}
      </Box>
      {error && (
        <InputStatusMessage
          data-testid="error-message"
          inputStatus="error"
          statusIconLabel={formatMessage({ id: 'ERROR' })}
          css={{ marginTop: '$half', marginBottom: '$one' }}
        >
          {error}
        </InputStatusMessage>
      )}
    </>
  );
};
