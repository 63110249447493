import * as React from 'react';
import {
  Box,
  HeadingText,
  StackItem,
  StackLayout,
} from '@leagueplatform/genesis-core';

type EntityListPageLayoutProps = {
  entityName: string;
  actions: React.ReactNode | JSX.Element;
};

export const EntityListPageLayout: React.FC<
  React.PropsWithChildren<EntityListPageLayoutProps>
> = ({ entityName, actions, children }) => (
  <StackLayout
    orientation="vertical"
    horizontalAlignment="stretch"
    css={{
      padding: '$twoAndHalf',
    }}
  >
    <StackLayout
      orientation="horizontal"
      horizontalAlignment="spaceBetween"
      css={{ paddingY: '$threeQuarters', flexGrow: 0 }}
    >
      <HeadingText level="1" size="xl">
        {entityName}
      </HeadingText>
      <StackItem css={{ flexDirection: 'row', gap: '$threeQuarters' }}>
        {actions}
      </StackItem>
    </StackLayout>
    <Box css={{ marginY: '$oneAndHalf', flexGrow: 1 }}>{children}</Box>
  </StackLayout>
);
