import { EN_STRING_ACTIVITY_OVERRIDES } from './entities/activity-strings';
import { EN_CAMPAIGN_STRINGS } from './entities/campaigns-strings';
import { EN_STRING_NODE_OVERRIDES } from './entities/nodes-strings';

export const EN_STRING_OVERRIDES = {
  DEVELOPMENT: 'Development',
  STAGING: 'Staging',
  PRODUCTION: 'Production',
  EMPTY_ENTITY_TITLE: 'No {entity} here (yet)',
  EMPTY_ENTITY_DESCRIPTION:
    'You can manage {entity} here and reference them across the {tenant} experience.',
  ERROR_ENTITY_TITLE: 'Something went wrong loading {entity}.',
  ERROR_ENTITY_DESCRIPTION:
    'We are unable to access {entity} at this time, please try again later.',
  CREATE: 'Create',
  EXIT_ENTITY_DETAIL_TITLE: 'Discard changes to {name}?',
  EXIT_ENTITY_DETAIL_DESCRIPTION:
    'Changes you have made since you last saved or published will not be persisted.',
  EXIT_ENTITY_DETAIL_PRIMARY_CTA: 'Discard and Close',
  ENTITY_DETAIL_LOAD_ERROR_TITLE: 'Loading Error',
  ENTITY_DETAIL_LOAD_ERROR_DESCRIPTION:
    'An unexpected error occurred while loading this page',
  RETURN_HOME: 'Return Home',
  LOADING: 'Loading',
  PUBLISHED: 'Published',
  PUBLISH_CHANGES: 'Publish Changes',
  PUBLISHING: 'Publishing',
  RE_PUBLISHING: 'Re-publishing',
  DRAFT: 'Draft',
  ARCHIVED: 'Archived',
  ARCHIVE: 'Archive',
  UN_ARCHIVE: 'Unarchive as Draft',
  UN_ARCHIVING: 'Unarchiving',
  UNSAVED_CHANGES: 'Unsaved changes',
  ALL_CHANGES_SAVED: 'All changes saved',
  ALL_CHANGES_PUBLISHED: 'All changes published',
  UNPUBLISHED_CHANGES: 'Unpublished changes',
  UNPUBLISH: 'Unpublish',
  DUPLICATE_AS_DRAFT: 'Duplicate as Draft',
  EXIT_AND_DISCARD_CHANGES: 'Exit and Discard Changes',
  UNPUBLISH_BEFORE_YOU_ARCHIVE:
    'You must unpublish this {entity} before you can archive it.',
  SAVE_BEFORE_YOU_DUPLICATE:
    'You must save your changes before you can duplicate this {entity}.',
  SAVE_DRAFT: 'Save Draft',
  SAVE_CHANGES: 'Save Changes',
  SAVING: 'Saving',
  NAME: 'Name',
  ID: 'ID',
  LAST_UPDATED: 'Last Updated',
  STATUS: 'Status',
  COPY_ENTITY_ID: 'Copy entity ID',
  FIRST: 'First',
  PREVIOUS: 'Previous',
  NEXT: 'Next',
  LAST: 'Last',
  DELETE_ITEM_ARRAY_TOOLTIP: 'Delete {item} from list.',
  MOVE_ITEM_UP_TOOLTIP: 'Move {item} up.',
  MOVE_ITEM_DOWN_TOOLTIP: 'Move {item} down.',
  ADD_ITEM: 'Add {item}',
  DELETE: 'Delete',
  UP: 'Up',
  DOWN: 'Down',
  CANCEL: 'Cancel',
  DELETE_CONFIRMATION_MODAL_TITLE: 'Are you sure?',
  DELETE_ARRAY_ITEM_MODAL_DESCRIPTION:
    'Deleting <strong>{item}</strong> will remove all configurations that you have made for that item, and any of its children.',
  LIST_ITEM: 'List item',
  ADD_BY_ID: 'Add by ID',
  CHOOSE_FROM_LIST: 'Choose from a list',
  CHANGE_SELECTION: 'Change Selection',
  ENTITY_NAME_ARTICLE:
    '{startsWith, select, consonant {a} vowel {an} other {a}}',
  CHOOSE_ENTITY: 'Choose {entityName}',
  ENTITY_ID: 'Entity ID',
  OBJECT_CARD_LINK_OPENS_IN_OWN_PAGE: 'link, opens in its own page',
  ADDRESS_OR_USER: 'Address or User',
  USER_KEY: 'User',
  ADDRESS_KEY: 'Address',
  INTERNAL_NAME: 'Internal Name',
  LIST: 'List',
  LOW: 'Low',
  MEDIUM: 'Medium',
  HIGH: 'High',
  SIMPLE: 'Simple',
  STANDARD: 'Standard',
  DETAILED: 'Detailed',
  PRIMARY: 'Primary',
  SECONDARY: 'Secondary',
  SUBTLE: 'Subtle',
  TITLE: 'Title',
  SINGLE: 'Single',
  MULTI: 'Multi',
  REQUIRED: 'Required',
  MANUAL: 'Manual',
  SLIDER: 'Slider',
  COUNTER: 'Counter',
  BADGE: 'Badge',
  EVENT: 'Event',
  ALT_TEXT: 'Alt Text',
  BACKGROUND_COLOR: 'Background Color',
  BORDER_RADIUS: 'Border Radius',
  HORIZONTAL_ALIGNMENT: 'Horizontal Alignment',
  START: 'Start',
  CENTER: 'Center',
  END: 'End',
  UNIT: 'Unit',
  START_DATE: 'Start Date',
  END_DATE: 'End Date',
  VALUE: 'Value',
  INFO: 'Info',
  STRING: 'String',
  INTEGER: 'Integer',
  DECIMAL: 'Decimal',
  CODING: 'Coding',
  DATE: 'Date',
  TIME: 'Time',
  ANSWER: 'Answer',
  CODE: 'Code',
  METRIC: 'Metric',
  OBSERVATION: 'Observation',
  CORE: 'Core',
  LPF: 'lpf',
  GOAL: 'Goal',
  PILL: 'Pill',
  LINE: 'Line',
  BOX: 'Box',
  CHALLENGE: 'Challenge',
  NONE: 'None',
  NEW: 'New',
  DUPLICATE_OF: 'Duplicate of',
  /** rich text toolbar */
  NORMAL_LABEL: 'Normal',
  HEADING_1_LABEL: 'Heading 1',
  HEADING_2_LABEL: 'Heading 2',
  HEADING_3_LABEL: 'Heading 3',
  HEADING_4_LABEL: 'Heading 4',
  HEADING_5_LABEL: 'Heading 5',
  HEADING_6_LABEL: 'Heading 6',
  NUMBER_LIST_LABEL: 'Number list',
  BULLET_LIST_LABEL: 'Bullet list',
  QUOTE_LABEL: 'Quote',
  UNDO_LABEL: 'Undo',
  REDO_LABEL: 'Redo',
  BOLD_LABEL: 'Bold',
  INSERT_LINK_LABEL: 'Insert link',
  ITALIC_LABEL: 'Italic',
  STYLES_LIST: 'Styles list. {selectedBlockElement} text selected.',
  UNTITLED: 'Untitled',
  DEFAULT_ENUM_PLACEHOLDER: 'Select an option',
  ADD_IMAGE: 'Add Image',
  CONTENTFUL_ID: 'Contentful ID',
  ADD_IMAGE_BY_CONTENTFUL_ID: 'Add image by Contentful ID',
  IMAGE_URL: 'Image URL',
  USE_IMAGE_URL: 'Use Image URL',
  IMAGE_URL_HINT:
    'Please use a fully qualified URL. For example, (https://....)',
  INVALID_URL: 'Not a valid URL. Please check your input and try again.',
  CHOOSE_IMAGE_FROM_COMPUTER: 'Choose image from computer',
  CLEAR_SELECTED_IMAGE: 'Clear selected image',
  UNABLE_TO_FIND_IMAGE:
    'Unable to find this image. Please check the Contentful gallery or fix any entry errors.',
  UNABLE_TO_FIND_ENTITY: 'No {entityName} was found matching this ID.',
  COULD_NOT_LOAD_IMAGE: 'Could not load image from Contentful',
  MORE_ACTIONS: 'More actions',
  NO_ARRAY_ITEMS_YET: 'No {item} yet.',
  RULE_TEMPLATE: 'Rule template',
  RULE: 'Rule',
  APPLY: 'Apply',
  UPLOAD_IMAGE: 'Upload Image',
  IMAGE_FILE: 'Image File',
  CHANGE_IMAGE: 'Change Image',
  FILE_TYPE_NOT_SUPPORTED:
    'File type {fileType} is not supported. The following file types are supported: {allowedFileTypes}',
  FILE_TOO_LARGE_ERROR:
    'Maximum file size is {maxSize}. This file is {currentSize}.',
  NO_FILE_INCLUDED_ERROR: 'Please add a file before attempting to upload',
  NO_TITLE_INCLUDED_ERROR: 'Please add a title before attempting to upload',
  ERROR_LOADING_ENTITY_REFERENCE: 'There was a problem loading this content',
  WIDGETS: '{count, plural, one {Widget} other {Widgets}}',
  CONTAINERS: '{count, plural, one {Container} other {Containers}}',
  ASSETS: '{count, plural, one {Asset} other {Assets}}',
  NONE_RULES_TEMPLATE: 'None',
  CONFIGURATION_ERROR:
    '{count, plural, one {There is an error in your configuration.} other {There are # errors in your configuration}}',
  SERVER_ERROR: 'Server Error',
  ...EN_STRING_ACTIVITY_OVERRIDES,
  ...EN_CAMPAIGN_STRINGS,
  ...EN_STRING_NODE_OVERRIDES,
};
