import { useMemo, useCallback } from 'react';
import type { AnnotatedJsonSchema } from '@web-config-app/core';
import { useTranslate } from '../use-translate/use-translate';
import { createEntityTree } from '../../utils/create-entity-tree/create-entity-tree';
import type { TreeNode, Tree } from '../../types/tree';

export interface UseEntityTreeResult {
  entityTree: Tree;
  nodeMap: Map<string, TreeNode>;
  handleNodeToggle: (nodeId: string) => void;
}

export interface UseEntityTreeProps {
  schema: AnnotatedJsonSchema;
  data: any;
  onSelect?: (...args: any) => void;
}

const flattenTree = (tree: Tree) => {
  const nodes: TreeNode[] = [];
  const flattenTreeHelper = (t: Tree) => {
    t.forEach(({ children, ...node }: TreeNode) => {
      nodes.push(node);
      if (children?.length && children.length > 0) {
        flattenTreeHelper(children);
      }
    });
  };

  flattenTreeHelper(tree);
  return nodes;
};

export const useEntityTree = ({
  schema,
  data,
  onSelect,
}: UseEntityTreeProps) => {
  const translate = useTranslate();
  const entityTree = useMemo(
    () => createEntityTree({ schema, data }, translate),
    [translate, data, schema],
  );

  const nodeMap = useMemo(() => {
    const flatTree = flattenTree(entityTree);
    return new Map(flatTree.map((node: TreeNode) => [node.id, node]));
  }, [entityTree]);

  const handleNodeToggle = useCallback(
    (nodeId: string) => {
      const item = nodeMap.get(nodeId);
      onSelect?.(item);
    },
    [nodeMap, onSelect],
  );

  return {
    entityTree,
    nodeMap,
    handleNodeToggle,
  };
};
