import { useCallback, useState } from 'react';
import { RuleGroupType } from 'react-querybuilder';

export interface FilteredGroupType extends RuleGroupType {
  groupLevel?: string;
  rules: FilteredGroupType[];
}

export const useGenerateGroupLevels = () => {
  const [filteredGroups, setFilteredGroups] = useState<FilteredGroupType[]>();

  const filterGroupsOnly = (rules: FilteredGroupType[]) =>
    rules.filter((rule) => {
      if (rule.combinator) {
        if (Array.isArray(rule.rules)) {
          // eslint-disable-next-line no-param-reassign
          rule.rules = filterGroupsOnly(rule.rules);
        }
        return true;
      }
      return false;
    });
  const addGroupLevels = (
    groups: FilteredGroupType[],
    parentGroupLevel = '',
    result = {},
  ) => {
    groups.forEach((group, index) => {
      const groupLevel = parentGroupLevel
        ? `${parentGroupLevel}.${index + 1}`
        : `${index + 1}`;
      // eslint-disable-next-line no-param-reassign
      group.groupLevel = groupLevel;
      if (Array.isArray(group.rules)) {
        addGroupLevels(group.rules, groupLevel, result);
      }
    });
    return groups;
  };

  const filterOutRulesAndAddGroupLevel = useCallback(
    (rules: FilteredGroupType[]) => {
      const filtered = filterGroupsOnly(rules);
      addGroupLevels(filtered);

      return filtered;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const findGroupLevelByRuleId = useCallback(
    (
      rules: FilteredGroupType[],
      targetId: string = '',
      currentNumber = '',
    ): FilteredGroupType | string | null => {
      if (!rules) return null;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < rules.length; i++) {
        const rule = rules[i];
        const newNumber = currentNumber
          ? `${currentNumber}.${i + 1}`
          : `${i + 1}`;
        if (rule.id === targetId) {
          return newNumber;
        }
        if (Array.isArray(rule.rules)) {
          const result: FilteredGroupType | string | null =
            findGroupLevelByRuleId(rule.rules, targetId, newNumber);
          if (result) {
            return result;
          }
        }
      }
      return null;
    },
    [],
  );

  return {
    filteredGroups,
    setFilteredGroups,
    findGroupLevelByRuleId,
    filterOutRulesAndAddGroupLevel,
  };
};
