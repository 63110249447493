import { ComponentType } from 'react';
import {
  RankedTester,
  JsonFormsRendererRegistryEntry,
  LayoutProps,
} from '@jsonforms/core';
import { createEntityFormRenderer } from './create-entity-form-renderer';
import {
  CreateRendererProps,
  EntityFormRendererType,
} from '../types/renderers';
import { ControlProps } from '../types/controls';

export const createRenderer = <T>(
  tester: RankedTester,
  renderer: ComponentType<CreateRendererProps<T>>,
  controlType: EntityFormRendererType,
  memoize: boolean = false,
  omitPropsWrapperFn: boolean = false,
): JsonFormsRendererRegistryEntry => ({
  tester,
  renderer: createEntityFormRenderer<T>(
    renderer,
    controlType,
    memoize,
    omitPropsWrapperFn,
  ),
});

export const createPrimitiveControlRenderer = (
  tester: RankedTester,
  renderer: ComponentType<CreateRendererProps<ControlProps>>,
  memoize: boolean = false,
  omitWrapper: boolean = false,
): JsonFormsRendererRegistryEntry => ({
  tester,
  renderer: createEntityFormRenderer<ControlProps>(
    renderer,
    'primitive',
    memoize,
    omitWrapper,
  ),
});

export const createObjectLayoutRenderer = (
  tester: RankedTester,
  renderer: ComponentType<CreateRendererProps<LayoutProps>>,
  memoize: boolean = false,
  omitWrapper: boolean = false,
): JsonFormsRendererRegistryEntry => ({
  tester,
  renderer: createEntityFormRenderer<LayoutProps>(
    renderer,
    'objectLayout',
    memoize,
    omitWrapper,
  ),
});
