import * as React from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  Modal,
  Button,
  HeadingText,
  Box,
  ParagraphText,
  UtilityText,
} from '@leagueplatform/genesis-core';
import { generateIdString } from '../../utils/generate-id-string.util';

// Element mappers accept ...content because they may be passed any number
// of arguments, based on the number of elements found in the source string
export const formatStringElements = {
  h3: (...content: any) => (
    <HeadingText size="md" level="3" key={generateIdString()}>
      {content}
    </HeadingText>
  ),
  p: (...content: any) => (
    <ParagraphText key={generateIdString()}>{content}</ParagraphText>
  ),
  li: (...content: any) => (
    <UtilityText
      as="li"
      css={{ margin: '$quarter 0' }}
      key={generateIdString()}
    >
      {content}
    </UtilityText>
  ),
  ul: (...content: any) => (
    <UtilityText
      as="ul"
      css={{ margin: '$threeQuarters 0 0 $oneAndHalf', listStyle: 'disc' }}
      key={generateIdString()}
    >
      {content}
    </UtilityText>
  ),
  strong: (...content: any) => (
    <UtilityText emphasis="emphasized" key={generateIdString()}>
      {content}
    </UtilityText>
  ),
};

export const guideSections = [
  { id: 'FLOW_RULE_ENGINE_RULE_BUILDER_GUIDE_RULE', body: '_LIST' },
  { id: 'FLOW_RULE_ENGINE_RULE_BUILDER_GUIDE_GROUP', body: false },
  { id: 'FLOW_RULE_ENGINE_RULE_BUILDER_GUIDE_COMBINATOR', body: false },
];

export const RuleBuilderGuide = () => {
  const { formatMessage } = useIntl();

  return (
    <Modal.Root>
      <Modal.Trigger>
        <Button
          css={{
            flexGrow: '0',
            marginRight: '$half',
          }}
          data-testid="rule-builder-guide-trigger"
          icon="illustrativeQuestionBox"
          priority="secondary"
          size="medium"
          type="button"
        >
          {formatMessage({ id: 'FLOW_RULE_ENGINE_RULE_BUILDER_GUIDE_TITLE' })}
        </Button>
      </Modal.Trigger>
      <Modal.Content layout="right">
        <HeadingText size="lg" level="2">
          {formatMessage({ id: 'FLOW_RULE_ENGINE_RULE_BUILDER_GUIDE_TITLE' })}
        </HeadingText>
        {guideSections.map(({ id, body }) => (
          <Box css={{ margin: '$oneAndHalf 0 $twoAndHalf' }} key={id}>
            {formatMessage({ id }, formatStringElements)}
            {body ? (
              <Box>
                {formatMessage({ id: id + body }, formatStringElements)}
              </Box>
            ) : null}
          </Box>
        ))}
      </Modal.Content>
    </Modal.Root>
  );
};
