import * as React from 'react';
import {
  Button,
  StackLayout,
  styled,
  StackItem,
  InputLabel,
  Modal,
  InputStatusMessage,
  Box,
  InputHint,
  HeadingText,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { useImportRule } from '../../hooks/use-import-rule.hook';

export interface RuleImportProps {
  onQueryChange(value: any): void;
}

// TODO :: replace with Genesis TextInput when it takes textarea in "as"
const TextAreaInput = styled('textarea', {
  typography: '$bodyTwo',
  color: '$onSurfaceTextPrimary',
  backgroundColor: '$surfaceBackgroundPrimary',
  border: '$borderWidths$thin solid $interactiveBorderDefault',
  borderRadius: '$medium',
  padding: '$threeQuarters $one',
  transition: 'box-shadow 0.2s ease-in-out',
  flex: '1 0 auto',
  width: '100%',
  resize: 'none',
  minHeight: '30rem',
  '&:focus': {
    borderColor: '$interactiveFocusInner',
    boxShadow:
      'inset 0 0 0 $borderWidths$innerFocus $colors$interactiveFocusInner',
    outline: '$borderWidths$innerFocus  solid transparent',
  },
  '&:hover:not(:focus)': {
    borderColor: '$inputBorderHovered',
    backgroundColor: '$inputBackgroundHovered',
  },
});

export const RuleImport = ({ onQueryChange }: RuleImportProps) => {
  const {
    error,
    inputValue,
    ruleImportOpen,
    setRuleImportOpen,
    handleFieldChange,
    handleImportRule,
  } = useImportRule({
    onQueryChange,
  });

  const { formatMessage } = useIntl();

  return (
    <Modal.Root
      open={ruleImportOpen}
      onOpenChange={() => {
        setRuleImportOpen((previous) => !previous);
      }}
    >
      <Modal.Trigger>
        <Button
          size="medium"
          priority="secondary"
          icon="interfaceDownload"
          type="button"
          css={{
            flexGrow: '0',
            marginRight: '$half',
          }}
        >
          {formatMessage({ id: 'IMPORT_JSON' })}
        </Button>
      </Modal.Trigger>
      <Modal.Content
        css={{
          '.GDS-modal-content': {
            paddingTop: '$none',
            paddingRight: '$two',
            paddingLeft: '$two',
            paddingBottom: '$one',
          },
        }}
      >
        <StackLayout
          css={{
            width: '100%',
          }}
        >
          <Modal.Title>
            <HeadingText
              size="lg"
              level="2"
              css={{
                marginBottom: '$half',
              }}
            >
              {formatMessage({ id: 'IMPORT_JSON' })}
            </HeadingText>
          </Modal.Title>
          <InputLabel
            htmlFor="importRule"
            required
            css={{
              marginBottom: '$half',
              typography: '$bodyOne',
              color: '$onSurfaceTextPrimary',
            }}
          >
            {formatMessage({ id: 'IMPORT_JSON_LABEL' })}
          </InputLabel>
          <TextAreaInput
            id="importRule"
            aria-describedby="statusMessage"
            value={inputValue}
            onChange={handleFieldChange}
          />
          {/* TODO: Remove with Genesis fix for react 18
          @ts-ignore */}
          <InputHint
            css={{
              marginTop: '$half',
            }}
          >
            {formatMessage({ id: 'IMPORT_JSON_HINT' })}
          </InputHint>
          <StackItem
            css={{
              flexDirection: 'row',
              justifyContent: 'right',
              alignItems: 'center',
              width: '100%',
              marginTop: '$one',
            }}
          >
            <Modal.Close>
              <Button
                priority="secondary"
                quiet
                size="medium"
                type="button"
                css={{
                  flexGrow: '0',
                  border: 'none',
                }}
              >
                {formatMessage({ id: 'CLOSE' })}
              </Button>
            </Modal.Close>
            <Button
              priority="primary"
              size="medium"
              onClick={() => handleImportRule(inputValue)}
              css={{
                flexGrow: '0',
                marginLeft: '$half',
              }}
            >
              {formatMessage({ id: 'IMPORT_CTA' })}
            </Button>
          </StackItem>
          <Box
            aria-live="polite"
            id="statusMessage"
            css={{
              marginLeft: 'auto',
              marginTop: '$half',
              height: '$one',
            }}
          >
            {error.isError ? (
              // TODO :: Fix @ts-ignore
              // @ts-ignore
              <InputStatusMessage inputStatus="error" id="statusComponent">
                {formatMessage({ id: error.messageId })}
              </InputStatusMessage>
            ) : null}
          </Box>
        </StackLayout>
      </Modal.Content>
    </Modal.Root>
  );
};
