import type {
  AppTenantConfig,
  RulesTemplate,
  TenantConfig,
} from '@web-config-app/core';
import type { LeagueConfig } from '@leagueplatform/core';
import {
  commonCoreConfig,
  commonAppEnvironment,
  commonCoreAuthClientOptionsClientId,
  commonCoreAuthClientOptionsDomain,
  commonCoreAuthClientOptionsRedirectUri,
} from '../configs/common/core';
import { mergeTenantStrings, type TenantStrings } from './merge-tenant-strings';
import { mergeRulesTemplates } from './merge-rules-templates';
import leagueLogo from '../assets/league-logo.svg';

export type ConfigAppCoreTenantConfig = Pick<
  LeagueConfig['core'],
  'contentUrl' | 'analytics' | 'api'
> & {
  appEnvironment?: LeagueConfig['core']['appEnvironment'];
  auth: ConfigAppCoreAuth;
};

type LeagueConfigCoreAuth = Extract<
  LeagueConfig['core']['auth'],
  { clientOptions: any }
>;
export interface ConfigAppCoreAuth
  extends Omit<LeagueConfigCoreAuth, 'clientOptions'> {
  clientOptions: ConfigAppCoreAuthClientOptions;
}

export interface ConfigAppCoreAuthClientOptions
  extends Omit<LeagueConfigCoreAuth['clientOptions'], 'domain'> {
  domain?: LeagueConfigCoreAuth['clientOptions']['domain'];
  client_id?: LeagueConfigCoreAuth['clientOptions']['client_id'];
  redirect_uri?: LeagueConfigCoreAuth['clientOptions']['redirect_uri'];
}

export interface ConfigAppTenantConfig
  extends Omit<TenantConfig, 'rulesTemplates' | 'tenantLogo'> {
  tenantLogo?: string;
}
export interface CreateTenantConfigArgs {
  core: ConfigAppCoreTenantConfig;
  configAppTenantConfig: ConfigAppTenantConfig;
  strings?: TenantStrings;
  rulesTemplates?: RulesTemplate[];
}

const getAuth0ClientOptions = (authConfig: ConfigAppCoreAuthClientOptions) => ({
  ...authConfig.clientOptions,
  domain: authConfig.clientOptions?.domain ?? commonCoreAuthClientOptionsDomain,
  client_id:
    authConfig.clientOptions?.client_id ?? commonCoreAuthClientOptionsClientId,
  redirect_uri:
    authConfig.clientOptions?.redirect_uri ??
    commonCoreAuthClientOptionsRedirectUri,
});

export const createTenantConfig = ({
  core,
  strings,
  configAppTenantConfig,
  rulesTemplates,
}: CreateTenantConfigArgs): AppTenantConfig => {
  const { en: enLocale, ...tenantStringsOtherLocales } =
    mergeTenantStrings(strings);
  const tenantRulesTemplates = mergeRulesTemplates(rulesTemplates);
  const auth0ClientOptions = getAuth0ClientOptions(core.auth);
  return {
    core: {
      ...commonCoreConfig,
      ...core,
      auth: {
        ...core.auth,
        clientOptions: auth0ClientOptions,
      } as LeagueConfigCoreAuth,
      appEnvironment: core.appEnvironment ?? commonAppEnvironment,
      i18n: {
        stringOverrides: {
          default: enLocale,
          en: enLocale,
          ...tenantStringsOtherLocales,
        },
      },
    },
    configApp: {
      ...configAppTenantConfig,
      tenantLogo: leagueLogo,
      rulesTemplates: tenantRulesTemplates,
    },
  };
};
