import React from 'react';
import { StackItem, StackLayout } from '@leagueplatform/genesis-core';
import { FieldSelectorProps, ValueEditorProps } from 'react-querybuilder';
import { generateFormComponentId } from '../../utils/generate-form-component-id.util';
import { useValueInput } from '../../hooks/use-value-input.hook';
import { useValueFieldType } from '../../hooks/use-value-field-type.hook';
import {
  ValidatedRule,
  validationFieldTypes,
} from '../../hooks/use-validate-rule-query.hook';
import { PLACEHOLDER_MIN_VALUE } from '../../constants/special-values.constants';
import { TextInputField } from '../text-input-field/text-input-field.component';

const dataPointTitle = 'Fields';

export const ValueInput = ({
  context: { query, setQuery, invalidRules = [] },
  handleOnChange,
  title,
  value,
  level,
  path,
  rule,
}: FieldSelectorProps | ValueEditorProps) => {
  const { fieldHint } = useValueFieldType(rule?.field);

  const mustBeDataPoint = title === dataPointTitle;
  const label = mustBeDataPoint ? 'DATA_POINT_LABEL' : 'VALUE_LABEL';

  const uniqueId = generateFormComponentId(title, level, path);
  const validationMsgId = `${uniqueId}-validation-msg`;

  const validationFieldType = mustBeDataPoint
    ? validationFieldTypes.dataPoint
    : validationFieldTypes.value;

  const invalidRuleField = invalidRules.find(
    ({ ruleId, fieldType }: ValidatedRule) =>
      ruleId === rule.id && fieldType === validationFieldType,
  );

  const { operator } = rule;
  const multiFieldOperators = ['between', 'notBetween'];
  const nullFieldOperators = ['null', 'notNull'];

  const {
    handleDataPointSelect,
    valueAsArray,
    handleMultipleValues,
    handleFieldChange,
    handleChangeMultipleValues,
  } = useValueInput({
    value,
    handleOnChange,
    mustBeDataPoint,
    query,
    setQuery,
    path,
    operator,
  });

  if (!mustBeDataPoint && nullFieldOperators.includes(operator)) {
    return (
      // TODO :: Fix @ts-ignore
      // @ts-ignore
      <StackLayout css={{ visibility: 'hidden' }}>
        {/* returning stacklayout because we want to preserve the spacing we would have if input was present */}
      </StackLayout>
    );
  }

  if (!mustBeDataPoint && multiFieldOperators.includes(operator)) {
    const valueInputs = [
      'FLOW_RULE_ENGINE_MIN_VALUE',
      'FLOW_RULE_ENGINE_MAX_VALUE',
    ];

    return (
      <StackLayout orientation="horizontal" verticalAlignment="top">
        {valueInputs.map((key, i) => (
          <StackItem css={{ width: '100%' }} key={key}>
            <TextInputField
              fieldHint={fieldHint}
              handleChange={(e) => handleChangeMultipleValues(e, i)}
              handleSelectDataPoint={handleMultipleValues}
              inputIndex={i}
              invalidRuleField={invalidRuleField}
              label={key}
              mustBeDataPoint={false}
              uniqueId={`${uniqueId}-${key}`}
              validationMsgId={validationMsgId}
              value={
                valueAsArray[i] === PLACEHOLDER_MIN_VALUE ? '' : valueAsArray[i]
              }
            />
          </StackItem>
        ))}
      </StackLayout>
    );
  }

  return (
    <TextInputField
      fieldHint={fieldHint}
      handleChange={handleFieldChange}
      handleSelectDataPoint={handleDataPointSelect}
      invalidRuleField={invalidRuleField}
      label={label}
      mustBeDataPoint={mustBeDataPoint}
      uniqueId={uniqueId}
      validationMsgId={validationMsgId}
      value={value}
    />
  );
};
