import {
  ControlElement,
  TesterContext,
  UISchemaElement,
  and,
  or,
  rankWith,
  resolveSchema,
  uiTypeIs,
  JsonSchema,
} from '@jsonforms/core';
import type { AnnotatedJsonSchema } from '@web-config-app/core';
import { isPrimitiveObjectSchema } from '@web-config-app/schema-utils';
import { TesterRank } from './tester-rank';

/** To find a matching renderer, JSON Forms relies on so-called testers. Every renderer has a tester associated with its registration, which is a function of a UI schema and a JSON schema returning a number. The returned number is the priority which expresses if and how well a renderer can actually render the given UI Schema Element (where NOT_APPLICABLE aka. -1 means "not at all").
 *
 */

/**
 * JsonSchema testers use a `rank` to break ties in instances where multiple testers return `true` for the
 * same property. As an example, for a property with `type: 'string'` and `format: 'date'`, both the
 * `isTextInputControl` and `isDateInputControl` testers below will return true. By giving `isDateInputControl`
 * a higher rank, it will be the matcher used to return the property renderer.
 */

/**
 * Object Layout Testers
 *
 */

/**
 * Must be of object type, all non-primitive objects are rendered with this tester at a lower rank
 */
export const isObjectSchemaType = (
  uischema: UISchemaElement,
  schema: JsonSchema,
  context: TesterContext,
): boolean => {
  const schemaPath = (uischema as ControlElement).scope;
  const resolvedSchema = resolveSchema(
    schema,
    schemaPath,
    context?.rootSchema ?? schema,
  );
  return resolvedSchema.type === 'object';
};

export const isCompoundObjectLayout = rankWith(
  TesterRank.Lower,
  and(isObjectSchemaType, or(uiTypeIs('Group'), uiTypeIs('VerticalLayout'))),
);

/**
 * All object's properties must be of primitive type, if object have a property with type array, will return true if the array.items are of a primitive type
 */

export const isPrimitiveObject = (
  uischema: UISchemaElement,
  schema: JsonSchema,
  context: TesterContext,
): boolean => {
  const schemaPath = (uischema as ControlElement).scope;
  const resolvedSchema = resolveSchema(
    schema,
    schemaPath,
    context?.rootSchema ?? schema,
  );

  return isPrimitiveObjectSchema(resolvedSchema as AnnotatedJsonSchema);
};

export const isPrimitiveObjectLayout = rankWith(
  TesterRank.Base,
  and(uiTypeIs('Group'), isPrimitiveObject),
);

export const isPrimitiveObjectArrayItemLayout = rankWith(
  TesterRank.Base,
  uiTypeIs('PrimitiveObjectArrayItem'),
);
