import jp from 'jsonpath';
import { EntityRootData, Entity } from '../types/entities';

export const getEntityInstanceName = (
  data: EntityRootData | undefined,
  entity: Entity,
) => {
  const entityNamePath = entity.schema['x-entity-metadata']?.entityNamePath;

  if (typeof entityNamePath === 'undefined' || typeof data === 'undefined') {
    return null;
  }

  const instanceName = jp.value(data, entityNamePath);

  return instanceName;
};
