import * as React from 'react';
import { StatusBadge } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { getEntityStatusBadgeProps, EntityStatus } from '@web-config-app/core';

export const EntityStatusBadge: React.FC<{ status: EntityStatus }> = ({
  status: entityStatus,
}) => {
  const { formatMessage } = useIntl();
  const statusBadgeProps = getEntityStatusBadgeProps(entityStatus);
  return (
    <StatusBadge
      status={statusBadgeProps.genesisStatus}
      label={formatMessage({ id: statusBadgeProps.labelKey })}
    />
  );
};
