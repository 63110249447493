import {
  EndpointPathParameter,
  EndpointQueryParameter,
  EndpointParameterValue,
} from '@web-config-app/core';

/**
 * Path parameters replace placeholder values within a path. Placeholder values
 * are in the format `{someParamHere}`, most typically with endpoints that get
 * or set an instance by id
 *
 * @example
 * const endpoint = {
 *   path: 'v1/config-entities/{entityId}',
 *   ...
 * }
 */

export const getEndpointPathWithPathParameters = (
  path: string,
  paramValues: EndpointParameterValue[],
  pathParameters: EndpointPathParameter[],
): string => {
  const pathParamValues = paramValues.filter((paramValue) =>
    pathParameters.find((pathParam) => paramValue.name === pathParam.name),
  );

  /**
   * Using reduce here allows us to iterate over all the path parameter
   * values that correspond to endpoint path parameters (in: `path`) and
   * replace all.
   *
   * @example
   * const path = `v1/config-entities/{entityId}/{entityOperation}
   */

  const pathWithPathParameters = pathParamValues.reduce(
    (composedPath, { name, value }) => {
      const pathParamRegex = new RegExp(`{${name}}`);
      return composedPath.replace(pathParamRegex, String(value));
    },
    path,
  );

  const unfilledParamRegex = /{[a-z]*}/i;
  if (unfilledParamRegex.test(pathWithPathParameters)) {
    /* there are path parameters that have not been replaced, bail and throw an error */
    throw new Error(
      `Missing path parameters to composed path, calculated: '${pathWithPathParameters}'`,
    );
  }

  return pathWithPathParameters;
};

/**
 * Query parameters are appended to a URL as a [Query String]{@link https://en.wikipedia.org/wiki/Query_string}
 */

export const getEndpointPathWithQueryParameters = (
  path: string,
  paramValues: EndpointParameterValue[],
  queryParameters: EndpointQueryParameter[],
): string => {
  const pathParamValues = paramValues.filter((paramValue) =>
    queryParameters.find((queryParam) => paramValue.name === queryParam.name),
  );

  if (pathParamValues.length === 0) {
    return path;
  }

  const joinedPathParameters = pathParamValues
    .map((paramValue) => `${paramValue.name}=${paramValue.value}`)
    .join('&');

  return `${path}?${joinedPathParameters}`;
};

/**
 * returns a fully formed path with param values projected into the final path
 */

export const getEndpointPathWithParameters = (
  path: string,
  paramValues: EndpointParameterValue[],
  pathParameters: EndpointPathParameter[] = [],
  queryParameters: EndpointQueryParameter[] = [],
) => {
  if (paramValues.length === 0 && pathParameters.length === 0) {
    /**
     * We don't have any parameter values and can return path since there are no
     * path placeholders to be filled, ex `v1/config-entities/{pathParameter}
     */
    return path;
  }

  return getEndpointPathWithQueryParameters(
    getEndpointPathWithPathParameters(path, paramValues, pathParameters),
    paramValues,
    queryParameters,
  );
};
