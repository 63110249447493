import { useConfigAppParams } from '../use-config-app-params/use-config-app-params';

interface ConfigAppPathParams {
  domainIdParam?: string;
  entityIdParam?: string;
  entityInstanceIdParam?: string;
}

/**
 * returns dynamic paths used in config app
 *
 * Accepts parameters for any of the variable route ids, but will fall back to the parameters derived from useParams vis the useConfigAppParams
 *
 */

export const useConfigAppPath = ({
  domainIdParam,
  entityIdParam,
}: ConfigAppPathParams = {}) => {
  const { domainId: domainIdUrlParam, entityId: entityIdUrlParam } =
    useConfigAppParams();

  const domainId = domainIdParam || domainIdUrlParam;
  const entityId = entityIdParam || entityIdUrlParam;

  return {
    domainPath: `/domains/${domainId}`,
    entityListPath: `/domains/${domainId}/${entityId}`,
    entityCreatePath: `/domains/${domainId}/${entityId}/create`,
    getEntityDetailsPathForId: (instanceId: string) =>
      `/domains/${domainId}/${entityId}/${instanceId}`,
  };
};
