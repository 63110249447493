import * as React from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import Bold from '@tiptap/extension-bold';
import Blockquote from '@tiptap/extension-blockquote';
import Italic from '@tiptap/extension-italic';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import Document from '@tiptap/extension-document';
import BulletList from '@tiptap/extension-bullet-list';
import ListItem from '@tiptap/extension-list-item';
import Link from '@tiptap/extension-link';
import OrderedList from '@tiptap/extension-ordered-list';
import Heading from '@tiptap/extension-heading';
import History from '@tiptap/extension-history';
import { GDSInputStatus, StackLayout } from '@leagueplatform/genesis-core';
import { RichTextEditorToolbar } from './rich-text-editor-toolbar/rich-text-editor-toolbar.component';
import { richTextEditorCss } from './rich-text-editor.css';

// Use <b> instead of <strong>
const CustomBold = Bold.extend({
  renderHTML({ HTMLAttributes }) {
    return ['b', HTMLAttributes, 0];
  },
});
// use <i> tag instead of <em>
const CustomItalic = Italic.extend({
  renderHTML({ HTMLAttributes }) {
    return ['i', HTMLAttributes, 0];
  },
});

export const tipTapExtensions = [
  CustomBold,
  Blockquote,
  Paragraph,
  Text,
  Document,
  BulletList,
  ListItem,
  OrderedList,
  CustomItalic,
  Heading,
  History,
  Link.configure({
    openOnClick: false,
    autolink: true,
  }),
];

interface RichTextEditorProps {
  value: string;
  id: string;
  readOnly?: boolean;
  inputStatus?: GDSInputStatus;
  editorCacheKey?: string;
  onChange: (newValue: string) => void;
}

const useEditorConfig = ({
  editable,
  id,
  inputStatus,
  onChange,
  value,
  editorCacheKey,
}: Omit<RichTextEditorProps, 'readOnly'> & {
  editable: boolean;
}) => {
  const cacheKeyRef = React.useRef<string | undefined>(editorCacheKey);
  const valueRef = React.useRef<string | undefined>(value);

  const editorProps = {
    attributes: {
      id,
      as: 'textarea',
      'data-testid': 'rich-text-editor',
      class: `${
        inputStatus === 'error'
          ? 'rich-text-editor-input error'
          : 'rich-text-editor-input'
      }`,
    },
  };

  const editorConfig = useEditor(
    {
      extensions: tipTapExtensions,
      editable,
      content: valueRef.current,
      editorProps,
      onUpdate: ({ editor }) => {
        onChange(editor.getHTML());
      },
    },
    [cacheKeyRef.current],
  );

  React.useEffect(() => {
    /**
     * The tip tap editor is pretty finicky when it comes to updating content. We need some mechanism
     * to construct a key that tells `useEditor` when it needs to create a new instance containing the current
     * `value`
     *
     * Since the value and editorCacheKey get updated on separate render cycles in the config app, we want to
     * retain the reference to them until BOTH of the values have been updated. Updating the `cacheKeyRef.current`
     * will trigger the destroy/create cycle and return a NEW editorConfig
     */
    if (cacheKeyRef.current !== editorCacheKey && valueRef.current !== value) {
      cacheKeyRef.current = editorCacheKey;
      valueRef.current = value;
    } else {
      /**
       * Otherwise we need to keep the valueRef.current up to date with any changes to avoid
       * the above check failing when value has been updated and a user switches to a new form page that
       * also contains a rich text property.
       */
      valueRef.current = value;
    }
  }, [editorCacheKey, value]);

  return editorConfig;
};

export const RichTextEditor = ({
  value = '',
  id,
  readOnly = false,
  onChange,
  inputStatus,
  editorCacheKey,
}: RichTextEditorProps) => {
  const editorConfig = useEditorConfig({
    editable: !readOnly,
    id,
    onChange,
    inputStatus,
    value,
    editorCacheKey,
  });

  return (
    <StackLayout css={richTextEditorCss} horizontalAlignment="stretch">
      <RichTextEditorToolbar editor={editorConfig} readOnly={readOnly} />
      <EditorContent editor={editorConfig} />
    </StackLayout>
  );
};
