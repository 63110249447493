import * as React from 'react';
import {
  Button,
  GDSSelectProps,
  Select,
  StackItem,
  StackLayout,
  UtilityText,
} from '@leagueplatform/genesis-core';
import type { RulesTemplate } from '@web-config-app/core';
import { useIntl } from '@leagueplatform/locales';

interface JsonLogicInputTopBarProps {
  rulesTemplates: RulesTemplate[];
  onChange: GDSSelectProps['onChange'];
  selectedTemplate?: GDSSelectProps['value'];
  onClickApply: () => void;
}

export const JsonLogicInputTopBar = ({
  rulesTemplates,
  onChange,
  selectedTemplate,
  onClickApply,
}: JsonLogicInputTopBarProps) => {
  const { formatMessage } = useIntl();
  const rulesTemplateOptions = React.useMemo(
    () => [
      {
        value: 'none',
        label: formatMessage({ id: 'NONE_RULES_TEMPLATE' }),
      },
      ...rulesTemplates.map((template) => ({
        value: template.id,
        label: formatMessage({ id: template.label }),
      })),
    ],
    [formatMessage, rulesTemplates],
  );
  return (
    <StackLayout
      orientation="horizontal"
      verticalAlignment="center"
      css={{
        width: '100%',
        '.GDS-select-menu': {
          paddingLeft: '$half',
          paddingTop: '$quarter',
          paddingBottom: '$quarter',
        },
      }}
    >
      <StackItem
        css={{ width: '100%', flexDirection: 'row', alignItems: 'center' }}
      >
        <UtilityText
          as="label"
          htmlFor="rule-template"
          css={{ paddingX: '$half', flexGrow: 0 }}
          emphasis="subtle"
          size="xs"
        >
          {formatMessage({ id: 'RULE_TEMPLATE' })}
        </UtilityText>
        <Select
          options={rulesTemplateOptions}
          onChange={onChange}
          value={selectedTemplate}
          id="rule-template"
        />
        <Button
          size="small"
          priority="primary"
          css={{ flexGrow: 0, marginLeft: '$half' }}
          onClick={onClickApply}
        >
          {formatMessage({ id: 'APPLY' })}
        </Button>
      </StackItem>
      <Button
        as="a"
        icon="interfaceQuestionCircle"
        quiet
        size="small"
        priority="tertiary"
        target="_blank"
        // TODO:: Update with link once documentation is done
        href="https://everlong.atlassian.net/wiki/spaces/CACT/overview"
      >
        {formatMessage({ id: 'HELP' })}
      </Button>
    </StackLayout>
  );
};
