import * as React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { isEmpty } from 'lodash-es';

type FileError = {
  fileError?: string;
  titleError?: string;
  descriptionError?: string;
};

export function useUploadImageModal({ onCancel }: { onCancel: () => void }) {
  const { formatMessage } = useIntl();
  const [title, setTitle] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [file, setFile] = React.useState<File | null>(null);
  const [fileUploadError, setFileUploadError] = React.useState<Error | null>(
    null,
  );
  const [formErrors, setFormErrors] = React.useState<FileError>({});

  const getErrorProps = (statusMessage: string) =>
    ({
      inputStatus: 'error',
      statusIconLabel: formatMessage({ id: 'ERROR' }),
      statusMessage,
    } as const);

  const clearValues = () => {
    setFile(null);
    setFileUploadError(null);
    setTitle('');
    setDescription('');
    setFormErrors({});
  };

  const handleOpenChange = (isOpen: boolean) => {
    if (!isOpen) {
      onCancel();
      clearValues();
    }
  };

  const validateForm = () => {
    setFormErrors({});
    const errors: FileError = {};
    if (!file) {
      errors.fileError = formatMessage({ id: 'NO_FILE_INCLUDED_ERROR' });
    }
    if (!title) {
      errors.titleError = formatMessage({ id: 'NO_TITLE_INCLUDED_ERROR' });
    }
    if (!isEmpty(errors)) {
      setFormErrors(errors);
    }
  };

  return {
    title,
    setTitle,
    description,
    setDescription,
    file,
    setFile,
    setFileUploadError,
    formErrors,
    handleOpenChange,
    validateForm,
    getErrorProps,
    fileErrorMessage: formErrors.fileError || fileUploadError?.message,
    clearValues,
  };
}
