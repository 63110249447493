import * as React from 'react';
import { TextInput } from '@leagueplatform/genesis-core';
import { ControlComponent } from '../../../types/controls';
import { EntityFormControl } from '../../entity-form-control/entity-form-control.component';

export const TextInputControl: ControlComponent = (props) => (
  <EntityFormControl
    {...props}
    renderControl={({
      data,
      handleChange,
      path,
      schema,
      required,
      inputStatus,
      enabled,
    }) => {
      const { pattern, maxLength, minLength, format } = schema;
      /**
       * We may want to, in the future, implement a specific EmailInputControl but, in the meantime
       * JSON Schema supports two email `format` options, as defined
       * here: https://json-schema.org/understanding-json-schema/reference/string#email-addresses
       */
      const inputType = ['email', 'idn-email'].includes(format ?? '')
        ? 'email'
        : undefined;

      return (
        <TextInput
          value={data ?? ''}
          // TODO :: set readOnly if const value present as well https://everlong.atlassian.net/browse/CACT-721
          readOnly={!enabled}
          required={required}
          pattern={pattern}
          minLength={minLength}
          maxLength={maxLength}
          type={inputType}
          inputStatus={inputStatus}
          clearable={enabled && data && data.length > 0}
          onChange={(evt) => {
            handleChange(path, evt.currentTarget.value);
          }}
          onClear={() => handleChange(path, undefined)}
          css={{
            backgroundColor: '$inputBackgroundDefault',
          }}
        />
      );
    }}
  />
);
