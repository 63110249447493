/**
 * These strings get added to our mock config so we can properly render stories in `packages/entity-form`
 * since they expect localized strings for properties like label, hint, banner, etc.
 */

export const MOCK_EN_STRINGS: { [key: string]: string } = {
  MOCK_COMPLEX_OBJECT_LABEL_KEY: 'Very Complicated Object',
  MOCK_PRIMITIVE_OBJECT_LABEL_KEY: 'A Simple Object',
  MOCK_COMPLEX_OBJECT_TWO_LABEL_KEY: 'Another Complex Object',
  MOCK_INPUT_BANNER_TITLE: 'A Banner Title',
  MOCK_INPUT_BANNER_DESCRIPTION:
    'A banner description here to provide helpful context to a content author',
  MOCK_INPUT_HINT: 'Here is a helpful hint for you, friend',
  MOCK_PRIMITIVE_OBJECT_HINT: 'I am just a primitive object.',
  MOCK_BANNER_TITLE_IMPORTANT: 'Important!',
  MOCK_PRIMITIVE_OBJECT_BANNER_DESCRIPTION:
    'I am the primitive objects banner.',
  MOCK_COMPLEX_OBJECT_HINT:
    'I am a complex/compound object with a nested object inside of me',
  MOCK_PRIMITIVE_ARRAY_HINT: 'I am an array with primitive items',
  MOCK_BANNER_TITLE_GOOD_TO_KNOW: 'Good to know',
  MOCK_COMPLEX_OBJECT_BANNER_DESCRIPTION:
    'I am the complex root objects banner.',
  MOCK_PRIMITIVE_ARRAY_BANNER_DESCRIPTION: 'I am the primitive array banner.',
  MOCK_COMPLEX_COMPOUND_OBJECT_HINT:
    'I am a complex/compound object with a nested object inside of me',
  MOCK_BASE_INPUT_CONTROL_LABEL: 'Field Name',
  MOCK_BASE_INPUT_HINT:
    'This is the property hint and it is fun to see in a real component',
  MOCK_BASE_INPUT_BANNER_DESCRIPTION:
    'This field is important, so when you are filling it out make sure you think about this important stuff',
  MOCK_BOOLEAN_INPUT_LABEL: 'Use relative time',
  MOCK_ENTITY_REFERENCE_INPUT_LABEL: 'Select an entity',
  PUBLISHING_STATUS: 'Publishing Status',
  SELECT_PUBLISHING_STATUS: 'Select a publishing status',
  PUBLISHED: 'Published',
  DRAFT: 'Draft',
  UNSAVED_CHANGES: 'Unsaved changes',
  ALL_CHANGES_SAVED: 'All changes saved',
  ALL_CHANGES_PUBLISHED: 'All changes published',
  UNPUBLISHED_CHANGES: 'Unpublished changes',
  PICK_SOMETHING: 'Pick something',
  THIS: 'This',
  THAT: 'That',
  THE_OTHER: 'The Other',
  METADATA: 'Metadata',
  THING: 'Thing',
  THINGS: 'Things',
  THING_TYPE_ONE: 'Thing Type One',
  CONTENT_DATA: 'Content Data',
  THING_NAME: 'Thing Name',
  THING_SIZE: 'Thing Size',
  OWLS: 'Owls',
  ADD_OWL: 'Add Owl',
  SIMPLE_SCHEMA_ENTITY_NAME: 'Entity',
  SIMPLE_SCHEMA_PRIMITIVE_TEXT_LABEL: 'Primitive String',
  SIMPLE_SCHEMA_COMPLEX_OBJECT_LABEL: 'Complex Object',
  SIMPLE_SCHEMA_PRIMITIVE_ENUM_LABEL: 'Primitive Enum',
  SIMPLE_SCHEMA_COMPLEX_NESTED_OBJECT_LABEL: 'Nested Object',
  SIMPLE_SCHEMA_COMPLEX_NESTED_OBJECT_NAME_LABEL: 'Name',
  SIMPLE_SCHEMA_COMPLEX_NESTED_OBJECT_AGE_LABEL: 'Age',
  SIMPLE_SCHEMA_ARRAY_LABEL: 'Things',
  SIMPLE_SCHEMA_ARRAY_ITEM_LABEL: 'Thing',
  SIMPLE_SCHEMA_ARRAY_ITEM_NAME_LABEL: 'Array Item',
  SIMPLE_SCHEMA_THING_LABEL: 'A Thing',
  SIMPLE_SCHEMA_START_DATE: 'Start date',
  SIMPLE_SCHEMA_END_DATE: 'End date',
  CACTUSES: '{count, plural, one {Cactus} other {Cactuses}}',
  PYGMY_OWL_LABEL: 'Pygmy Owl',
  PYGMY_OWLS: '{count, plural, one {Pygmy Owl} other {Pygmy Owls}}',
  PYGMY_OWL_HINT: 'Choose a pygmy owl to live in this cactus',
  MOCK_ENTITY_REFERENCE_HINT: 'Select a cactus to include in this thing OK!?!',
  MOCK_ARRAY_PRIMITIVE_OBJECT_LABEL: 'I am an array',
  MOCK_ARRAY_PRIMITIVE_OBJECT_HINT: 'My items are object primitive type!!!',
  MOCK_ARRAY_PRIMITIVE_BANNER_TITLE: 'What is an array?',
  MOCK_ARRAY_PRIMITIVE_BANNER_TEXT: 'An array is cool!',
  MOCK_ARRAY_COMPLEX_OBJECT_LABEL: 'Array of complex objects',
  MOCK_ARRAY_ADD_LABEL: 'Add more wee children',
  MAIL: 'Mail',
  ADDRESS_OR_USER: 'Address or User',
  USER_KEY: 'User',
  ADDRESS_KEY: 'Address',
  LOCATION: 'Location',
  LOCATION_TYPE: 'Location type',
  LOCATION_TYPE_HINT: 'Select the appropriate location type',
  SELECT_LOCATION_OPTION: 'Select a location option',
  JSON_LOGIC_HINT: 'Enter json logic to configure rules',
  ENTITY_ROOT_OBJECT_LABEL: 'Entity root object',
  META_DATA_LABEL: 'Meta data',
  INTERNAL_NAME_HINT: 'This is internal only, a primitive string type',
  ENUM_SELECT_PLACEHOLDER: 'I am the enumControlPlaceholder',
  /** rich text toolbar - used for tests */
  NORMAL_LABEL: 'Normal',
  HEADING_1_LABEL: 'Heading 1',
  HEADING_2_LABEL: 'Heading 2',
  HEADING_3_LABEL: 'Heading 3',
  HEADING_4_LABEL: 'Heading 4',
  HEADING_5_LABEL: 'Heading 5',
  HEADING_6_LABEL: 'Heading 6',
  NUMBER_LIST_LABEL: 'Number list',
  BULLET_LIST_LABEL: 'Bullet list',
  QUOTE_LABEL: 'Quote',
  UNDO_LABEL: 'Undo',
  REDO_LABEL: 'Redo',
  BOLD_LABEL: 'Bold',
  INSERT_LINK_LABEL: 'Insert link',
  ITALIC_LABEL: 'Italic',
  STYLES_LIST: 'Styles list. {selectedBlockElement} text selected.',
  MOCK_A_COMPONENT: 'A Component',
  /* Rules Template Names */
  MOCK_ACTIVITIES_RULES_TEMPLATE: 'Activities Rules Template',
  MOCK_CAMPAIGNS_RULES_TEMPLATE: 'Campaigns Rules Template',
  MOCK_CACTUSES_RULES_TEMPLATE: 'Cactuses Rules Template',
  MOCK_OWLS_RULES_TEMPLATE: 'Owls Rules Template',
};

export const getMockTranslationForKey = (key: string = '') => {
  const label = MOCK_EN_STRINGS[key];
  return label ?? `Unknown key ${key}`;
};
