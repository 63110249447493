import * as React from 'react';
import { Box, ParagraphText } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';

/**
 Card that shows up if there are no array items in any type of array
 */

export const EmptyArrayState = ({ item }: { item: string }) => {
  const { formatMessage } = useIntl();
  return (
    <Box
      data-testid="array-empty-state"
      css={{
        border: 'solid $colors$onSurfaceBorderSubdued $borderWidths$thin',
        borderRadius: '$medium',
        padding: '$two $oneAndQuarter',
        width: '100%',
      }}
    >
      <ParagraphText emphasis="subtle" css={{ textAlign: 'center' }}>
        {formatMessage({ id: 'NO_ARRAY_ITEMS_YET' }, { item })}
      </ParagraphText>
    </Box>
  );
};
