import * as React from 'react';
import {
  Button,
  StackLayout,
  styled,
  HeadingText,
  StackItem,
  InputLabel,
  Modal,
  InputStatusMessage,
  Box,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { CopyStatusMsgTypes } from '../../hooks/use-generate-json-logic.hook';

// TODO :: replace with Genesis TextInput when it takes textarea in "as"
const ReadOnlyInput = styled('textarea', {
  typography: '$bodyTwo',
  color: '$onSurfaceTextPrimary',
  backgroundColor: '$inputBackgroundDisabled',
  border: '$borderWidths$thin solid $inputBorderDefault',
  borderRadius: '$medium',
  padding: '$threeQuarters $one',
  flex: '1 0 auto',
  width: '100%',
  resize: 'none',
  minHeight: '27rem',
  transition: 'box-shadow 0.2s ease-in-out',
  '&:focus': {
    borderColor: '$interactiveFocusInner',
  },
});

export interface JsonLogicOutputProps {
  generateJsonProps: {
    jsonLogicString?: string;
    copyToClipboard?: () => Promise<void>;
    copyStatusMsg: CopyStatusMsgTypes | null;
    modalOpen?: boolean;
    onModalOpenChange?: (open: boolean) => void;
    handleUpdateJson?: (query: any) => void;
  };
}

export const JsonLogicOutput = ({
  generateJsonProps,
}: JsonLogicOutputProps) => {
  const { formatMessage } = useIntl();

  // This is not an ideal solution, but we need to retain the useGenerateJsonLogic
  // state between the CreateRulePage and this component in order to trigger the modal
  // and render the formatted JSON object. This should be reworked whenever possible.
  const {
    jsonLogicString,
    copyToClipboard,
    copyStatusMsg,
    modalOpen,
    onModalOpenChange,
  } = generateJsonProps;

  return (
    <Modal.Root open={modalOpen} onOpenChange={onModalOpenChange}>
      <Modal.Trigger />
      <Modal.Content
        css={{
          '.GDS-modal-content': {
            paddingTop: '$none',
            paddingRight: '$two',
            paddingLeft: '$two',
            paddingBottom: '$one',
          },
        }}
      >
        <StackLayout
          css={{
            width: '100%',
          }}
        >
          <Modal.Title>
            <HeadingText
              level="2"
              size="lg"
              css={{
                marginBottom: '$half',
              }}
            >
              {formatMessage({ id: 'GENERATE_JSON' })}
            </HeadingText>
          </Modal.Title>
          <InputLabel
            htmlFor="jsonOutput"
            required
            css={{
              marginBottom: '$oneAndHalf',
              typography: '$bodyOne',
              color: '$onSurfaceTextPrimary',
            }}
          >
            {formatMessage({ id: 'GENERATE_JSON_INPUT_LABEL' })}
          </InputLabel>
          <ReadOnlyInput
            key={jsonLogicString}
            value={jsonLogicString}
            id="jsonOutput"
            aria-describedby="statusMessage"
            readOnly
          />
          <StackItem
            css={{
              flexDirection: 'row',
              justifyContent: 'right',
              alignItems: 'center',
              width: '100%',
              marginTop: '$one',
            }}
          >
            <Modal.Close>
              <Button
                priority="secondary"
                quiet
                size="medium"
                type="button"
                css={{
                  flexGrow: '0',
                  border: 'none',
                }}
              >
                {formatMessage({ id: 'CLOSE' })}
              </Button>
            </Modal.Close>
            <Button
              priority="primary"
              size="medium"
              onClick={copyToClipboard}
              css={{
                flexGrow: '0',
                marginLeft: '$half',
              }}
            >
              {formatMessage({ id: 'COPY_JSON' })}
            </Button>
          </StackItem>
          <Box
            aria-live="polite"
            id="statusMessage"
            css={{
              marginLeft: 'auto',
              marginTop: '$half',
              height: '$one',
            }}
          >
            {copyStatusMsg ? (
              // TODO: Remove with Genesis fix for react 18
              // @ts-ignore
              <InputStatusMessage
                inputStatus={copyStatusMsg?.status}
                id="statusComponent"
              >
                {formatMessage({ id: copyStatusMsg?.msg })}
              </InputStatusMessage>
            ) : null}
          </Box>
        </StackLayout>
      </Modal.Content>
    </Modal.Root>
  );
};
