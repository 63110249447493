import { AppTenant } from 'types';
import {
  DomainConfig,
  EntityConfig,
  mapStringToCategoryType,
} from '@web-config-app/core';
import GeneratedTenantConfigs from './json/tenants.json';
import GeneratedDomainConfigs from './json/domains.json';
import GeneratedEntityConfigs from './json/entities.json';

/**
 * @description Process the generated config JSON from `api-specs` via the `generate-configs` package.
 *
 * That package uses YAML files in the `.config-registry` that indicate which domains and entities
 * and entities are included in a tenant's app configuration.
 *
 */

type EntityId = keyof typeof GeneratedEntityConfigs;
type DomainId = keyof typeof GeneratedDomainConfigs;
type GeneratedConfigTenantId = keyof typeof GeneratedTenantConfigs;
type ConfigTenantId<T> = T extends `${AppTenant}` ? T : never;

/**
 *  @returns the entity object for the given entityId key from `./generated/entities.json`
 */

export function getGeneratedEntityConfig(entityId: EntityId) {
  return GeneratedEntityConfigs[entityId] as EntityConfig;
}

/**
 * @returns the domain object for the given `domainId` key from `./generated/domains.json` with
 * an array of entities returned from `getGeneratedEntityConfig`.
 *
 * Domains are setup in their respective YAML registry files with a set of default entities but it
 * is possible to override the default entities for a domain in a tenant's config in a tenant
 * registry file. Those overrides are passed as the `overrideEntities` param.
 */

export function getGeneratedDomainConfig(
  domainId: DomainId,
  overrideEntities?: EntityId[],
): DomainConfig {
  const domainData = GeneratedDomainConfigs[domainId];

  // Ensure category is of CategoryType
  const category = mapStringToCategoryType(domainData.category);

  return {
    ...domainData,
    entities: (overrideEntities ?? domainData.entities).map((entityId) =>
      getGeneratedEntityConfig(entityId as EntityId),
    ),
    category,
  };
}

/**
 * @returns a array of domain and their associated entities for a specific
 * tenant for the given `tenantId` key from `./generated/tenants.json`
 */

export function getGeneratedDomainsConfigForTenant(
  tenantId: ConfigTenantId<GeneratedConfigTenantId>,
) {
  const tenantData = GeneratedTenantConfigs[tenantId];

  return tenantData.domains.map(
    ({ id, entities }: { id: string; entities?: string[] }) =>
      getGeneratedDomainConfig(id as DomainId, entities as EntityId[]),
  );
}
