import * as React from 'react';
import { ControlProps } from '@jsonforms/core';
import { PrimitiveControlFormField } from '../primitive-control-form-field/primitive-control-form-field.component';
import { EntityFormControlProps } from '../../types/controls';
import { useEntityFormControl } from '../../hooks/use-entity-form-control/use-entity-form-control';
import { Hidden } from '../hidden/hidden.component';

/*
 * EntityFormControl encapsulates taking in the JsonForms props, calling useEntityFormControl to get the correct annotation/props, and passing those to the individual controls. The renderControl prop lets you completely take over how the form control is rendered.
 *
 * Optional wrapperComponent that can be passed to override the default PrimitiveControlFormField for the control
 *
 */

export interface EntityFormControlOwnProps extends ControlProps {
  wrapperComponent?: React.ComponentType<any>;
  renderControl?: (props: EntityFormControlProps) => JSX.Element;
}

export const EntityFormControl: React.FC<EntityFormControlOwnProps> = ({
  wrapperComponent: WrapperComponent = PrimitiveControlFormField,
  renderControl,
  ...props
}) => {
  const entityFormControlProps = useEntityFormControl(props);
  const { hidden } = entityFormControlProps;

  if (hidden) return <Hidden {...entityFormControlProps} />;

  return renderControl ? (
    <WrapperComponent {...entityFormControlProps}>
      {renderControl(entityFormControlProps)}
    </WrapperComponent>
  ) : null;
};
