import {
  GDSPrimaryButtonTokens,
  GDSSecondaryButtonTokens,
  GDSTertiaryButtonTokens,
} from '@leagueplatform/genesis-core';

const primary: GDSPrimaryButtonTokens = {
  background: {
    default: '#501CD2',
    hovered: '#37209A',
    pressed: '#251669',
    critical: {
      default: '#BA1B3B',
      hovered: '#A51834',
      pressed: '#711124',
    },
  },
  text: {
    default: '#FFFFFF',
    critical: {
      default: '#FFFFFF',
    },
  },
};

const secondary: GDSSecondaryButtonTokens = {
  background: {
    default: 'transparent',
    hovered: '#37209A',
    pressed: '#251669',
  },
  text: {
    default: '#501CD2',
    hovered: '#FFFFFF',
  },
  border: {
    default: '#501CD2',
  },
};

const tertiary: GDSTertiaryButtonTokens = {
  background: {
    default: 'transparent',
    hovered: '#37209A',
    pressed: '#251669',
  },
  text: {
    default: '#66686B',
    hovered: '#FFFFFF',
  },
  border: {
    default: '#949494',
  },
};

export const buttonTokens = {
  primary,
  secondary,
  tertiary,
};
