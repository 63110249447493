import { Box, styled } from '@leagueplatform/genesis-core';

/**
 * override json-editor theme colors as advised in https://github.com/josdejong/svelte-jsoneditor?tab=readme-ov-file#styling
 *
 */

export const StyledJsonEditor = styled(Box, {
  '--jse-theme-color': 'var(--GDS-colors-surfaceBackgroundPrimary)',
  '--jse-menu-color': 'var(--GDS-colors-onSurfaceTextSubdued)',
  '--jse-active-line-background-color':
    'var(--GDS-colors-interactiveBackgroundPressed)',
  '--jse-panel-background': 'var(--GDS-colors-surfaceBackgroundSecondary)',
  '--jse-panel-border': '1px solid var(--GDS-colors-interactiveBorderDefault)',
  '--jse-main-border': '1px solid var(--GDS-colors-interactiveBorderDefault)',
  '--message-success-background': 'var(--GDS-colors-successIcon)',
  '--jse-error-color': 'var(--GDS-colors-primaryBackgroundCriticalDefault)',
  '--jse-theme-color-highlight': 'var(--GDS-colors-primaryBackgroundHovered)',
  // only way to override certain styles is with 'important'
  '.jse-menu .jse-button': {
    fontSize: '$label !important',
    '&:focus, &:hover:not(:disabled)': {
      color: '$primaryTextDefault !important',
    },
    '&.jse-undo, &.jse-redo': {
      borderRadius: '$button !important',
    },
    '&.jse-group-button': {
      padding: '$quarter $half !important',
      '&.jse-first': {
        borderStartStartRadius: '$button',
        borderEndStartRadius: '$button',
      },
      '&:not(.jse-first)': {
        border: '1px solid $interactiveBorderDefault !important',
        borderStartEndRadius: '$button',
        borderEndEndRadius: '$button',
      },
    },
    svg: {
      padding: '1px',
    },
  },
  '.jse-menu': {
    border: '$borderWidths$thin solid $interactiveBorderDefault',
    padding: '$quarter',
    backgroundColor: 'white',
  },
  '.jse-status-bar': {
    borderEndStartRadius: '$medium',
    borderEndEndRadius: '$medium',
    borderBottom: '1px solid $interactiveBorderDefault',
  },
  '.jse-message': {
    borderRadius: '$medium',
    marginTop: '$quarter',
    button: {
      borderRadius: '$button',
      display: 'flex',
      alignItems: 'center',
      svg: {
        marginRight: '$half',
      },
    },
  },
  // Variant to apply additional styles when showTopBar is false
  variants: {
    showTopBar: {
      false: {
        '.jse-menu': {
          borderStartStartRadius: '$medium',
          borderStartEndRadius: '$medium',
        },
      },
    },
  },
});
