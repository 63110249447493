import { ControlProps } from '@jsonforms/core';
import { EntityFormControlStatusProps } from '../types/controls';

export const mapControlPropsToControlStatus = ({
  errors,
}: ControlProps): EntityFormControlStatusProps => {
  if (errors.length > 1) {
    return {
      inputStatus: 'error',
      statusMessage: errors,
    };
  }

  return {
    inputStatus: undefined,
    statusMessage: undefined,
  };
};
