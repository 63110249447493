/**
 * This function is intended for use with the integer input control and number input control. It can be used to convert user input into the appropriate value necessary for our entity form. In the case of an empty string value (when a user has typed a value, then backspaced down to nothing), we should return `undefined`. Otherwise, we wrap pass the inputString to the Number constructor.
 *
 *
 * @param inputString this string should come from the user's input into a number or integer control. It should typically be evt.currentTarget.value
 * @returns `undefined` in the case where the inputString is empty (`""`), otherwise the number version of the input string. E.g.  "12345" becomes 12345
 */
export const deriveNumberFromInputString = (inputString: string) => {
  // The Number() constructor converts an empty string to 0, so in the case where a user has deleted text
  // and evt.currentTarget.value is '', we want pass `undefined` to the handleChange function instead of 0
  if (!inputString) {
    return undefined;
  }
  return Number(inputString);
};
