import * as React from 'react';
import { LeagueProvider } from '@leagueplatform/core';
import { mockConfig } from '@web-config-app/common';
import { AppTenantConfig } from '@web-config-app/core';
import { mockDesertsDomain } from '@web-config-app/mock-data';

const { configApp } = mockConfig as AppTenantConfig;

export const mockConfigWithEntities = {
  ...mockConfig,
  configApp: {
    ...configApp,
    domains: [...configApp.domains, mockDesertsDomain],
  },
};

export const CustomTestLeagueProvider = ({
  children,
}: React.PropsWithChildren) => (
  <LeagueProvider config={mockConfigWithEntities}>{children}</LeagueProvider>
);
