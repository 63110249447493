import {
  RulesTemplate,
  RulesTemplateWithoutEntityType,
} from '@web-config-app/core';

/**
 * This is a utility to make it a bit less verbose when writing out a bunch of rule templates
 * which allows the template writer to omit the entityType. Usage:
 *
 * @example
 *
 * const rulesTemplates = rulesTemplatePartialsArray.map(mapRulesTemplatesForEntity('some-entity-type))
 */

export const mapRulesTemplatesForEntity =
  (entityType: string) =>
  (ruleTemplatePartial: RulesTemplateWithoutEntityType): RulesTemplate => ({
    ...ruleTemplatePartial,
    entityType,
  });
