import * as React from 'react';
import { Button } from '@leagueplatform/genesis-core';
import { useClipboardCopy } from '@web-config-app/core-react';

export const CopyButton = ({
  label,
  priority,
  contents,
  disabled,
  hideLabel,
}: {
  label: string;
  priority: 'secondary' | 'tertiary';
  contents: string;
  disabled?: boolean;
  hideLabel?: boolean;
}) => {
  const { copyContentsToClipboard, isCopied } = useClipboardCopy(contents);

  return (
    <Button
      disabled={disabled}
      css={
        isCopied
          ? { color: '$successIcon', '&:hover': { color: '$successIcon' } }
          : undefined
      }
      priority={priority}
      quiet
      icon={isCopied ? 'interfaceCheckmark' : 'interfaceCopy'}
      size="toolbar"
      onClick={() => copyContentsToClipboard()}
      hideLabel={hideLabel}
    >
      {label}
    </Button>
  );
};
