import {
  tenantSpecificConfigs,
  TenantId,
} from '../configs/tenant-configs/tenant-configs.constants';

export const isValidTenantId = (tenantId: string) => {
  const isTenantIdEmpty = tenantId === '';

  return !isTenantIdEmpty && (tenantId as TenantId) in tenantSpecificConfigs;
};
