import { vanillaRenderers } from '@jsonforms/vanilla-renderers';
import {
  textInputControlRenderer,
  dateInputControlRenderer,
  dateTimeInputControlRenderer,
  numberInputControlRenderer,
  booleanInputControlRenderer,
  integerInputControlRenderer,
  imageInputControlRenderer,
  objectControlRenderer,
  objectCardRenderer,
  arrayPrimitiveControlRenderer,
  selectInputControlRenderer,
  arrayPrimitiveObjectControlRenderer,
  entityReferenceObjectControlRenderer,
  entityReferenceStringControlRenderer,
  arrayEntityReferenceControlRenderer,
  richTextInputControlRenderer,
  jsonLogicInputControlRenderer,
  combinatorObjectInputControlAnyOfRenderer,
  combinatorObjectInputControlOneOfRenderer,
  combinatorArrayInputControlOneOfRenderer,
  constantDiscriminatorCombinatorControlRenderer,
  arrayComplexObjectControlRenderer,
} from './control-renderers';
import {
  objectPrimitiveLayoutRenderer,
  objectCompoundLayoutRenderer,
  objectPrimitiveArrayItemLayoutRenderer,
} from './layout-renderers';

export const defaultRenderers = [
  ...vanillaRenderers,
  textInputControlRenderer,
  dateInputControlRenderer,
  dateTimeInputControlRenderer,
  numberInputControlRenderer,
  booleanInputControlRenderer,
  integerInputControlRenderer,
  richTextInputControlRenderer,
  entityReferenceStringControlRenderer,
  entityReferenceObjectControlRenderer,
  jsonLogicInputControlRenderer,
  imageInputControlRenderer,
  objectPrimitiveLayoutRenderer,
  objectControlRenderer,
  objectCardRenderer,
  arrayEntityReferenceControlRenderer,
  arrayPrimitiveControlRenderer,
  objectCompoundLayoutRenderer,
  selectInputControlRenderer,
  arrayPrimitiveObjectControlRenderer,
  objectPrimitiveArrayItemLayoutRenderer,
  arrayComplexObjectControlRenderer,
  combinatorObjectInputControlAnyOfRenderer,
  combinatorObjectInputControlOneOfRenderer,
  combinatorArrayInputControlOneOfRenderer,
  constantDiscriminatorCombinatorControlRenderer,
];
