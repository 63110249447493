import { useEffect, useState } from 'react';
import { RuleGroupType, RuleType } from 'react-querybuilder';

export const emptyFocusId = '';

export const useManageRuleFocus = () => {
  const [focusId, setFocusId] = useState(emptyFocusId);
  const queryBuilderContainerId = 'query-builder-container';

  const onAddRule = (rule: RuleType) => {
    const ruleId = rule?.id || emptyFocusId;
    if (rule.id) setFocusId(ruleId);
    return rule;
  };

  const onAddGroup = (group: RuleGroupType) => {
    const groupId = group?.id || emptyFocusId;
    if (group.id) setFocusId(groupId);
    return group;
  };

  const onRemove = () => {
    setFocusId(queryBuilderContainerId);
    return true;
  };

  const assignFocusElement = (id = emptyFocusId) => {
    if (id && document.getElementById(id)) {
      document.getElementById(id)?.focus();
      setFocusId(emptyFocusId);
    }
  };

  useEffect(() => {
    if (focusId !== emptyFocusId) assignFocusElement(focusId);
  }, [focusId]);

  return {
    onAddRule,
    onAddGroup,
    onRemove,
    queryBuilderContainerId,
  };
};
