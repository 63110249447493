import { useSearchParams } from '@leagueplatform/web-common';

/**
 * Will retrieve whatever value in the URLSearchParams of the browser's current location matches
 * the passed `param` is.
 *
 * Ex, for location: https://localhost?plant=cactus you would do:
 *
 * ```
 * const plant = useSearchParam('plant')
 */

export const useSearchParam = (param: string) => {
  const searchParams = useSearchParams();
  const value = searchParams.get(param);

  return value;
};
