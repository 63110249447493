import React from 'react';
import { isEmpty } from 'lodash-es';
import { ControlElement } from '@jsonforms/core';
import { JsonFormsDispatch } from '@jsonforms/react';
import { EntityFormDispatchChildrenProps } from '../../types/dispatch';

export const EntityFormDispatchChildren = ({
  layout,
  schema,
  path,
  enabled,
  renderers,
  cells,
}: EntityFormDispatchChildrenProps) => {
  const { elements } = layout;

  if (isEmpty(elements)) {
    return null;
  }

  return (
    <>
      {elements.map((child) => {
        const { scope } = child as ControlElement;
        return (
          <JsonFormsDispatch
            key={`${path}-${scope}`}
            renderers={renderers}
            cells={cells}
            uischema={child}
            schema={schema}
            path={path}
            enabled={enabled}
          />
        );
      })}
    </>
  );
};
