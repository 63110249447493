import React from 'react';
import { isEmpty } from 'lodash-es';
import {
  findUISchema,
  Generate,
  JsonFormsUISchemaRegistryEntry,
} from '@jsonforms/core';
import { JsonFormsDispatch } from '@jsonforms/react';
import { EntityFormStatePropsOfControlWithDetail } from '../../../types/controls';

/**    
 * 
This borrows from the MaterialObjectRenderer here: https://github.com/eclipsesource/jsonforms/blob/ba6bdc5a93044a872285cd71ed40d9a54f437561/packages/material/src/complex/MaterialObjectRenderer.tsx

It calls JsonFormsDispatch which parses the UI schema dispatches the rendering process to appropriate nested components and custom renderers. JsonFormsDispatch also works to resolve schemas using $refs.

Object control renders if something is of Control and type object, it delegates the rendering of the objects individual fields.

If an object that passes through the ObjectControl is not a PrimitiveObjectType, then before the JsonFormDispatch the "Object Link" is rendered

Note: ObjectControl calls useAnnotatedSchemaProps directly and is props wrapper-less as it is a one-off control component 
 * 
*/

export const ObjectControl = ({
  renderers,
  cells,
  // setting as empty array to safeguard for findUISchema
  uischemas = [],
  schema,
  path,
  visible,
  enabled,
  uischema,
  rootSchema,
}: EntityFormStatePropsOfControlWithDetail) => {
  const root = isEmpty(path);

  const detailUiSchema = React.useMemo(
    () =>
      findUISchema(
        uischemas as JsonFormsUISchemaRegistryEntry[],
        schema,
        uischema.scope,
        path,
        // this is the fallback function to use if there is no uiSchema found
        () =>
          // if at the schema root, this returns "VerticalLayout" type for uischema. This matches default json forms uischema behavior and is useful because if something is at the root, we always want to render it the same way (even if technically it is not a complex object schema)
          Generate.uiSchema(
            schema,
            root ? 'VerticalLayout' : 'Group',
            undefined,
            rootSchema,
          ),
        uischema,
        rootSchema,
      ),
    [uischemas, schema, uischema, path, rootSchema, root],
  );

  return (
    <JsonFormsDispatch
      visible={visible}
      enabled={enabled}
      schema={schema}
      uischema={detailUiSchema}
      path={path}
      renderers={renderers}
      cells={cells}
    />
  );
};
