import { useEntityReference } from '@web-config-app/core-react';
import type { EntityDetail } from '@web-config-app/core';
import { getRelationshipSchemaItemType } from '@web-config-app/schema-utils';
import type { EntityReferenceControlSchema } from '../../types/controls';
import { useEntityDetailsProps } from '../use-entity-details-props/use-entity-details-props';
import { useRelationship } from '../use-relationship/use-relationship';
import { useRelationshipsData } from '../use-relationships-data/use-relationships-data';
import type { UseEntityFormHandleChange } from '../use-entity-form-handle-change/use-entity-form-handle-change';

interface UseEntityReferenceSchemaProps {
  schema: EntityReferenceControlSchema;
  handleEntityFormChange?: UseEntityFormHandleChange;
}

export const useEntityReferenceSchema = ({
  schema,
  handleEntityFormChange,
}: UseEntityReferenceSchemaProps) => {
  const { relationship, ...entityReferenceAnnotation } =
    schema['x-entity-reference'];

  const { entityRootData, rootSchema, setEntityRootData } =
    useEntityDetailsProps();

  const { type: entityType } = getRelationshipSchemaItemType(
    relationship,
    rootSchema,
  );

  /**
   * This is the full referenced entity, including the list endpoint data!
   */
  const entity = useEntityReference({ entityType });

  /**
   * It looks like `useRelationshipsData` should be moved inside of `useRelationship`.
   * which shouldn't be too bad - just need to also include rootData and onRelationshipChange
   */

  const { getRelationship, setRelationships } = useRelationshipsData({
    schema: rootSchema,
    rootData: entityRootData,
    onRelationshipChange: (data) => {
      const { relationships, ...rootData } = entityRootData as EntityDetail;
      if (data)
        setEntityRootData({
          ...rootData,
          relationships: data,
        });
    },
  });

  const { setRelationship, ...relationshipProps } = useRelationship({
    relationship,
    schema: rootSchema,
    getRelationship,
    setRelationships,
  });

  const handleRelationshipChange = (
    { id, type }: EntityDetail,
    previousValue?: EntityDetail,
    index?: number,
  ) => {
    setRelationship({ id, type }, previousValue, index);
    handleEntityFormChange?.(id);
  };

  return {
    ...entityReferenceAnnotation,
    ...relationshipProps,
    handleRelationshipChange,
    entity,
  };
};
