import * as React from 'react';
import { ActionMenu } from '@leagueplatform/web-common-components';
import { Button } from '@leagueplatform/genesis-core';

type ActionMenuProps = React.ComponentProps<typeof ActionMenu>;

export interface CombinatorArrayAddActionMenuProps {
  buttonLabel: string;
  menuItems: ActionMenuProps['menuItems'];
  onSelectItem: (itemId: string) => void;
}

export const CombinatorArrayAddActionMenu: React.FC<
  CombinatorArrayAddActionMenuProps
> = ({ buttonLabel, menuItems, onSelectItem }) => (
  <ActionMenu
    menuButton={
      <Button icon="interfacePlus" size="medium" priority="secondary">
        {buttonLabel}
      </Button>
    }
    menuItems={menuItems}
    onSelectItem={onSelectItem}
    css={{ marginTop: '$one' }}
  />
);
