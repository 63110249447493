import * as React from 'react';
import { Suspense, useMemo } from 'react';
import { BrowserRouter } from '@leagueplatform/routing';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import {
  loadTheme,
  GDSThemeProvider,
  Spinner,
} from '@leagueplatform/genesis-core';
import { leagueTheme } from '@web-config-app/common';
import { useOnlineStatus } from '@leagueplatform/web-common';
import { useLocalStorage } from 'usehooks-ts';
import { LOCAL_STORAGE_TENANT_ID_KEY } from 'constants/locale-storage';
import { getTenantSpecificConfig } from 'utils/get-tenant-specific-config';
import { LeagueProvider } from '@leagueplatform/core';
import { ErrorPage } from '@web-config-app/core-react-ui';
import { ConfigUserFeedbackProvider } from '@web-config-app/core-react';
import { ErrorBoundaryWrapper } from './components/error-boundary/error-boundary.component';
import { TenantIdForm } from './components/tenant-id-form/tenant-id-form.component';
import AppRoutes from './components/app-routes.component';
import { globalStyles } from './global-styles';

const coreTheme = loadTheme(leagueTheme, { useRems: true });
const queryClient = new QueryClient();

export function App() {
  const { isOnline } = useOnlineStatus();

  const [tenantId, setTenantId] = useLocalStorage(
    LOCAL_STORAGE_TENANT_ID_KEY,
    '',
  );

  const config = useMemo(() => getTenantSpecificConfig(tenantId), [tenantId]);

  globalStyles();

  return (
    <QueryClientProvider client={queryClient}>
      <GDSThemeProvider theme={coreTheme}>
        <ErrorBoundaryWrapper>
          <Suspense fallback={<Spinner loading loadingText="Loading..." />}>
            {isOnline ? (
              <BrowserRouter>
                {config ? (
                  <LeagueProvider config={config}>
                    <ErrorBoundaryWrapper>
                      <ConfigUserFeedbackProvider>
                        <AppRoutes />
                      </ConfigUserFeedbackProvider>
                      <ReactQueryDevtools />
                    </ErrorBoundaryWrapper>
                  </LeagueProvider>
                ) : (
                  <TenantIdForm onValidSubmit={setTenantId} />
                )}
              </BrowserRouter>
            ) : (
              <ErrorPage />
            )}
          </Suspense>
        </ErrorBoundaryWrapper>
      </GDSThemeProvider>
    </QueryClientProvider>
  );
}
