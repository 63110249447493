import { useState } from 'react';
import { Entity } from '@web-config-app/core';
import {
  UseEntityGetOptions,
  useFindEntityInstanceById,
} from '@web-config-app/core-react';

export const useAddEntityById = (
  entity: Entity,
  options?: UseEntityGetOptions,
) => {
  const [idValue, setIdValue] = useState<string>('');

  const findById = useFindEntityInstanceById({
    entity,
    instanceId: idValue,
    options,
  });

  return { ...findById, idValue, setIdValue };
};
