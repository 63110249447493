import {
  tenantSpecificConfigs,
  TenantId,
} from '../configs/tenant-configs/tenant-configs.constants';

export const getTenantSpecificConfig = (tenantId: string) => {
  if (tenantId in tenantSpecificConfigs) {
    return tenantSpecificConfigs[tenantId as TenantId];
  }
  return undefined;
};
