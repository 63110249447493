import {
  UseEntityGetOptions,
  useEntityReference,
  useFindEntityInstanceById,
} from '@web-config-app/core-react';

export const useGetImageDataById = (
  imageId: string,
  options?: UseEntityGetOptions,
) => {
  const configAssetEntity = useEntityReference({ entityType: 'config-asset' });

  return useFindEntityInstanceById({
    entity: configAssetEntity,
    instanceId: imageId,
    options,
  });
};
