import * as React from 'react';
import { StackLayout } from '@leagueplatform/genesis-core';
import {
  DomainNavItem,
  DomainNavItemProps,
} from './domain-nav-item/domain-nav-item.component';

type DomainNavProps = {
  domains: DomainNavItemProps[];
};

export const DomainNav = ({ domains }: DomainNavProps) => (
  <nav>
    <StackLayout as="ul" spacing="$quarter" horizontalAlignment="stretch">
      {domains.map(({ name, icon, entities, domainPath, isActive }) => (
        <DomainNavItem
          name={name}
          icon={icon}
          entities={entities}
          key={domainPath}
          domainPath={domainPath}
          isActive={isActive}
        />
      ))}
    </StackLayout>
  </nav>
);
