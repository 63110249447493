import type { AnnotatedJsonSchema } from '@web-config-app/core';
import { isPrimitiveObjectSchema } from '../is-primitive-object-schema/is-primitive-object-schema';

const isArrayOrObjectSchema = ({ type }: AnnotatedJsonSchema) =>
  ['object', 'array'].includes(String(type));

export const isPrimitiveArraySchema = (schema: AnnotatedJsonSchema) => {
  if (schema.type !== 'array') return false;

  const itemsSchema = (schema?.items ?? {}) as AnnotatedJsonSchema;
  const { type, anyOf, allOf, oneOf } = itemsSchema;

  /**
   * type could be `undefined` for combinator schemas
   */

  if (type && type !== 'object') {
    return true;
  }

  if (type === 'object') {
    return isPrimitiveObjectSchema(itemsSchema);
  }

  const combinator = (anyOf ?? allOf ?? oneOf) as AnnotatedJsonSchema[];

  if (combinator) {
    /**
     * if every option in the combinator array is either a primitive or a primitive object return
     * true
     */
    return combinator.every(
      (optionSchema: AnnotatedJsonSchema) =>
        !isArrayOrObjectSchema(optionSchema) ||
        isPrimitiveObjectSchema(optionSchema),
    );
  }

  // I'm not sure what gets us to this condition but it's not good, return false
  return false;
};
