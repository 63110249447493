import { useCallback } from 'react';
import { useIntl } from '@leagueplatform/locales';
import { TranslationKey } from '@web-config-app/core';

/**
 * Hook that returns a convenience function that is useful for translating
 * keys from our custom annotations. Since these keys can be undefined, using the `translate`
 * function from this hook allows for terser code since we avoid having to repeatedly
 * check that a key is defined before passing it to `formatMessage`
 */

export const useTranslate = () => {
  const { formatMessage } = useIntl();

  const translate = useCallback(
    (id?: TranslationKey) => id && formatMessage({ id }),
    [formatMessage],
  );

  return translate;
};
