import * as React from 'react';

export const FolderIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path
      d="m7.69,1.28l2.58,2.43c.79.75,1.83,1.16,2.92,1.16h8.05c1.07,0,1.93.87,1.93,1.93v14.43c0,1.07-.87,1.93-1.93,1.93H2.68c-1.07,0-1.93-.87-1.93-1.93V2.68c0-1.07.87-1.93,1.93-1.93h3.68c.49,0,1.33.53,1.33.53Z"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  </svg>
);
