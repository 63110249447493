import { AppTenantConfig } from '@web-config-app/core';
import { AppTenant } from 'types';
import { leagueB2BConfig } from './league-b2b/league-b2b.config';
import { pcHealthConfig } from './pchealth/pchealth.config';
import { highmarkIELConfig } from './highmark-iel/highmark-iel.config';
import { highmarkBeneficityConfig } from './highmark-beneficity/highmark-beneficity.config';
import { highmarkAMHConfig } from './highmark-allmyhealth/highmark-allmyhealth.config';
import { highmarkEnGenConfig } from './highmark-engen/highmark-engen.config';
import { optumConfig } from './optum/optum.config';
import { bcbsazConfig } from './bcbsaz/bcbsaz.config';
import { manulifeConfig } from './manulife/manulife.config';
import { ovatientMetroHealthConfig } from './ovatient-metrohealth/ovatient-metrohealth.config';
import { xoHealthConfig } from './xohealth/xohealth.config';
import { cignaConfig } from './cigna/cigna.config';
import { premeraConfig } from './premera/premera.config';
import { scanConfig } from './scan/scan.config';
import { scfhpConfig } from './scfhp/scfhp.config';
import { baptistConfig } from './baptist/baptist.config';
import { telusConfig } from './telus/telus.config';
import { referenceConfig } from './reference/reference.config';
import { medibankValenciaConfig } from './medibank-valencia/medibank-valencia.config';

/**
 * @description `tenantSpecificConfigs` is an object containing, per tenant, the `LeagueConfig` values needed when passed to LeagueProvider config prop.
 * To add a new config for a tenant, follow the `AppTenantConfig` type definition from `@web-config-app/core`.
 */

export const tenantSpecificConfigs = {
  [AppTenant.B2B]: leagueB2BConfig,
  [AppTenant.PCHealth]: pcHealthConfig,
  [AppTenant.HighmarkIel]: highmarkIELConfig,
  [AppTenant.HighmarkBeneficity]: highmarkBeneficityConfig,
  [AppTenant.HighmarkAllMyHealth]: highmarkAMHConfig,
  [AppTenant.HighmarkEnGen]: highmarkEnGenConfig,
  [AppTenant.Optum]: optumConfig,
  [AppTenant.BCBSAZ]: bcbsazConfig,
  [AppTenant.Manulife]: manulifeConfig,
  [AppTenant.OvatientMetroHealth]: ovatientMetroHealthConfig,
  [AppTenant.XOHealth]: xoHealthConfig,
  [AppTenant.Cigna]: cignaConfig,
  [AppTenant.Premera]: premeraConfig,
  [AppTenant.SCAN]: scanConfig,
  [AppTenant.SCFHP]: scfhpConfig,
  [AppTenant.Baptist]: baptistConfig,
  [AppTenant.Telus]: telusConfig,
  [AppTenant.Reference]: referenceConfig,
  [AppTenant.MedibankValencia]: medibankValenciaConfig,
} satisfies Record<AppTenant, AppTenantConfig>;

export type TenantId = keyof typeof tenantSpecificConfigs;
