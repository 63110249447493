import { EntityDetailSchema, EntityRootData } from '@web-config-app/core';
import { cloneDeep } from 'lodash-es';
import jp from 'jsonpath';

/**
 * Get any data sources that have been declared in the entity schema's root using
 * the {@link EntityDataSourcesAnnotation} annotation
 */

export const getSchemaDataSources = (
  { 'x-entity-data-sources': dataSources }: EntityDetailSchema,
  data: EntityRootData | undefined,
) => {
  const sources = dataSources?.sources ?? [];
  return sources.map(({ path, name }) => ({
    name,
    /**
     * We use cloneDeep to avoid making the value a direct reference to the actual
     * entity data
     */
    value: cloneDeep(jp.value(data ?? {}, path)),
  }));
};
