import React, { FC } from 'react';
import { ActionMenu } from '@leagueplatform/web-common-components';
import { useIntl } from '@leagueplatform/locales';
import { Button, StackItem, UtilityText } from '@leagueplatform/genesis-core';
import { EntityDetailAction } from '@web-config-app/core';

export interface EntityDetailsRightHeaderActionsProps {
  menuItemGroup: {
    menuItems: {
      id: EntityDetailAction;
      label: React.ReactNode;
    }[];
    onMenuItemClicked: (itemId: EntityDetailAction) => void;
  };
  entityDetailSaveStatus?: JSX.Element;
  secondaryAction?: React.ReactNode | JSX.Element;
  primaryAction?: React.ReactNode | JSX.Element;
}

export const EntityDetailsRightHeaderActions: FC<
  EntityDetailsRightHeaderActionsProps
> = ({
  entityDetailSaveStatus,
  menuItemGroup,
  secondaryAction,
  primaryAction,
}) => {
  const { formatMessage } = useIntl();
  return (
    <StackItem
      css={{
        flexDirection: 'row',
        gap: '$threeQuarters',
        alignItems: 'center',
      }}
    >
      {entityDetailSaveStatus && (
        <UtilityText emphasis="subtle">{entityDetailSaveStatus}</UtilityText>
      )}
      {menuItemGroup.menuItems.length > 0 && (
        <ActionMenu
          css={{
            '.GDS-action-menu-list-item': {
              padding: 0,
            },
            '.GDS-action-menu-list': {
              margin: 0,
              borderRadius: '$huge',
              border: '$borderWidths$thin solid $onSurfaceBorderSubdued',
              'li:first-child > p': {
                'border-top-left-radius': '$huge',
                'border-top-right-radius': '$huge',
              },
              'li:last-child > p': {
                'border-bottom-left-radius': '$huge',
                'border-bottom-right-radius': '$huge',
              },
            },
          }}
          menuButton={
            <Button
              hideLabel
              priority="tertiary"
              size="medium"
              icon="interfaceMeatballs"
            >
              {formatMessage({ id: 'MORE_ACTIONS' })}
            </Button>
          }
          menuId="entityDetailsActionMenu"
          menuItems={menuItemGroup.menuItems}
          onSelectItem={menuItemGroup.onMenuItemClicked}
        />
      )}
      {secondaryAction}
      {primaryAction}
    </StackItem>
  );
};
