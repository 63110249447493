import * as React from 'react';
import {
  GDSStyleObject,
  StackLayout,
  UtilityText,
} from '@leagueplatform/genesis-core';

type NumberIconProps = {
  number: number | string;
  css?: GDSStyleObject;
  ariaHidden?: boolean;
};

export const NumberIcon = ({
  number,
  css,
  ariaHidden = false,
}: NumberIconProps) => (
  <StackLayout
    css={{
      height: '$one',
      width: '$one',
      backgroundColor: '$surfaceBackgroundTertiary',
      borderRadius: '$circle',
      ...css,
    }}
  >
    <UtilityText
      aria-hidden={ariaHidden}
      emphasis="subtle"
      css={{
        margin: 'auto',
        typography: '$label',
      }}
    >
      {number}
    </UtilityText>
  </StackLayout>
);
