import { useEffect } from 'react';
import { StandaloneAuth } from '@leagueplatform/auth-standalone';
import { useLocalStorage } from 'usehooks-ts';
import { LOCAL_STORAGE_TENANT_ID_KEY } from 'constants/locale-storage';
import { LoadingIndicator } from '../../components/loading-indicator.component';

export const AuthViewLogout = () => {
  const [, setTenantId] = useLocalStorage(LOCAL_STORAGE_TENANT_ID_KEY, '');
  useEffect(() => {
    setTenantId('');
    StandaloneAuth.client.logout({ returnTo: window.location.origin });
  }, [setTenantId]);

  return <LoadingIndicator />;
};

export const AuthViewLogin = () => {
  useEffect(() => {
    StandaloneAuth.client.loginWithRedirect();
  }, []);

  return <LoadingIndicator />;
};
