import * as React from 'react';
import { LayoutProps } from '@jsonforms/core';
import { EntityFormLayoutProps } from '../../types/layouts';
import { useEntityFormLayout } from '../../hooks/use-entity-form-layout/use-entity-form-layout';
import { Hidden } from '../hidden/hidden.component';

/*
 * EntityFormLayout encapsulates taking in the JsonForms props, calling useEntityFormLayout to get the correct annotation/props, and passing those to the Layout component. The renderLayout prop lets you completely take over how the form layout is rendered.
 *
 */

export interface EntityFormLayoutOwnProps extends LayoutProps {
  renderLayout: (props: EntityFormLayoutProps) => JSX.Element;
}

export const EntityFormLayout: React.FC<EntityFormLayoutOwnProps> = ({
  renderLayout,
  ...props
}) => {
  const entityFormLayoutProps = useEntityFormLayout(props);
  const { hidden } = entityFormLayoutProps;

  if (hidden) return <Hidden {...entityFormLayoutProps} />;

  return renderLayout(entityFormLayoutProps);
};
