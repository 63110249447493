import { useCallback, useEffect, useState } from 'react';
import {
  DefaultRuleGroupType,
  parseJsonLogic,
  prepareRuleGroup,
} from 'react-querybuilder';

export interface ImportRuleProps {
  onQueryChange(value: any): void;
}

export interface NodeType extends DefaultRuleGroupType {}

export const useImportRule = ({ onQueryChange }: ImportRuleProps) => {
  const [inputValue, setInputValue] = useState('');

  const [error, setError] = useState({
    messageId: '',
    isError: false,
  });

  const [ruleImportOpen, setRuleImportOpen] = useState(false);

  const handleFieldChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const { value } = e.target;
      setInputValue(value);
    },
    [],
  );

  useEffect(() => {
    setError({
      messageId: '',
      isError: false,
    });
  }, [ruleImportOpen, inputValue]);

  const handleImportRule = useCallback(
    (rule: string) => {
      try {
        const json = JSON.parse(rule) && !!rule;
        const q = parseJsonLogic(rule);

        if (q.rules.length > 0 && json) {
          // utility function that applies an id to query and all its rules and subgroups
          const queryWithIds = prepareRuleGroup(q);
          onQueryChange(queryWithIds);
          setError({ messageId: '', isError: false });
          setRuleImportOpen(false);
        } else {
          setError({ messageId: 'VALID_RULE_ERROR', isError: true });
        }
      } catch (err) {
        setError({ messageId: 'VALID_JSON_ERROR', isError: true });
      }
    },
    [onQueryChange],
  );

  return {
    error,
    ruleImportOpen,
    setRuleImportOpen,
    handleImportRule,
    inputValue,
    handleFieldChange,
  };
};
