import type {
  EntityRootData,
  EntityConfigEndpoint,
} from '@web-config-app/core';
import { replaceRequiredValuesVariables } from '../map-required-field-values/map-required-field-values.util';

/**
 * Overrides the entity's attributes.entityMetadata values with ones prescribed by the operation. Ex:
 *
 * For example: the publish operation requires setting attributes.entityMetadata.status to `publish`
 */

export const injectOperationEntityMetadataRequiredFields = (
  data?: EntityRootData,
  operation?: EntityConfigEndpoint,
) => {
  const requiredEntityMetadataFieldValuesWithMapping =
    replaceRequiredValuesVariables(
      operation?.requiredEntityMetadataFieldValues,
    );

  return data
    ? {
        ...data,
        attributes: {
          ...data?.attributes,
          entityMetadata: {
            ...data?.attributes?.entityMetadata,
            ...requiredEntityMetadataFieldValuesWithMapping,
          },
        },
      }
    : {};
};
