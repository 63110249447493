import * as React from 'react';
import {
  SkeletonCircle,
  SkeletonText,
  SkeletonRectangle,
} from '@leagueplatform/genesis-core';
import { TableRow, TableCell } from '../table-pieces.component';

export interface EntityListTableSkeletonRowProps {
  selectable?: boolean;
}

export const EntityListTableSkeletonRow = ({
  selectable = false,
}: EntityListTableSkeletonRowProps) => (
  <TableRow>
    {selectable && (
      <TableCell css={{ paddingY: 19.5 }}>
        <SkeletonCircle size="$oneAndHalf" />
      </TableCell>
    )}
    {/* NAME */}
    <TableCell css={{ paddingY: 19.5 }}>
      <SkeletonText />
    </TableCell>

    {/* ID */}
    <TableCell css={{ paddingY: 19.5 }}>
      <SkeletonText />
    </TableCell>

    {/* DATE */}
    <TableCell css={{ paddingY: 19.5 }}>
      <SkeletonText />
    </TableCell>

    {/* STATUS */}
    <TableCell css={{ paddingY: 19.5 }}>
      <SkeletonRectangle css={{ borderRadius: '$pill', height: 20 }} />
    </TableCell>
  </TableRow>
);
