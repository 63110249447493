import { DataPoint } from '@web-config-app/rule-builder-api';
import { FlatDataPoint, ParentId } from '../types/flat-data-point.types';

export const transformAndFlattenDataPoints = (
  sourceDataPoints: DataPoint[] = [],
): FlatDataPoint[] => {
  if (!sourceDataPoints || sourceDataPoints.length === 0) return [];

  let transformDataPoints: Function;
  const transformedAndFlatDataPoints: FlatDataPoint[] = [];

  const transformDataPoint = (
    dataPoint: DataPoint,
    parentId: ParentId = null,
  ): void => {
    const { dataType, id, label, dataPoints = [] } = dataPoint;
    const transformedDataPoint = {
      dataType,
      id,
      isCategory: dataPoints.length > 0,
      label,
      parentId,
    };

    transformedAndFlatDataPoints.push(transformedDataPoint);
    transformDataPoints(dataPoints, id);
  };

  transformDataPoints = (
    dataPoints: DataPoint[],
    parentId: ParentId = null,
  ): void => {
    dataPoints.map((point) => transformDataPoint(point, parentId));
  };

  transformDataPoints(sourceDataPoints);

  return transformedAndFlatDataPoints;
};
