import { Domain } from '@web-config-app/core';
import { useDomains } from '../use-domains/use-domains';

export const useDomain = (domainId: string): Domain => {
  const domains = useDomains();
  const domain = domains.get(domainId);

  if (!domain) {
    throw new Error(`Unknown domain: ${domainId}`);
  }

  return domain;
};
