import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  StackLayout,
  FormField,
  Select,
  InputStatusMessage,
  Box,
} from '@leagueplatform/genesis-core';
import { OperatorSelectorProps } from 'react-querybuilder';
import { generateFormComponentId } from '../../utils/generate-form-component-id.util';
import {
  ValidatedRule,
  validationFieldTypes,
} from '../../hooks/use-validate-rule-query.hook';

export const OperatorSelector = ({
  context: { invalidRules = [] },
  options,
  handleOnChange,
  title,
  level,
  value,
  path,
  rule,
}: OperatorSelectorProps) => {
  const { formatMessage } = useIntl();
  const handleFieldChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const eventValue = e.target.value;
    handleOnChange(eventValue);
  };
  const uniqueId = generateFormComponentId(title, level, path);
  const validationMsgId = `${uniqueId}-validation-msg`;

  const ruleValidation = invalidRules.find(
    ({ ruleId, fieldType }: ValidatedRule) =>
      ruleId === rule.id && fieldType === validationFieldTypes.operator,
  );

  const selectLabel = formatMessage({ id: 'OPERATOR_INPUT_LABEL' });
  const selectTitle = formatMessage({ id: 'OPERATOR_SELECT_TITLE' });

  const isInvalid = !!ruleValidation;

  return (
    <StackLayout css={{ marginLeft: '$one', maxWidth: '24rem' }}>
      <FormField
        id={uniqueId}
        label={selectLabel}
        inputStatus={isInvalid ? 'error' : undefined}
        css={{
          '.GDS-input-label': {
            typography: '$bodyTwo',
            marginBottom: '$half',
          },
        }}
      >
        <Select
          aria-invalid={isInvalid}
          aria-describedby={validationMsgId}
          key={`${value}-${uniqueId}`}
          onChange={handleFieldChange}
          defaultValue={value}
          title={selectTitle}
          name={uniqueId}
        >
          {options.map((option: { name?: string; label: string }) => {
            const { name, label } = option;
            return (
              <option key={`${name}-${uniqueId}`} value={name}>
                {formatMessage({ id: label })}
              </option>
            );
          })}
        </Select>
      </FormField>
      <Box id={validationMsgId}>
        {ruleValidation ? (
          // TODO: Remove with Genesis fix for react 18
          // @ts-ignore
          <InputStatusMessage id="" inputStatus="error">
            {formatMessage({ id: ruleValidation.fieldMessage })}
          </InputStatusMessage>
        ) : null}
      </Box>
    </StackLayout>
  );
};
