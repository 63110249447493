import * as React from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  Button,
  Modal,
  HeadingText,
  StackLayout,
  StackItem,
  UtilityText,
} from '@leagueplatform/genesis-core';
import { ActionProps, isRuleGroupType } from 'react-querybuilder';

const getGroupOrRuleText = (isGroup: boolean) => (isGroup ? 'GROUP' : 'RULE');

const getButtonLabelKey = (isGroup: boolean) =>
  `REMOVE_${getGroupOrRuleText(isGroup)}`;

const getConfirmHeadingKey = (isGroup: boolean) =>
  `CONFIRM_REMOVE_${getGroupOrRuleText(isGroup)}_HEADING`;

const getConfirmDescriptionKey = (isGroup: boolean) =>
  `CONFIRM_REMOVE_${getGroupOrRuleText(isGroup)}_DESCRIPTION`;

export const RemoveButton = ({ handleOnClick, ruleOrGroup }: ActionProps) => {
  const { formatMessage } = useIntl();

  const isGroup = isRuleGroupType(ruleOrGroup);

  return (
    <Modal.Root>
      <Modal.Trigger>
        <Button
          css={{
            margin: 'auto',
            marginTop: `${!isGroup ? `$oneAndHalf` : `auto`}   `,
          }}
          data-testid="remove-button-trigger"
          hideLabel
          icon="interfaceDelete"
          priority="tertiary"
          type="button"
          quiet
        >
          {formatMessage({ id: getButtonLabelKey(isGroup) })}
        </Button>
      </Modal.Trigger>
      <Modal.Content padding="0 $oneAndHalf $one $oneAndHalf">
        <StackLayout css={{ marginBottom: '$oneAndHalf' }}>
          <Modal.Title>
            <HeadingText level="2" size="md" css={{ marginBottom: '$half' }}>
              {formatMessage({ id: getConfirmHeadingKey(isGroup) })}
            </HeadingText>
          </Modal.Title>
          <Modal.Description>
            <UtilityText data-testid="confirm-description">
              {formatMessage({ id: getConfirmDescriptionKey(isGroup) })}
            </UtilityText>
          </Modal.Description>
        </StackLayout>
        <StackLayout
          orientation="horizontal"
          horizontalAlignment="end"
          spacing="$half"
        >
          <StackItem>
            <Modal.Close>
              <Button
                data-testid="confirm-cancel"
                priority="secondary"
                size="medium"
                type="button"
              >
                {formatMessage({ id: 'CANCEL' })}
              </Button>
            </Modal.Close>
          </StackItem>
          <StackItem>
            <Button
              data-testid="confirm-remove"
              destructive
              onClick={handleOnClick}
              priority="primary"
              size="medium"
              type="button"
            >
              {formatMessage({ id: 'DELETE' })}
            </Button>
          </StackItem>
        </StackLayout>
      </Modal.Content>
    </Modal.Root>
  );
};
