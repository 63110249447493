import * as React from 'react';
import {
  SkeletonRectangle,
  SkeletonText,
  StackLayout,
} from '@leagueplatform/genesis-core';

export const SidePanelSkeleton = () => (
  <StackLayout
    spacing="$oneAndHalf"
    horizontalAlignment="stretch"
    css={{ marginTop: '$oneAndHalf' }}
  >
    <SkeletonText width="13rem" />
    <StackLayout
      spacing="$oneAndHalf"
      css={{ paddingLeft: '$oneAndHalf', width: '15rem' }}
      horizontalAlignment="stretch"
    >
      <SkeletonText lines={3} />
    </StackLayout>
  </StackLayout>
);

const FormFieldSkeleton = () => (
  <StackLayout spacing="$half" css={{ width: '100%' }}>
    <SkeletonText width="11rem" />
    <SkeletonRectangle height="3.5rem" css={{ borderRadius: '$small' }} />
  </StackLayout>
);

export const EntityFormSkeleton = () => (
  <StackLayout spacing="$two" css={{ marginTop: '$one', width: '100%' }}>
    <SkeletonText header width="15rem" css={{ marginBottom: '$two' }} />
    <FormFieldSkeleton />
    <FormFieldSkeleton />
    <FormFieldSkeleton />
  </StackLayout>
);
