import { FlatDataPoint, ParentId } from '../types/flat-data-point.types';

export const getCategoryById = (
  categoryId: ParentId,
  dataPoints: FlatDataPoint[],
): FlatDataPoint | undefined => dataPoints.find(({ id }) => id === categoryId);

export const getDataPointsInCategoryById = (
  categoryId: ParentId,
  dataPoints: FlatDataPoint[],
): FlatDataPoint[] =>
  dataPoints.filter(({ parentId }) => parentId === categoryId);

export const getCurrentCategoryBreadcrumbs = (
  currentCategory: FlatDataPoint | undefined,
  dataPoints: FlatDataPoint[],
): FlatDataPoint[] => {
  if (!currentCategory) return [];

  const breadcrumbs: FlatDataPoint[] = [];
  const cycleParentCategories = (category: FlatDataPoint | undefined) => {
    if (category?.parentId) {
      const parentCategory = getCategoryById(category?.parentId, dataPoints);
      if (parentCategory) {
        breadcrumbs.push(parentCategory);
        cycleParentCategories(parentCategory);
      }
    }
  };

  cycleParentCategories(currentCategory);

  return [
    currentCategory,
    ...breadcrumbs,
    {
      id: null,
      isCategory: true,
      label: 'category',
      parentId: null,
    },
  ].reverse();
};
