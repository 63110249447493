import * as React from 'react';

export const ChevronRightFilledIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="6"
    height="10"
    viewBox="0 0 6 10"
    fill="none"
  >
    <path d="M0.75 0.5L0.75 9.5L5.25 5L0.75 0.5Z" fill="currentColor" />
  </svg>
);
