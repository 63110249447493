import { Level } from '@tiptap/extension-heading';
import { Editor } from '@tiptap/react';
import {
  BlockElementType,
  BlockFormatter,
  FormatBlockElementProps,
} from '../rich-text-editor.types';

export const formatParagraph = ({ editor }: { editor: Editor }) => {
  editor.chain().focus().setParagraph().run();
};

export const formatHeading = ({
  editor,
  headingLevel,
}: {
  editor: Editor;
  headingLevel: Level;
}) => {
  editor.chain().focus().toggleHeading({ level: headingLevel }).run();
};

export const formatBulletList = ({ editor }: { editor: Editor | null }) => {
  // there is a weird bug with tip tap and the formatting and  nesting of lists and block quotes so we are only allowing one of these block element to exist (no nesting of these)
  if (editor?.isActive('blockquote')) {
    editor?.chain().focus().toggleBlockquote().run();
  }
  editor?.chain().focus().toggleBulletList().run();
};

export const formatOrderedList = ({ editor }: { editor: Editor | null }) => {
  // there is a weird bug with tip tap and the formatting and  nesting  of lists and block quotes so we are only allowing one of these block element to exist
  if (editor?.isActive('blockquote')) {
    editor?.chain().focus().toggleBlockquote().run();
  }
  editor?.chain().focus().toggleOrderedList().run();
};

export const formatBlockQuote = ({ editor }: { editor: Editor | null }) => {
  // there is a weird bug with tip tap and the formatting and  nesting  of lists and block quotes so we are only allowing one of these block element to exist
  if (editor?.isActive('orderedList')) {
    editor?.chain().focus().toggleOrderedList().run();
  }
  if (editor?.isActive('bulletList')) {
    editor?.chain().focus().toggleBulletList().run();
  }
  editor?.chain().focus().toggleBlockquote().run();
};

export const blockElementFormatMap: Record<BlockElementType, BlockFormatter> = {
  paragraph: formatParagraph,
  bulletList: formatBulletList,
  orderedList: formatOrderedList,
  blockquote: formatBlockQuote,
  h1: (props) => formatHeading({ ...props, headingLevel: 1 }),
  h2: (props) => formatHeading({ ...props, headingLevel: 2 }),
  h3: (props) => formatHeading({ ...props, headingLevel: 3 }),
  h4: (props) => formatHeading({ ...props, headingLevel: 4 }),
  h5: (props) => formatHeading({ ...props, headingLevel: 5 }),
  h6: (props) => formatHeading({ ...props, headingLevel: 6 }),
};

export const formatBlockElement = ({
  newBlockElement,
  editor,
}: FormatBlockElementProps) => {
  if (!editor) return;
  const formatter = blockElementFormatMap[newBlockElement];
  formatter({ editor });
};

export const blockElementsWithoutHeadings: BlockElementType[] = [
  'bulletList',
  'orderedList',
  'blockquote',
  'paragraph',
];

export const getActiveBlockType = (editor: Editor | null): BlockElementType => {
  if (editor?.isActive('heading')) {
    // only have 6 heading levels
    for (let i = 1; i <= 6; i += 1) {
      if (editor?.isActive('heading', { level: i })) {
        return `h${i}` as BlockElementType;
      }
    }
  }

  const activeType = blockElementsWithoutHeadings.find((type) =>
    editor?.isActive(type),
  ) as BlockElementType;
  return activeType;
};
