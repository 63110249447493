import * as React from 'react';
import {
  Button,
  HeadingText,
  Modal,
  ParagraphText,
  StackLayout,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { Link } from '@leagueplatform/routing';

// modal width according to designs
const MODAL_CONTENT_WIDTH = 668;

export interface EntityDetailsConfirmationModalProps {
  modalTrigger: React.ReactNode;
  primaryButtonText: string;
  primaryButtonPath: string;
  showTertiaryButton?: boolean;
  title: string;
  description: string;
}

export const EntityDetailsConfirmationModal: React.FC<
  EntityDetailsConfirmationModalProps
> = ({
  modalTrigger,
  primaryButtonText,
  primaryButtonPath,
  showTertiaryButton = true,
  title,
  description,
}) => {
  const { formatMessage } = useIntl();

  const [modalOpen, setModalOpen] = React.useState(false);

  const onModalOpenChange = React.useCallback(() => {
    setModalOpen((previous) => !previous);
  }, []);
  return (
    <Modal.Root open={modalOpen} onOpenChange={onModalOpenChange}>
      <Modal.Trigger>{modalTrigger}</Modal.Trigger>
      <Modal.Content width={MODAL_CONTENT_WIDTH}>
        <StackLayout spacing="$one" horizontalAlignment="stretch">
          <Modal.Title>
            <HeadingText size="xl" level="2">
              {title}
            </HeadingText>
          </Modal.Title>
          <Modal.Description>
            <ParagraphText>{description}</ParagraphText>
          </Modal.Description>
          <StackLayout
            orientation="horizontal"
            horizontalAlignment="end"
            spacing="$one"
          >
            {showTertiaryButton && (
              <Modal.Close>
                <Button priority="tertiary" quiet>
                  {formatMessage({ id: 'CANCEL' })}
                </Button>
              </Modal.Close>
            )}
            <Button destructive as={Link} to={primaryButtonPath}>
              {primaryButtonText}
            </Button>
          </StackLayout>
        </StackLayout>
      </Modal.Content>
    </Modal.Root>
  );
};
