import { create } from 'zustand';
import { Nullable } from '@web-config-app/core';

export interface EntityTreeState {
  /**
   * An array of strings corresponding to tree node IDs
   */
  expandedNodes: string[];
  /**
   * Add or remove the value for nodeId from `expandedNodes`. Optionally
   * pass `expanded` as true to override closing the node.
   */
  toggleNode: (nodeId: string, expanded?: boolean) => void;
  /**
   * Track the last manually toggled node. This is required due to needing to
   * auto-expand any a node and its parent via either deep-link or object
   * card navigation
   */
  lastToggledNode: Nullable<string>;
  /**
   * Check the expanded state of a node
   */
  isExpanded: (nodeId: string) => boolean;
  /**
   * Provides the ability to expand a nested node AND all of its parent
   * nodes. This is useful for cases where we are opening a deeply nested
   * tree object via the URL, ex: `path?=contentData.pages.0.something`
   */
  expandParentNodesForPath: (nodeId: string) => void;
  /**
   * Used to reset the expanded nodes. If no nodes array is passed it will
   * default to using `initialExpandedNodes`, equal to [`root`]
   */
  resetNodes: (nodes?: string[]) => void;
}

const isInitialExpandedNodes = (nodes: string[]) => nodes.length === 0;

export const useEntityTreeState = create<EntityTreeState>((set, get) => ({
  lastToggledNode: null,
  expandedNodes: [],
  toggleNode: (nodeId: string) => {
    const { expandedNodes } = get();
    const lastToggledNode = nodeId;
    const updatedExpandedNodes = expandedNodes.includes(nodeId)
      ? expandedNodes.filter(
          (id: string) => id !== nodeId && id.indexOf(nodeId) !== 0,
        )
      : [...expandedNodes, nodeId];
    return set({ lastToggledNode, expandedNodes: updatedExpandedNodes });
  },
  isExpanded: (nodeId: string) => {
    const { expandedNodes } = get();
    return expandedNodes.includes(nodeId);
  },
  expandParentNodesForPath: (nodeId: string) => {
    const { isExpanded, expandedNodes, lastToggledNode } = get();
    const newExpandedNodes: string[] = [];
    /**
     * Checking that a) the node at this path is NOT expanded yet and b) that
     * it wasn't the last toggled node. The reason for this check is that a user is allowed
     * to intentionally toggle a node closed, in which case it would be not expanded AND
     * the last toggled node.
     */
    if (
      (!isExpanded(nodeId) && nodeId !== lastToggledNode) ||
      isInitialExpandedNodes(expandedNodes)
    ) {
      let currentNodePath: string;
      nodeId.split('.').forEach((p: string) => {
        /**
         * Expand every node as the subpath, starting with the top-most
         * parent and iterating over each child.
         *
         * Ex: for the path `contentData.pages.0`, we split into an array
         * [`contentData`, `pages`, `0`] and then expand, in order:
         *
         * 1. `contentData`
         * 2. `contentData.pages`
         * 3. `contentData.pages.0`
         *
         */
        currentNodePath = currentNodePath ? `${currentNodePath}.${p}` : p;
        if (!isExpanded(currentNodePath)) {
          newExpandedNodes.push(currentNodePath);
        }
      });
    }

    return set({
      expandedNodes: [...expandedNodes, ...newExpandedNodes],
      lastToggledNode: nodeId,
    });
  },
  resetNodes: (nodes?: string[]) =>
    set({
      expandedNodes: nodes ?? [],
      lastToggledNode: null,
    }),
}));
