import * as React from 'react';
import {
  EntityListTableSkeletonRow,
  EntityListTableSkeletonRowProps,
} from './entity-list-table-skeleton-row.component';

export const EntityListTableSkeleton = ({
  selectable,
}: EntityListTableSkeletonRowProps) => (
  <tbody data-testid="entity-list-table-skeleton">
    {Array(10)
      .fill(true)
      .map((_, index) => (
        <EntityListTableSkeletonRow
          data-testid="entity-list-skeleton"
          // eslint-disable-next-line react/no-array-index-key
          key={`skeleton-row-${index}`}
          selectable={selectable}
        />
      ))}
  </tbody>
);
