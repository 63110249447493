import { Format } from 'ajv';
import { FormatType } from '../../types';

const richTextFormat: Format = {
  type: 'string',
  // TODO - Make this function actually do stuff in https://everlong.atlassian.net/browse/CACT-641
  validate: (value: string) => value.length > 1,
};

export const richTextFormatEntry = {
  name: FormatType.RichText,
  format: richTextFormat,
};
