import { GDSInputStatus } from '@leagueplatform/genesis-core';
import { useState, useEffect, useCallback } from 'react';
import { RuleGroupType, formatQuery } from 'react-querybuilder';

export interface CopyStatusMsgTypes {
  status: GDSInputStatus;
  msg: string;
}

export const useGenerateJsonLogic = () => {
  const [copyStatusMsg, setCopyStatusMsg] = useState<CopyStatusMsgTypes | null>(
    null,
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [jsonLogicString, setJsonLogicString] = useState('');

  useEffect(() => {
    setCopyStatusMsg(null);
  }, [jsonLogicString, modalOpen]);

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(jsonLogicString);
      setCopyStatusMsg({ status: 'success', msg: 'JSON_COPY_SUCCESS' });
    } catch (error) {
      setCopyStatusMsg({ status: 'error', msg: 'JSON_COPY_ERROR' });
    }
  };

  // TODO: make not any
  const handleUpdateJson = useCallback((query: any) => {
    const formattedJSON = formatQuery(query, 'jsonlogic');
    const jsonString = formattedJSON ? JSON.stringify(formattedJSON) : '';
    setJsonLogicString(jsonString);
  }, []);

  const handleFormSubmit = useCallback(
    (query: RuleGroupType) => {
      handleUpdateJson(query);
      setModalOpen(true);
    },
    [handleUpdateJson],
  );

  const onModalOpenChange = useCallback(() => {
    setModalOpen((previous) => !previous);
  }, []);

  return {
    jsonLogicString,
    handleUpdateJson,
    handleFormSubmit,
    copyToClipboard,
    copyStatusMsg,
    modalOpen,
    onModalOpenChange,
  };
};
