import { createAjv } from './create-ajv';
import { customFormats } from './formats';
import { customKeywords } from './keywords';

/**
 * AJV recommends using a single instance shared across the entire application and its modules
 * read more: https://ajv.js.org/guide/managing-schemas.html#managing-schemas
 *
 * The instance exported here has been pre-wrapped in two ajv extension modules,
 *   1. avj-errors - adds an errorMessage keyword
 *   2. avj-formats - adds several new formats
 *
 * more details on the above are available in the `createAjv` documentation.
 *
 * It also contains custom formats and keywords created for the Config App
 */

export const ajv = createAjv({
  formats: customFormats,
  keywords: customKeywords,
});
