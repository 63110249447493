import * as React from 'react';
import { format, formatISO, isValid } from 'date-fns';
import { UTCDate } from '@date-fns/utc';
import { TextInput } from '@leagueplatform/genesis-core';
import { ControlComponent } from '../../../types/controls';
import { EntityFormControl } from '../../entity-form-control/entity-form-control.component';

/**
 * FUN FACT: the format returned from an input of type `datetime-local`, 2000-01-01T12:12, is NOT the same
 * as the [ISO 86010]{@link https://www.iso.org/iso-8601-date-and-time-format.html}, 2000-01-01T12:12:12+00:00
 *
 * This means that we need to handle the value coming out of the input on change as well as coming into the control
 * via the `data` prop.
 */
export const getDatetimeLocalFormatFromISO = (value: string) => {
  if (!value) {
    return value;
  }

  const utcDate = new UTCDate(value);
  const localDate = new Date(
    utcDate.getFullYear(),
    utcDate.getMonth(),
    utcDate.getDate(),
    utcDate.getHours(),
    utcDate.getMinutes(),
  );

  const formatted = format(localDate, "yyyy-MM-dd'T'HH:mm");
  return formatted;
};

export const getUTCDateFromDatetimeLocalFormat = (value: string) => {
  const date = new Date(value);
  const utcDate = new UTCDate(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
  );

  return utcDate;
};

export const DateTimeInputControl: ControlComponent = (props) => (
  <EntityFormControl
    {...props}
    renderControl={({ data, handleChange, path, inputStatus, enabled }) => (
      <TextInput
        type="datetime-local"
        value={getDatetimeLocalFormatFromISO(data ?? '')}
        readOnly={!enabled}
        clearable={enabled && data && data.length > 0}
        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
          const utcDate = getUTCDateFromDatetimeLocalFormat(
            evt.currentTarget.value,
          );
          if (isValid(utcDate)) {
            const formattedDate = formatISO(utcDate);
            handleChange(path, formattedDate);
          }
        }}
        onClear={() => handleChange(path, undefined)}
        inputStatus={inputStatus}
        css={{
          backgroundColor: '$inputBackgroundDefault',
        }}
      />
    )}
  />
);
