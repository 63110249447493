import React, { FC } from 'react';
import {
  HeadingText,
  StatusBadge,
  GDSStatus,
  StackLayout,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { ValueWithCopyButton } from '../value-with-copy-button/value-with-copy-button.component';

interface EntityNameAndStatusProps {
  entityInstanceName?: string;
  statusBadgeLabel?: string;
  statusBadgeStatus?: GDSStatus;
  instanceId?: string;
}

export const EntityNameAndStatus: FC<EntityNameAndStatusProps> = ({
  entityInstanceName,
  statusBadgeLabel,
  statusBadgeStatus,
  instanceId,
}) => {
  const { formatMessage } = useIntl();
  return (
    <StackLayout spacing="$none">
      <HeadingText
        level="1"
        size="lg"
        css={{
          display: 'block',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          maxWidth: '30vw',
          '@tablet': {
            maxWidth: '40vw',
          },
          '@laptop': {
            maxWidth: '45vw',
          },
          '@desktop': {
            maxWidth: '60vw',
          },
        }}
      >
        {entityInstanceName}
      </HeadingText>
      <StackLayout
        orientation="horizontal"
        spacing="$half"
        verticalAlignment="center"
      >
        {instanceId && (
          <ValueWithCopyButton
            value={instanceId}
            buttonLabel={formatMessage({ id: 'COPY_ENTITY_ID' })}
          />
        )}
        {statusBadgeLabel && statusBadgeStatus && (
          <StatusBadge label={statusBadgeLabel} status={statusBadgeStatus} />
        )}
      </StackLayout>
    </StackLayout>
  );
};
