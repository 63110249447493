import * as React from 'react';
import { Box } from '@leagueplatform/genesis-core';
import {
  EntityFormControlProps,
  EntityFormArrayControlProps,
} from '../../types/controls';
import { EntityFormLayoutProps } from '../../types/layouts';

/**
 * TODO :: return null or hide and set values if const is in schema https://everlong.atlassian.net/browse/CACT-721
 */

type HiddenProps =
  | EntityFormControlProps
  | EntityFormLayoutProps
  | EntityFormArrayControlProps;

export const Hidden: React.FC<HiddenProps> = () => <Box data-testid="hidden" />;
