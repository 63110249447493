import type { EntityFormControlAnnotationProps } from '../../types/controls';
import { createDefaultValueForSchema } from '../create-default-value-for-schema/create-default-value-for-schema';

export const addCombinatorOptionToArray = ({
  combinatorProperties,
  value,
  path,
  addItem,
}: {
  value: string;
  combinatorProperties?: EntityFormControlAnnotationProps['combinatorProperties'];
  path: string;
  addItem(path: string, data: any): () => void;
}) => {
  const selectedSubSchema =
    combinatorProperties?.combinatorSubSchemaMap?.[value];

  if (selectedSubSchema) {
    const defaultValue = createDefaultValueForSchema(selectedSubSchema);
    addItem(path, defaultValue)();
  }
};
