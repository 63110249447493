import * as React from 'react';
import {
  StackLayout,
  SkeletonRectangle,
  SkeletonText,
  VisuallyHidden,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';

export function SelectedImageLoadingSkeleton() {
  const { formatMessage } = useIntl();
  return (
    <StackLayout
      orientation="horizontal"
      verticalAlignment="center"
      horizontalAlignment="spaceBetween"
    >
      <StackLayout
        verticalAlignment="center"
        orientation="horizontal"
        spacing="$half"
      >
        <SkeletonRectangle aspectRatio="square" width="2.5rem" />
        <SkeletonText width="160px" />
      </StackLayout>
      <StackLayout
        verticalAlignment="center"
        orientation="horizontal"
        spacing="$oneAndHalf"
        horizontalAlignment="end"
        css={{ '& > *': { flexGrow: 0 } }}
      >
        <SkeletonText width="120px" alignment="right" />
        <SkeletonRectangle aspectRatio="square" width="1.5rem" />
      </StackLayout>
      <VisuallyHidden>{formatMessage({ id: 'LOADING' })}</VisuallyHidden>
    </StackLayout>
  );
}
