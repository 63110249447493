import * as React from 'react';
import { Redirect, Route, Switch } from '@leagueplatform/routing';
import { CreateRulePage } from '@web-config-app/rule-builder';
import { AuthViewLogin, AuthViewLogout } from 'pages/auth/Auth.pages';
import { ErrorPage } from '@web-config-app/core-react-ui';
import { useAppRedirectPaths } from '@web-config-app/core-react';
import { BasePage, DomainPage } from '@web-config-app/core-react-containers';
import { EntityListPage } from '../pages/entity-list-page/entity-list-page.component';
import { EntityDetails } from '../pages/entity-details/entity-details.page';
import { AuthorizedRoutes } from './authorized-routes/authorized-routes.component';

function AppRoutes() {
  const { landingRedirectPath } = useAppRedirectPaths();
  return (
    <Switch>
      <Route path="/sign-in" exact component={AuthViewLogin} />
      <Route path="/sign-out" exact component={AuthViewLogout} />
      <AuthorizedRoutes>
        <Switch>
          <Route path="/" exact>
            <Redirect to={landingRedirectPath()} />
          </Route>
          <Route path="/domains/:domainId" exact>
            <DomainPage />
          </Route>
          <Route path="/domains/:domainId/:entityId" exact>
            <BasePage>
              <EntityListPage />
            </BasePage>
          </Route>
          <Route path="/domains/:domainId/:entityId/create" exact>
            <EntityDetails />
          </Route>
          <Route path="/domains/:domainId/:entityId/:entityInstanceId" exact>
            <EntityDetails />
          </Route>
          <Route path="/rule-builder" exact>
            <CreateRulePage />
          </Route>
          <Route component={ErrorPage} />
        </Switch>
      </AuthorizedRoutes>
    </Switch>
  );
}

export default AppRoutes;
