import type {
  AnnotatedJsonSchema,
  EntityDetailSchemaWithRelationships,
} from '@web-config-app/core';

export const getRelationshipDataSchema = (
  relationship: string,
  schema: EntityDetailSchemaWithRelationships,
) =>
  schema.properties?.relationships?.properties?.[relationship]?.properties
    ?.data as AnnotatedJsonSchema;
