import * as React from 'react';
import { JsonFormsDispatch } from '@jsonforms/react';
import { StackLayout } from '@leagueplatform/genesis-core';
import type { AnnotatedJsonSchema } from '@web-config-app/core';
import {
  ControlComponent,
  EntityFormControlProps,
} from '../../../types/controls';
import { useEntityFormControl } from '../../../hooks/use-entity-form-control/use-entity-form-control';
import { useParentSchema } from '../../../hooks/use-parent-schema/use-parent-schema';
import {
  useAnnotatedSchemaProps,
  AnnotatedSchemaProps,
} from '../../../hooks/use-annotated-schema-props/use-annotated-schema-props';
import { useCombinatorSchemas } from '../../../hooks/use-combinator-schemas/use-combinator-schemas';

type CombinatorProperties = AnnotatedSchemaProps['combinatorProperties'];

const useParentSchemaCombinatorProperties = () => {
  const [combinatorProps, setCombinatorProps] =
    React.useState<CombinatorProperties>();
  const { parentSchema } = useParentSchema();

  const { combinatorProperties } = useAnnotatedSchemaProps(
    parentSchema as AnnotatedJsonSchema,
  );

  if (combinatorProperties && !combinatorProps) {
    setCombinatorProps(combinatorProperties);
  }

  return { combinatorProperties: combinatorProps ?? combinatorProperties };
};

/**
 *
 * This component renders for schemas with ``anyOf` or `oneOf` where the the value for the
 * `discriminator`is pre-defined in the data for the property and the user does not have
 * the option to select a different value.
 *
 */

export const ConstantDiscriminatorCombinatorControlContents = ({
  path,
  rootSchema,
  data,
  visible,
  enabled,
  renderers,
  cells,
}: EntityFormControlProps) => {
  const { combinatorProperties } = useParentSchemaCombinatorProperties();

  const { combinatorDiscriminator } = combinatorProperties ?? {
    combinatorDiscriminator: '',
  };

  const { selectedSubSchema, detailUiSchema } = useCombinatorSchemas({
    combinatorProperties,
    rootSchema,
    data,
  });

  if (!selectedSubSchema) {
    // eslint-disable-next-line
    console.error(
      `Unable to find a schema matching discriminator ${combinatorDiscriminator} using discriminator value: ${
        (data ?? {})[combinatorDiscriminator]
      }`,
    );
  }

  if (!combinatorProperties) {
    // eslint-disable-next-line
    console.error(
      'Combinator properties are missing but required for the combinator',
    );
  }

  return combinatorProperties ? (
    <StackLayout spacing="$half" horizontalAlignment="stretch">
      {selectedSubSchema && (
        <JsonFormsDispatch
          visible={visible}
          enabled={enabled}
          schema={selectedSubSchema}
          uischema={detailUiSchema}
          path={path}
          renderers={renderers}
          cells={cells}
        />
      )}
    </StackLayout>
  ) : null;
};

export const ConstantDiscriminatorCombinatorControl: ControlComponent = (
  props,
) => {
  const entityFormControlProps = useEntityFormControl(props);

  return (
    <ConstantDiscriminatorCombinatorControlContents
      {...entityFormControlProps}
    />
  );
};
