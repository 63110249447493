// Uses the title: title of component, level (nested level of component), and path (array of integers unique to each rule and group that corresponds to their position within the rules array(s) of their ancestor group(s) within a query) that is passed to custom react query builder controlElements to generate a unique for component id that is used to bind form components together (labels to their inputs or fieldsets to their hints etc )

import { generateIdString } from './generate-id-string.util';

export const generateFormComponentId = (
  title: string | undefined,
  level: number,
  path: number[],
): string => {
  // Title should always be passed, but react query builder has it typed as | undefined. Using generate-id-string.test.ts to generate unique component title as backup
  const componentTitle = title ? `${title}-` : generateIdString(4);
  const uniqueId = `${componentTitle}${level}${
    path.length ? `-${path.join('-')}` : ''
  }`;

  return uniqueId;
};
