/* eslint-disable import/no-extraneous-dependencies */
import * as React from 'react';
import {
  StackLayout,
  Button,
  Modal,
  HeadingText,
  FormField,
  TextInput,
  InputStatusMessage,
  Box,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { Nullable } from '@web-config-app/core';
import { AddItemModalProps } from '../../types/components';

export const AddItemByIdModal: React.FC<AddItemModalProps> = ({
  onSubmit,
  onCancel,
  open,
  headingLabel,
  errorMessage,
  idValue,
  setIdValue,
  fieldLabel,
  isLoadingSubmission,
  inputHint,
}) => {
  const { formatMessage } = useIntl();
  const inputRef = React.useRef<Nullable<HTMLInputElement>>(null);

  React.useEffect(() => {
    const focusInputTimeout = setTimeout(() => {
      if (inputRef.current && open) {
        inputRef.current.focus();
      }
    }, 0);
    return () => clearTimeout(focusInputTimeout);
  }, [open]);

  return (
    <Modal.Root onOpenChange={(isOpen) => !isOpen && onCancel()} open={open}>
      <Modal.Content showCloseButton={false}>
        <Modal.Title>
          <HeadingText level="2" size="lg">
            {headingLabel}
          </HeadingText>
        </Modal.Title>
        <FormField
          label={fieldLabel}
          id="item_id"
          css={{ marginY: '$one' }}
          hint={inputHint}
        >
          <TextInput
            ref={inputRef}
            onChange={(evt) => setIdValue(evt.currentTarget.value)}
            value={idValue}
            disabled={isLoadingSubmission}
          />
        </FormField>
        <Box id="add-by-id-error" role="region" aria-live="polite">
          {errorMessage && (
            <InputStatusMessage inputStatus="error">
              {errorMessage}
            </InputStatusMessage>
          )}
        </Box>

        <StackLayout
          orientation="horizontal"
          horizontalAlignment="end"
          spacing="$half"
          css={{ marginTop: '$one' }}
        >
          <Modal.Close>
            <Button
              priority="tertiary"
              quiet
              size="medium"
              type="button"
              onClick={() => onCancel()}
              disabled={isLoadingSubmission}
            >
              {formatMessage({ id: 'CANCEL' })}
            </Button>
          </Modal.Close>
          <Button
            size="medium"
            priority="primary"
            onClick={onSubmit}
            loading={isLoadingSubmission}
          >
            {formatMessage({ id: 'SUBMIT' })}
          </Button>
        </StackLayout>
      </Modal.Content>
    </Modal.Root>
  );
};
