import {
  GDSOnSurfaceTokens,
  GDSSurfaceTokens,
} from '@leagueplatform/genesis-core';

// Surface colours affect surfaces of components, such as pages, cards, sheets, and popovers.
const surface: GDSSurfaceTokens = {
  background: {
    primary: '#FFFFFF',
    secondary: '#F8F8F8',
    tertiary: '#EAEEFB',
  },
  card: {
    primary: '#F8F7F6',
    secondary: '#FFFFFF',
    disabled: '#D7D7D7',
  },
};

// On-surface colours are applied to elements that appear on neutral surfaces, typically text, borders, and secondary icons.
const onSurface: GDSOnSurfaceTokens = {
  text: {
    primary: '#250D53',
    subdued: '#66686B',
    reversed: '#FFFFFF',
    critical: '#BA1B3B',
    success: '#00805A',
    warning: '#222222',
  },
  border: {
    default: '#66686B',
    subdued: '#D6D6D6',
  },
  icon: {
    primary: '#66686B',
  },
};

export const surfaceTokens = {
  surface,
  onSurface,
};
