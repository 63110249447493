import * as React from 'react';
import {
  StackLayout,
  Button,
  StackItem,
  ParagraphText,
} from '@leagueplatform/genesis-core';
import { RuleGroupProps, useRuleGroup } from 'react-querybuilder';
import { useIntl } from '@leagueplatform/locales';
import { RemoveButton } from '../remove-button/remove-button.component';
import { CombinatorSelector } from '../combinator-selector/combinator-selector.component';
import { useGenerateGroupLevels } from '../../hooks/use-generate-group-levels.hook';

export interface RuleGroupHeaderProps extends RuleGroupProps {
  rootHeader: boolean;
}

const groupHeaderCss = {
  backgroundColor: '$surfaceBackgroundSecondary',
  padding: '$half $threeQuarters $half $oneAndHalf',
  borderTopLeftRadius: '$extraLarge',
  borderTopRightRadius: '$extraLarge',
};

export const RuleGroupHeader = (
  ruleGroupProps: RuleGroupHeaderProps & ReturnType<typeof useRuleGroup>,
) => {
  const { formatMessage } = useIntl();

  const {
    ruleGroup,
    schema,
    path,
    context,
    combinator,
    onCombinatorChange,
    addGroup,
    addRule,
    removeGroup,
    rootHeader,
    translations,
  } = ruleGroupProps;

  const { findGroupLevelByRuleId } = useGenerateGroupLevels();

  const groupLevel = !rootHeader
    ? findGroupLevelByRuleId(context.filteredGroups, ruleGroup.id)
    : undefined;

  // TODO: FIX ts-ignore
  // @ts-ignore
  const groupTitle = formatMessage({ id: 'FRE_GROUP_HEADER' }, { groupLevel });

  const headerStyles = !rootHeader ? groupHeaderCss : {};

  return (
    <StackLayout
      data-testid="rule-group-header"
      orientation="horizontal"
      horizontalAlignment="center"
      verticalAlignment="center"
      css={{ ...headerStyles, marginBottom: '$oneAndHalf' }}
    >
      <CombinatorSelector
        options={schema.combinators}
        value={combinator}
        title={translations.combinators.title}
        handleOnChange={onCombinatorChange}
        level={path.length}
        path={path}
        context={context}
        schema={schema}
      />
      {!rootHeader && (
        <ParagraphText
          data-testid="group-title"
          css={{ typography: '$subtitleOne', textWrap: 'nowrap' }}
        >
          {groupTitle}
        </ParagraphText>
      )}
      <StackItem
        verticalAlignment="bottom"
        css={{
          flexDirection: 'row',
          width: '100%',
        }}
      >
        <Button
          onClick={addRule}
          data-testid="add-rule"
          size="small"
          priority="secondary"
          css={{ marginRight: '$threeQuarters', flexGrow: '0' }}
        >
          {formatMessage({ id: 'ADD_RULE_BUTTON_LABEL' })}
        </Button>
        <Button
          data-testid="add-group"
          onClick={addGroup}
          size="small"
          priority="secondary"
          css={{ flexGrow: '0' }}
        >
          {formatMessage({ id: 'ADD_GROUP_BUTTON_LABEL' })}
        </Button>
      </StackItem>
      {!rootHeader && (
        <RemoveButton
          handleOnClick={removeGroup}
          ruleOrGroup={ruleGroup}
          path={path}
          level={path.length}
          schema={schema}
        />
      )}
    </StackLayout>
  );
};
