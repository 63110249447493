import * as React from 'react';
import { StackLayout } from '@leagueplatform/genesis-core';

export interface RuleFormStylesProps {
  children?: React.ReactNode;
}

const formStyles = {
  width: '100%',
  padding: '$twoAndHalf $three',
  '.queryBuilder': {
    width: '100%',
  },
  // targets all rules besides rules at the 'root'
  '.rule-group-container .rule-group-container .rule': {
    paddingLeft: '$three',
    paddingRight: '$threeQuarters',
  },
  // targets >= 3 nested groups
  '.rule-group-container .rule-group-container .rule-group-container': {
    marginLeft: '$three',
    marginRight: '$three',
  },
  '.rule-group-container:last-of-type': {
    marginBottom: '$twoAndHalf',
  },
  // removing the valueSource selector (choosing between field and value, because we want this to happen automatically)
  '.rule-valueSource': {
    display: 'none',
  },
};

export const RuleFormStyles = ({ children }: RuleFormStylesProps) => {
  if (!children) return null;

  return <StackLayout css={formStyles}>{children}</StackLayout>;
};
