import * as React from 'react';
import { TextAction } from '@leagueplatform/genesis-core';
import { NavLink, useLocation } from '@leagueplatform/routing';
import { UseTreeItem2Parameters } from '@mui/x-tree-view/useTreeItem2';
import { TreeItem2 } from '@mui/x-tree-view/TreeItem2';
import { TreeNodeType } from '../../../types/tree';

/**
 * See: customizing TreeItem using TreeItem2
 * https://mui.com/x/api/tree-view/tree-item-2/
 * https://mui.com/x/react-tree-view/simple-tree-view/customization/#custom-label
 */

interface EntityTreeItemProps
  extends Omit<UseTreeItem2Parameters, 'rootRef'>,
    Omit<React.HTMLAttributes<HTMLLIElement>, 'onFocus'> {
  onClick?: (args: any) => void;
  path: string;
  type: TreeNodeType | undefined;
  index?: number;
}

type EntityTreeItemLabelProps = {
  to: string;
  onClick?: (args: any) => void;
  label: string;
  type: TreeNodeType | undefined;
};

const EntityTreeItemLabel = ({
  to,
  onClick,
  label,
  type,
}: EntityTreeItemLabelProps) => (
  <TextAction
    as={NavLink}
    to={to}
    css={{
      flexGrow: 1,
      display: 'flex',
      typography: '$subtitleOne',
      color: '$onSurfaceTextPrimary',
      '&.CACT-tree-item-arrayItem:before': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        typography: '$label',
        height: '$one',
        width: '$one',
        marginRight: '$half',
        backgroundColor: '$surfaceBackgroundTertiary',
        color: '$onSurfaceTextSubdued',
        borderRadius: '$circle',
        counterIncrement: 'array-item-count',
        content: 'counter(array-item-count)',
      },
    }}
    className={`CACT-tree-item-${type}`}
    onClick={onClick}
  >
    {label}
  </TextAction>
);

export const EntityTreeItem = React.forwardRef(
  (props: EntityTreeItemProps, ref: React.Ref<HTMLLIElement>) => {
    const { path, onClick, label, type, index } = props;
    const { pathname } = useLocation();

    return (
      <TreeItem2
        {...props}
        ref={ref}
        slots={{
          label: EntityTreeItemLabel,
        }}
        slotProps={{
          label: {
            label,
            to: `${pathname}?path=${path}`,
            onClick,
            type,
            index,
          } as any,
        }}
      />
    );
  },
);
