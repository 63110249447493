import { RulesTemplate, RulesTemplateId } from '@web-config-app/core';
import { commonRulesTemplates } from '../configs/common/rules-templates';

/**
 * Returns an object map whose keys all RulesTemplateIds (string) and values are all RulesTemplate. The
 * second param, `baseRules`, is optional and will default to the `commonRulesTemplates`.
 *
 * Any rules in `rulesToMerge` with an id matching an existing id in `baseRules` will OVERRIDE that
 * rule (which may be helpful for a tenant)
 */

export const mergeRulesTemplates = (
  rulesToMerge: RulesTemplate[] = [],
  baseRules: { [k: RulesTemplateId]: RulesTemplate } = commonRulesTemplates,
) => {
  const mergedRules = { ...baseRules };
  rulesToMerge.forEach((rule) => {
    mergedRules[rule.id] = rule;
  });

  return mergedRules;
};
