import { Spinner, StackLayout } from '@leagueplatform/genesis-core';

export const LoadingIndicator = () => (
  <StackLayout
    css={{ width: '100%', height: '100vh' }}
    horizontalAlignment="center"
    verticalAlignment="center"
  >
    <Spinner loading />
  </StackLayout>
);
