import * as React from 'react';
import { Button, Tooltip } from '@leagueplatform/genesis-core';
import { IconButtonWithTooltipProps } from '../../types/components';

/**
 This component renders a Genesis Core Tooltip automatically with an icon button, the tooltips content is the button's primary label so the label within the button itself is aria-hidden so the button label is not read out by screen-readers twice.
 It gets passed a <span> because the tooltip has the content and the button is aria-described by it automatically.
 */

export const IconButtonWithTooltip = React.forwardRef(
  (
    {
      buttonLabel,
      tooltipContent,
      onClick,
      icon,
      css,
      size = 'medium',
      priority = 'primary',
      disabled = false,
      quiet = false,
      destructive = false,
    }: IconButtonWithTooltipProps,
    ref: React.ForwardedRef<HTMLButtonElement>,
  ) => (
    <Tooltip
      align="center"
      avoidCollisions
      content={tooltipContent}
      side="bottom"
    >
      <Button
        ref={ref}
        onClick={onClick}
        icon={icon}
        size={size}
        priority={priority}
        quiet={quiet}
        destructive={destructive}
        disabled={disabled}
        css={css}
        hideLabel
      >
        {buttonLabel}
      </Button>
    </Tooltip>
  ),
);
