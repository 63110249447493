import { GDSColors } from '@leagueplatform/genesis-core';
import { buttonTokens } from './buttonTokens';
import { surfaceTokens } from './surfaceTokens';
import { interactiveTokens } from './interactiveTokens';
import { statusTokens } from './statusTokens';
import { decorativeTokens } from './decorativeTokens';
import { inputTokens } from './inputTokens';

export const colors: GDSColors = {
  ...surfaceTokens,
  ...buttonTokens,
  ...interactiveTokens,
  ...inputTokens,
  ...statusTokens,
  ...decorativeTokens,
};
