import * as React from 'react';
import { ArrayControlProps } from '@jsonforms/core';
import { EntityFormArrayControlProps } from '../../types/controls';
import { useEntityFormArrayControl } from '../../hooks/use-entity-form-array-control/use-entity-form-array-control';
import { Hidden } from '../hidden/hidden.component';

/*
 * EntityFormArrayControl encapsulates taking in the JsonForms props, calling useEntityFormArrayControl to get the correct annotation/props, and passing those to the arrayControl. The renderControl prop lets you completely take over how the control is rendered
 */

export interface EntityFormArrayControlOwnProps extends ArrayControlProps {
  renderControl: (props: EntityFormArrayControlProps) => JSX.Element;
}

export const EntityFormArrayControl: React.FC<
  EntityFormArrayControlOwnProps
> = ({ renderControl, ...props }) => {
  const entityFormArrayControlProps = useEntityFormArrayControl(props);
  const { hidden } = entityFormArrayControlProps;

  if (hidden) return <Hidden {...entityFormArrayControlProps} />;

  return renderControl(entityFormArrayControlProps);
};
