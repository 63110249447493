import type {
  AnnotatedJsonSchema,
  RelationshipsSchema,
} from '@web-config-app/core';
import { isRelationshipArrayDataSchema } from '@web-config-app/schema-utils';
/**
 * This function will be called during tree view node calculation. Each tree node
 * will contain a `schema` reference that will be passed to the `EntityForm` when loading
 * a tree node.
 *
 */
export const mapEntityReferenceAnnotationToSchemaProperty = (
  {
    'x-entity-inject-reference': entityRelationshipAnnotation,
    ...schema
  }: AnnotatedJsonSchema,
  relationshipsSchema?: RelationshipsSchema,
) => {
  if (!entityRelationshipAnnotation) {
    return schema;
  }

  if (schema.type !== 'object') {
    console.warn(
      'Annotation x-entity-inject-reference should only exist on `object` type schemas. The annotation will be ignored.',
    );
    return schema;
  }

  const {
    relationshipDataIdKey = 'id',
    includesDisplayNameKey = 'name',
    relationship,
    ...entityReferenceProps
  } = entityRelationshipAnnotation;

  const relationshipSchema =
    relationshipsSchema?.properties[relationship].properties.data;

  const isArray = isRelationshipArrayDataSchema(relationshipSchema);

  const injectedReferenceAnnotations = {
    'x-entity-reference': {
      relationship,
      relationshipDataIdKey,
      includesDisplayNameKey,
    },
  };

  const injectedSchema = isArray
    ? {
        ...relationshipSchema,
        ...entityReferenceProps,
        items: {
          ...relationshipSchema.items,
          ...injectedReferenceAnnotations,
        },
      }
    : {
        ...relationshipSchema,
        ...entityReferenceProps,
        ...injectedReferenceAnnotations,
      };

  return {
    ...schema,
    /**
     * add a new property into the existing schema with the same name as the relationship. This will allow
     * us to render `the EntitySelectControl` but it won't behave the same way as other controls since our
     * manner of referencing other entities is via the top-level `relationships` property of the root schema and
     * we will have to set that data.
     */
    properties: {
      ...schema.properties,
      [relationship]: injectedSchema,
    },
  };
};
