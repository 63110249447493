export const DATA_TYPES = {
  BOOLEAN: 'boolean',
  DATETIME: 'datetime',
  INTEGER: 'int',
  NUMBER: 'number',
  OBJECT: 'object',
  STRING: 'string',
  UNSET: '',
};

export const DEFAULT_DATA_TYPE = DATA_TYPES.UNSET;
