import * as React from 'react';
import {
  TextAction,
  Card,
  useClickableCard,
  StackLayout,
  Icon,
  VisuallyHidden,
  focusOutlineOuter,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { NavLink } from '@leagueplatform/routing';
import { Hidden } from '../../hidden/hidden.component';
import { EntityFormControlProps } from '../../../types/controls';
import { useEntityDetailsProps } from '../../../hooks/use-entity-details-props/use-entity-details-props';

import { useAnnotatedSchemaProps } from '../../../hooks/use-annotated-schema-props/use-annotated-schema-props';

/**
 * Renders for complex object and arrays of complex objects where their schema is annotated with
 * 'x-entity-control'.type is `objectCard`
 */

export const ObjectCard = ({
  schema,
  path,
  ...props
}: EntityFormControlProps) => {
  const { formPath } = useEntityDetailsProps();
  const [cardToPath] = React.useState(
    formPath === 'root' ? path : `${formPath}.${path}`,
  );
  const { label, hidden } = useAnnotatedSchemaProps(schema);
  const { formatMessage } = useIntl();
  const { primaryActionRef, clickAreaRef, onCardMouseDown, onCardMouseUp } =
    useClickableCard();

  return hidden ? (
    <Hidden {...props} schema={schema} path={path} />
  ) : (
    <Card.Elevated
      css={{
        marginY: '$oneAndQuarter',
        backgroundColor: '$surfaceBackgroundPrimary',
        '&:focus-within': focusOutlineOuter,
        '&:hover': { backgroundColor: '$inputBackgroundHovered' },
        '&:has(a:active)': { backgroundColor: '$inputBackgroundPressed' },
      }}
    >
      <Card.ClickArea
        ref={clickAreaRef}
        onCardMouseUp={onCardMouseUp}
        onCardMouseDown={onCardMouseDown}
      >
        <Card.Section css={{ padding: 0 }}>
          <StackLayout
            orientation="horizontal"
            verticalAlignment="center"
            css={{ width: '100%', position: 'relative' }}
          >
            <VisuallyHidden id="object-link-description">
              {formatMessage({ id: 'OBJECT_CARD_LINK_OPENS_IN_OWN_PAGE' })}
            </VisuallyHidden>
            <TextAction
              as={NavLink}
              id="object-link"
              aria-labelledby="object-link object-link-description"
              to={`?path=${cardToPath}`}
              ref={primaryActionRef}
              css={{ flexGrow: 1, padding: '$oneAndHalf' }}
            >
              {label}
            </TextAction>

            <Icon
              icon="tinyDisclosureIndicator"
              tint="$tertiaryTextDefault"
              size="$one"
              css={{
                position: 'absolute',
                pointerEvents: 'none',
                right: '$oneAndHalf',
                height: '100%',
              }}
            />
          </StackLayout>
        </Card.Section>
      </Card.ClickArea>
    </Card.Elevated>
  );
};
