/* eslint-disable react/jsx-no-useless-fragment */
import * as React from 'react';
import { useIntl } from '@leagueplatform/locales';

export interface EntityNameProps {
  id: string;
  singular?: boolean;
  lowercase?: boolean;
  withArticle?: boolean;
}

const getStartsWith = (entityName: string): 'consonant' | 'vowel' =>
  ['a', 'e', 'h', 'i', 'o', 'u'].includes(entityName[0].toLowerCase())
    ? 'vowel'
    : 'consonant';

export const EntityName: React.FC<EntityNameProps> = ({
  id,
  singular = false,
  lowercase = false,
  withArticle = false,
}) => {
  const { formatMessage } = useIntl();
  const entityName = formatMessage({ id }, { count: singular ? 1 : 2 });
  const casedEntityName = lowercase ? entityName.toLowerCase() : entityName;
  const startsWith = getStartsWith(casedEntityName);

  return (
    <>
      {withArticle && singular
        ? `${formatMessage(
            { id: 'ENTITY_NAME_ARTICLE' },
            { startsWith },
          )} ${casedEntityName}`
        : casedEntityName}
    </>
  );
};
