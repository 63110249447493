import * as React from 'react';
import { Box, ParagraphText, styled } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import type { Formatter } from '@leagueplatform/locales';

const StyledList = styled('ul', {
  typography: '$bodyOne',
  color: '$onSurfaceTextPrimary',
  marginLeft: '1.5rem',
  listStyleType: 'disc',
  listStylePosition: 'outside',
  maxWidth: '80rem',
});

const strong: Formatter = (msg) => <strong>{msg}</strong>;

export const EmptyRuleBuilder = () => {
  const { formatMessage } = useIntl();
  return (
    <Box
      css={{
        padding: '$oneAndHalf $two',
        backgroundColor: '$surfaceBackgroundSecondary',
        width: '100%',
        borderRadius: '$extraLarge',
      }}
    >
      <ParagraphText>
        {formatMessage({ id: 'EMPTY_RULE_STATE_HEADING' }, { strong })}
      </ParagraphText>
      <StyledList>
        <li>{formatMessage({ id: 'RULE_DESCRIPTION_SHORT' }, { strong })}</li>
        <li>{formatMessage({ id: 'GROUP_DESCRIPTION_SHORT' }, { strong })}</li>
      </StyledList>
    </Box>
  );
};
