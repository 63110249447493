// adds a leading slash to the beginning of a string (presumably as a path in a url)
// if it doesn't have one already

export function getPathWithLeadingSlash(path: string) {
  return path[0] === '/' ? path : `/${path}`;
}

export function getDomainRoute(basePath: string, ...args: string[]) {
  return `${getPathWithLeadingSlash(basePath)}${getPathWithLeadingSlash(
    args.join('/'),
  )}`;
}
