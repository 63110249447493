import React, { useRef } from 'react';
import { cloneDeep } from 'lodash';
import { QueryBuilder } from 'react-querybuilder';
import {
  Box,
  Button,
  HeadingText,
  InputStatusMessage,
  ParagraphText,
  StackItem,
  StackLayout,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { ValueInput } from '../../components/value-input/value-input.component';
import { JsonLogicOutput } from '../../components/json-logic-output/json-logic-output.component';
import { OperatorSelector } from '../../components/operator-selector/operator-selector.component';
import { RemoveButton } from '../../components/remove-button/remove-button.component';
import { RuleFormStyles } from '../../components/rule-form-styles/rule-form-styles.component';
import { SingleRule } from '../../components/single-rule/single-rule.component';
import { RuleGroup } from '../../components/rule-group/rule-group.component';
import { useCreateRule } from '../../hooks/use-create-rule.hook';
import { useManageRuleFocus } from '../../hooks/use-manage-rule-focus.hook';
import { RuleImport } from '../../components/rule-import/rule-import.component';
import { EmptyRuleBuilder } from '../../components/empty-rule-builder/empty-rule-builder.component';
import { getOperators } from '../../utils/get-operators';
import { useGetDataPointFields } from '../../hooks/use-get-data-point-fields.hook';
import { RuleBuilderGuide } from '../../components/rule-builder-guide/rule-builder-guide.component';
import { useGenerateJsonLogic } from '../../hooks/use-generate-json-logic.hook';
import { useValidateRuleQuery } from '../../hooks/use-validate-rule-query.hook';
import { DATA_POINT_VALIDATION } from '../../constants/validation.constants';
import {
  FilteredGroupType,
  useGenerateGroupLevels,
} from '../../hooks/use-generate-group-levels.hook';

export const CreateRulePage = () => {
  const { formatMessage } = useIntl();
  const { onAddRule, onAddGroup, onRemove, queryBuilderContainerId } =
    useManageRuleFocus();
  const { dataPointFields } = useGetDataPointFields();
  const { handleFormSubmit, ...generateJsonProps } = useGenerateJsonLogic();

  const validateDataPointOn = DATA_POINT_VALIDATION;

  const { validateQuery, validationMessage, invalidRules } =
    useValidateRuleQuery(validateDataPointOn);
  const submitRef = useRef(null as HTMLButtonElement | null);

  const { query, setQuery, emptyRules } = useCreateRule();

  const { filteredGroups, setFilteredGroups, filterOutRulesAndAddGroupLevel } =
    useGenerateGroupLevels();

  const onQueryChange = async (newQuery: typeof query) => {
    setQuery(newQuery);

    const { rules } = newQuery;
    const rulesClone = cloneDeep(rules);
    const filtered = filterOutRulesAndAddGroupLevel(
      rulesClone as FilteredGroupType[],
    );

    setFilteredGroups(filtered);

    // only want to call validateQuery outside of form submit and on Query change if there are invalidRules existing. This will update error states for individual inputs and remove them as the user fixes
    if (invalidRules.length) validateQuery(newQuery);
  };

  const configureControlElements = {
    fieldSelector: ValueInput,
    valueEditor: ValueInput,
    operatorSelector: OperatorSelector,
    removeRuleAction: RemoveButton,
    rule: SingleRule,
    ruleGroup: RuleGroup,
  };

  const onSubmitForm = (event: React.FormEvent) => {
    event?.preventDefault();

    // If query is invalid, set focus to the submit button when error message is added
    if (!validateQuery(query)) {
      if (submitRef.current) submitRef.current.focus();
      return;
    }

    handleFormSubmit(query);
  };

  return (
    <RuleFormStyles>
      <Box
        as="form"
        name="create rule"
        id="createRule"
        onSubmit={onSubmitForm}
        css={{
          width: '100%',
        }}
      >
        <StackLayout orientation="vertical" horizontalAlignment="stretch">
          <StackItem
            css={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <StackItem>
              <HeadingText level="1" size="md">
                {formatMessage({ id: 'RULE_BUILDER' })}
              </HeadingText>
              <ParagraphText>
                {formatMessage({ id: 'RULE_BUILDER_SUBHEADING' })}
              </ParagraphText>
            </StackItem>
            <RuleBuilderGuide />
            <RuleImport onQueryChange={onQueryChange} />
            <Box css={{ flexGrow: '0' }}>
              <Button
                aria-describedby="form-submit-error"
                icon="interfaceExternalShare"
                id="form-submit"
                size="medium"
                type="submit"
                ref={submitRef}
              >
                {formatMessage({ id: 'GENERATE_JSON' })}
              </Button>
              <JsonLogicOutput generateJsonProps={generateJsonProps} />
            </Box>
          </StackItem>
          <Box
            aria-live="assertive"
            css={{
              minHeight: '$three',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
            id="form-submit-error"
          >
            {validationMessage ? (
              // TODO: Remove with Genesis fix for react 18
              // @ts-ignore
              <InputStatusMessage
                css={{ margin: '$one 0' }}
                id="status-message"
                inputStatus="error"
              >
                {formatMessage({ id: validationMessage })}
              </InputStatusMessage>
            ) : null}
          </Box>
          {/*
                Read more about the QueryBuilder component here:
                https://react-querybuilder.js.org/docs/next/components/querybuilder
                The context prop : passed to each and every component, so it's accessible anywhere in the
                QueryBuilder component tree. Currently being used within the and/or component to determine
                whether or not we should the and/or switch (if there is less than one rule).
              */}
          <StackLayout
            data-testid="query-builder-container"
            tabIndex={-1}
            id={queryBuilderContainerId}
          >
            <QueryBuilder
              context={{ query, setQuery, invalidRules, filteredGroups }}
              controlElements={configureControlElements}
              fields={dataPointFields}
              onAddGroup={onAddGroup}
              onAddRule={onAddRule}
              onQueryChange={onQueryChange}
              query={query}
              onRemove={onRemove}
              getOperators={getOperators}
            />
            {emptyRules && <EmptyRuleBuilder />}
          </StackLayout>
        </StackLayout>
      </Box>
    </RuleFormStyles>
  );
};
