import type { SchemaTransformerArgs } from '@web-config-app/core';

/**
 * Returns a modified schema with the property matching the discriminator hidden via
 * x-entity-presentation.hidden
 *
 * Schemas using the discriminator like this have a constant value for the discriminated
 * property. We hide this property since it's not editable and muddies the UX.
 */

export const hideCombinatorDiscriminatorProperty = ({
  schema,
  options,
}: SchemaTransformerArgs) => {
  const discriminator = options?.discriminator;
  if (!discriminator) {
    return schema;
  }
  const discriminatorPropertySchema = schema.properties?.[discriminator];

  if (
    !discriminatorPropertySchema ||
    discriminatorPropertySchema?.enum?.length !== 1
  ) {
    return schema;
  }

  return {
    ...schema,
    properties: {
      ...schema.properties,
      [discriminator]: {
        ...discriminatorPropertySchema,
        'x-entity-presentation': {
          hidden: true,
        },
      },
    },
  };
};
