import { useState, useCallback, useEffect } from 'react';

export const useClipboardCopy = (contents: string) => {
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const copyContentsToClipboard = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(contents);
      setIsCopied(true);
    } catch (e) {
      console.error(e);
    }
  }, [contents]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isCopied) setIsCopied(false);
    }, 1500);

    return () => clearTimeout(timer);
  }, [isCopied]);

  return { copyContentsToClipboard, isCopied };
};
