import { StandaloneAuth } from '@leagueplatform/auth-standalone';
import { captureError } from '@leagueplatform/observability';

// eslint-disable-next-line consistent-return
export const getUserNameAndEmail = async () => {
  try {
    const idTokenClaims = await StandaloneAuth.client.getIdTokenClaims();
    const firstName = idTokenClaims?.given_name ?? '';
    const lastName = idTokenClaims?.family_name ?? '';
    return {
      name: `${firstName} ${lastName}`.trim(),
      email: idTokenClaims?.email ?? '',
    };
  } catch (err) {
    // If there is an error accessing the idTokenClaims, there's likely an issue with the user's authentication state more generally. IDP team recommends capturing the error and re-initiating the login process
    captureError(
      new Error(
        `Failed to retrieve claims from ID token: ${err}. Redirecting the user to login.`,
      ),
    );
    StandaloneAuth.client.logout();
  }
};
