import * as React from 'react';
import {
  Box,
  GDSStyleObject,
  TextAction,
  UtilityText,
} from '@leagueplatform/genesis-core';
import { Link } from '@leagueplatform/routing';

export const Table = ({ children }: React.PropsWithChildren) => (
  <Box
    as="table"
    css={{
      borderCollapse: 'collapse',
      tableLayout: 'fixed',
      textAlign: 'left',
      whiteSpace: 'nowrap',
      width: '100%',
    }}
  >
    {children}
  </Box>
);

interface TableCellProps extends React.PropsWithChildren {
  as?: 'td' | 'th';
  css?: GDSStyleObject;
  scope?: string;
}

export const BaseTableCell = ({
  as = 'td',
  scope,
  children,
  css,
}: TableCellProps) => (
  <Box as={as} scope={scope} css={css}>
    {children}
  </Box>
);

export const TableCell = ({ children, as, css, scope }: TableCellProps) => (
  <BaseTableCell
    as={as}
    css={{
      paddingX: '$half',
      paddingY: '$threeQuarters',
      verticalAlign: 'middle',
      ...css,
    }}
    scope={scope}
  >
    {children}
  </BaseTableCell>
);

interface TableCellWithLinkProps extends TableCellProps {
  to: string;
  tabIndex?: number;
}

export const TableCellWithLink = ({
  as,
  to,
  scope,
  tabIndex = 0,
  children,
  css,
}: TableCellWithLinkProps) => (
  <BaseTableCell as={as} scope={scope} css={css}>
    <TextAction
      as={Link}
      to={to}
      tabIndex={tabIndex}
      css={{
        display: 'block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        paddingX: '$half',
        paddingY: '$threeQuarters',
        '&:hover': {
          textDecoration: 'none',
        },
        '&&:active': {
          outline: 'none',
        },
      }}
    >
      {children}
    </TextAction>
  </BaseTableCell>
);

export const TableHeading = ({
  children,
  css,
}: React.PropsWithChildren<{ css?: GDSStyleObject }>) => (
  <BaseTableCell
    as="th"
    scope="col"
    css={{
      paddingX: '$half',
      paddingY: '$threeQuarters',
      ...css,
    }}
  >
    <UtilityText emphasis="subtle" size="sm">
      {children}
    </UtilityText>
  </BaseTableCell>
);

export interface TableRowProps {
  css?: GDSStyleObject;
}

export const TableRow = ({
  children,
  css,
}: React.PropsWithChildren<TableRowProps>) => (
  <Box
    as="tr"
    css={{
      borderTop: '1px solid $onSurfaceBorderSubdued',
      '&:hover': {
        backgroundColor: '$interactiveBackgroundHovered',
      },
      '&:active': {
        backgroundColor: '$interactiveBackgroundPressed',
      },
      ...css,
    }}
  >
    {children}
  </Box>
);
