import {
  useEntity,
  useEntityList,
  useConfigApp,
  useConfigAppPath,
  useConfigAppParams,
} from '@web-config-app/core-react';
import { Category } from '@web-config-app/core';
import { EntityListPageViewProps } from '@web-config-app/core-react-ui';
import { useIntl } from '@leagueplatform/locales';

interface EntityListPageContainerProps {
  entityListPageContentsRenderer: (
    props: EntityListPageViewProps,
  ) => JSX.Element;
}

export function EntityListPageContainer({
  entityListPageContentsRenderer,
}: EntityListPageContainerProps) {
  const { formatMessage } = useIntl();
  const { domainId, entityId } = useConfigAppParams();
  const entity = useEntity({ domainId, entityId });
  const { getEntityDetailsPathForId, entityCreatePath } = useConfigAppPath({
    entityIdParam: entityId,
    domainIdParam: domainId,
  });
  const { tenantName } = useConfigApp();
  const { pagination, data, isError, isLoading, loadPage } = useEntityList({
    entity,
  });
  const displayEmptyCard = isError || (!isLoading && !data?.length);
  const emptyCardHeading = formatMessage(
    { id: isError ? 'ERROR_ENTITY_TITLE' : 'EMPTY_ENTITY_TITLE' },
    { entity: entity.name },
  );

  const emptyCardDescription = formatMessage(
    {
      id: isError ? 'ERROR_ENTITY_DESCRIPTION' : 'EMPTY_ENTITY_DESCRIPTION',
    },
    { entity: entity.name, tenant: tenantName },
  );

  if (entity.category === Category.Hidden) {
    throw new Error(`Entity ${entity.id} list page does not exist`);
  }

  return entityListPageContentsRenderer({
    displayEmptyCard,
    emptyCardHeading,
    emptyCardDescription,
    getEntityDetailsPathForId,
    entityCreatePath,
    pagination,
    loadPage,
    isLoading,
    data,
    entity,
  });
}
