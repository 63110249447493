import * as React from 'react';
import * as Toolbar from '@radix-ui/react-toolbar';
import { Button } from '@leagueplatform/genesis-core';

interface RichtextToolbarButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  label: string;
  icon?: string | React.ReactElement;
  as?: React.ElementType<any> | undefined;
  active?: boolean;
  disabled?: boolean;
  value?: string;
}

export const RichtextToolbarButton = React.forwardRef(
  (
    {
      onClick,
      label,
      icon,
      as,
      active = false,
      disabled = false,
      value,
    }: RichtextToolbarButtonProps,
    ref: React.ForwardedRef<HTMLButtonElement>,
  ) => (
    <Button
      ref={ref}
      as={as || Toolbar.Button}
      onClick={onClick}
      size="toolbar"
      priority="tertiary"
      quiet
      hideLabel={!!icon}
      // TODO:: FIX THIS
      // @ts-expect-error
      icon={icon}
      value={value}
      data-format-active={active}
      disabled={disabled}
      css={{
        '&[aria-disabled="true"], &:disabled': {
          backgroundColor: 'transparent',
          color: '$interactiveActionDisabled',
          borderColor: 'transparent',
        },
        '&:hover': {
          backgroundColor: '$tertiaryBackgroundHovered',
          color: '$tertiaryTextHovered',
          borderColor: 'transparent',
        },
        '&[data-format-active="true"]': {
          backgroundColor: '$surfaceBackgroundTertiary',
          '&:hover': {
            backgroundColor: '$tertiaryBackgroundHovered',
            color: '$tertiaryTextHovered',
            borderColor: 'transparent',
          },
        },
      }}
    >
      {label}
    </Button>
  ),
);
