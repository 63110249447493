import * as React from 'react';
import { JsonForms, JsonFormsInitStateProps } from '@jsonforms/react';
import { JsonFormsCore, JsonFormsRendererRegistryEntry } from '@jsonforms/core';
import { ajv } from '@web-config-app/validation';
import { vanillaCells } from '@jsonforms/vanilla-renderers';
import { defaultRenderers } from '../../renderers/default-renderers';

export interface OnChangeParams {
  data?: JsonFormsCore['data'];
  errors?: JsonFormsCore['errors'];
}

export type EntityFormProps = Omit<
  JsonFormsInitStateProps,
  'data' | 'uischema' | 'renderers'
> & {
  data?: any;
  renderers?: JsonFormsRendererRegistryEntry[];
  onDataChange?: (params: OnChangeParams['data']) => any;
  onErrorsChange?: (params: OnChangeParams['errors']) => any;
};

const defaultConfigOptions = {
  restrict: true,
  hideRequiredAsterisk: true,
};

export const EntityForm: React.FC<EntityFormProps> = ({
  schema,
  data,
  renderers = [],
  config: configOptions,
  ajv: customAjv,
  onDataChange,
  onErrorsChange,
}) => {
  const config = React.useMemo(
    () => ({ ...defaultConfigOptions, ...configOptions }),
    [configOptions],
  );

  const joinedRenderers = React.useMemo(
    () => [...defaultRenderers, ...renderers],
    [renderers],
  );

  return schema ? (
    <JsonForms
      schema={schema}
      renderers={joinedRenderers}
      cells={vanillaCells}
      data={data}
      onChange={(changes) => {
        onErrorsChange?.(changes.errors);
        onDataChange?.(changes.data);
      }}
      config={config}
      ajv={customAjv ?? ajv}
      validationMode="ValidateAndHide"
    />
  ) : null;
};
