import {
  createRenderer,
  createPrimitiveControlRenderer,
} from '../utils/create-renderer';
import { TextInputControl } from '../components/controls-primitive/text-input-control/text-input-control.component';
import { DateInputControl } from '../components/controls-primitive/date-input-control/date-input-control.component';
import { DateTimeInputControl } from '../components/controls-primitive/datetime-input-control/datetime-input-control.component';
import { SelectInputControl } from '../components/controls-primitive/select-input-control/select-input-control.component';
import { NumberInputControl } from '../components/controls-primitive/number-input-control/number-input-control.component';
import { IntegerInputControl } from '../components/controls-primitive/integer-input-control/integer-input-control.component';
import { BooleanInputControl } from '../components/controls-primitive/boolean-input-control/boolean-input-control.component';
import { ImageInputControl } from '../components/controls-primitive/image-input-control/image-input-control.component';
import { ObjectControl } from '../components/controls-complex/object-control/object-control.component';
import { ObjectCard } from '../components/controls-complex/object-card/object-card.component';
import { ArrayOfPrimitiveObjectsControl } from '../components/controls-complex/array-of-primitive-objects-control/array-of-primitive-objects-control.component';
import { ArrayPrimitiveControl } from '../components/controls-complex/array-primitive-control/array-primitive-control.component';
import { ArrayOfComplexObjectsControl } from '../components/controls-complex/array-of-complex-objects-control/array-of-complex-objects-control.component';
import { EntityReferenceControl } from '../components/controls-complex/entity-reference-control/entity-reference-control.component';
import { ArrayEntityReferenceControl } from '../components/controls-complex/array-entity-reference-control/array-entity-reference-control.component';
import { SelectableDiscriminatorCombinatorControl } from '../components/controls-complex/selectable-discriminator-combinator-control/selectable-discriminator-combinator-control.component';
import { ConstantDiscriminatorCombinatorControl } from '../components/controls-complex/constant-discriminator-combinator-control/constant-discriminator-combinator-control.component';
import { RichTextInputControl } from '../components/controls-primitive/rich-text-input-control/rich-text-input-control.component';
import { JsonLogicInputControl } from '../components/controls-primitive/json-logic-input-control/json-logic-input-control.component';
import {
  isTextInputControl,
  isDateInputControl,
  isDateTimeInputControl,
  isNumberInputControl,
  isIntegerInputControl,
  isBooleanInputControl,
  isImageInputControl,
  isObjectDispatchControl,
  isArrayPrimitiveDispatchControl,
  isSelectInputControl,
  isArrayObjectPrimitiveDispatchControl,
  isArrayObjectComplexDispatchControl,
  isArrayEntityReferenceControl,
  isEntityReferenceInjectedControl,
  isEntityReferenceStringControl,
  isRichTextInputControl,
  isCombinatorObjectInputControl,
  isCombinatorOneOfArrayInputControl,
  isCombinatorArrayItemPageControl,
  isObjectCard,
  isJsonLogicInputControl,
} from '../testers/control-testers';

/**
 * Primitive Input Controls
 */

export const textInputControlRenderer = createPrimitiveControlRenderer(
  isTextInputControl,
  TextInputControl,
);

export const dateInputControlRenderer = createPrimitiveControlRenderer(
  isDateInputControl,
  DateInputControl,
);

export const dateTimeInputControlRenderer = createPrimitiveControlRenderer(
  isDateTimeInputControl,
  DateTimeInputControl,
);

export const selectInputControlRenderer = createPrimitiveControlRenderer(
  isSelectInputControl,
  SelectInputControl,
);

export const numberInputControlRenderer = createPrimitiveControlRenderer(
  isNumberInputControl,
  NumberInputControl,
);

export const integerInputControlRenderer = createPrimitiveControlRenderer(
  isIntegerInputControl,
  IntegerInputControl,
);

export const booleanInputControlRenderer = createPrimitiveControlRenderer(
  isBooleanInputControl,
  BooleanInputControl,
);

export const richTextInputControlRenderer = createPrimitiveControlRenderer(
  isRichTextInputControl,
  RichTextInputControl,
);

export const jsonLogicInputControlRenderer = createPrimitiveControlRenderer(
  isJsonLogicInputControl,
  JsonLogicInputControl,
);

export const imageInputControlRenderer = createPrimitiveControlRenderer(
  isImageInputControl,
  ImageInputControl,
);

/**
 * Object Controls
 */
export const objectControlRenderer = createRenderer(
  isObjectDispatchControl,
  ObjectControl,
  'object',
);

export const objectCardRenderer = createRenderer(
  isObjectCard,
  ObjectCard,
  'object',
);

/**
 * Array Controls
 */

export const arrayComplexObjectControlRenderer = createRenderer(
  isArrayObjectComplexDispatchControl,
  ArrayOfComplexObjectsControl,
  'array',
);

export const arrayPrimitiveObjectControlRenderer = createRenderer(
  isArrayObjectPrimitiveDispatchControl,
  ArrayOfPrimitiveObjectsControl,
  'array',
);

export const arrayPrimitiveControlRenderer = createRenderer(
  isArrayPrimitiveDispatchControl,
  ArrayPrimitiveControl,
  'array',
);

/**
 * Entity reference controls
 */

export const entityReferenceStringControlRenderer =
  createPrimitiveControlRenderer(
    isEntityReferenceStringControl,
    EntityReferenceControl,
  );

export const entityReferenceObjectControlRenderer = createRenderer(
  isEntityReferenceInjectedControl,
  EntityReferenceControl,
  'object',
);

export const arrayEntityReferenceControlRenderer = createRenderer(
  isArrayEntityReferenceControl,
  ArrayEntityReferenceControl,
  'array',
);

/**
 * Combinator Controls
 */

export const combinatorObjectInputControlAnyOfRenderer = createRenderer(
  isCombinatorObjectInputControl,
  SelectableDiscriminatorCombinatorControl,
  'anyOf',
);

export const combinatorObjectInputControlOneOfRenderer = createRenderer(
  isCombinatorObjectInputControl,
  SelectableDiscriminatorCombinatorControl,
  'oneOf',
);

export const combinatorArrayInputControlOneOfRenderer = createRenderer(
  isCombinatorOneOfArrayInputControl,
  SelectableDiscriminatorCombinatorControl,
  'oneOf',
);

export const constantDiscriminatorCombinatorControlRenderer = createRenderer(
  isCombinatorArrayItemPageControl,
  ConstantDiscriminatorCombinatorControl,
  'anyOf',
);
