import * as React from 'react';
import { TextInput } from '@leagueplatform/genesis-core';
import type { AnnotatedJsonSchema7 } from '@web-config-app/core';
import { ControlComponent } from '../../../types/controls';
import { EntityFormControl } from '../../entity-form-control/entity-form-control.component';
import { getMaxAndMinFromSchema } from '../../../utils/get-max-and-min-from-schema.util';
import { deriveNumberFromInputString } from '../../../utils/derive-number-from-input-string/derive-number-from-input-string.util';

export const IntegerInputControl: ControlComponent = (props) => (
  <EntityFormControl
    {...props}
    renderControl={({
      data,
      handleChange,
      path,
      schema,
      inputStatus,
      enabled,
      required,
    }) => {
      const { maximum, minimum } = getMaxAndMinFromSchema(
        schema as AnnotatedJsonSchema7,
      );
      return (
        <TextInput
          type="number"
          value={data ?? ''}
          step={schema.multipleOf ?? 1}
          min={minimum}
          max={maximum}
          required={required}
          onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(
              path,
              deriveNumberFromInputString(evt.currentTarget.value),
            )
          }
          onClear={() => handleChange(path, undefined)}
          clearable={enabled && typeof data === 'number'}
          inputStatus={inputStatus}
          // TODO :: set readOnly if const value present as well https://everlong.atlassian.net/browse/CACT-721
          readOnly={!enabled}
          css={{
            backgroundColor: '$inputBackgroundDefault',
          }}
        />
      );
    }}
  />
);
