import { GDSStyleObject } from '@leagueplatform/genesis-core';

const richTextEditorToolbarStyles = {
  '.toolbar-separator': {
    border: '$borderWidths$thin solid $onSurfaceBorderSubdued',
    margin: '0 $half',
  },
  '.toolbar-root': {
    display: 'flex',
    border: '$borderWidths$thin solid $interactiveBorderDefault',
    borderStartStartRadius: '$medium',
    borderStartEndRadius: '$medium',
    borderBottom: 0,
    padding: '$half',
    width: '100%',
  },
  '.toolbar-toggle-group': {
    display: 'flex',
    gap: '$quarter',
  },
};

export const baseInputStyles = {
  border: '$borderWidths$thin solid $interactiveBorderDefault',
  borderEndStartRadius: '$medium',
  borderEndEndRadius: '$medium',
  padding: '$threeQuarters $one',
  flex: '1 0 auto',
  width: '100%',
  '&:focus, &[class*="GDS"]:focus': {
    borderColor: '$interactiveFocusInner',
    boxShadow:
      'inset 0 0 0 $borderWidths$innerFocus $colors$interactiveFocusInner',
    // Visible in Windows high-contrast themes
    outline: '$borderWidths$innerFocus  solid transparent',
  },
  color: '$onSurfaceTextPrimary',
  backgroundColor: '$surfaceBackgroundPrimary',
  transition: 'box-shadow $default ease-in-out',
  '&:focus': {
    borderColor: '$interactiveFocusInner',
  },
  '&:placeholder': {
    typography: '$bodyTwo',
    color: '$onSurfaceTextSubdued',
  },
  '&:hover:not(:focus):not([disabled])': {
    borderColor: '$inputBorderHovered',
    backgroundColor: '$inputBackgroundHovered',
  },
  '&:read-only, &[aria-readonly="true"]': {
    backgroundColor: '$inputBackgroundDisabled',
  },
  '&:disabled, &[aria-disabled="true"]': {
    backgroundColor: '$inputBackgroundDisabled',
  },
  '&.error': {
    borderColor: '$inputBorderCritical',
    '&:hover:not(:focus)': {
      borderColor: '$inputBorderCritical',
    },
  },
};

export const richTextEditorCss = {
  width: '100%',
  ...richTextEditorToolbarStyles,
  // rich text editor input
  '.rich-text-editor-input': {
    ...baseInputStyles,
  },
  // rich-text-editor content styles
  typography: '$bodyTwo',
  h1: {
    typography: '$headingOne',
    margin: '$one 0',
  },
  h2: {
    typography: '$headingTwo',
    margin: '$one 0',
  },
  h3: {
    typography: '$headingThree',
    margin: '$one 0',
  },
  h4: {
    typography: '$headingFour',
    margin: '$one 0',
  },
  h5: {
    typography: '$subtitleOne',
    margin: '$one 0',
  },
  h6: {
    typography: '$subtitleTwo',
    margin: '$one 0',
  },
  b: {
    fontWeight: '$subtitleOne',
  },
  i: {
    fontStyle: 'italic',
  },
  em: {
    fontStyle: 'italic',
  },
  a: {
    color: '$interactiveActionPrimary',
  },
  ol: {
    listStyle: 'revert',
    paddingLeft: '$one',
    margin: '$one 0',
  },
  ul: {
    listStyle: 'revert',
    paddingLeft: '$one',
    margin: '$one 0',
  },
  blockquote: {
    margin: '$one',
    display: 'flex',
    '&::before': {
      flexShrink: 0,
      marginRight: '$one',
      content: '',
      width: '5px',
      borderRadius: '$large',
      backgroundColor: '$decorativeBrandPrimaryBrightest',
    },
  },
  '.editor-nestedListItem': {
    listStyle: 'none',
  },
} as GDSStyleObject;
