import * as React from 'react';
import {
  Box,
  Icon,
  Radio,
  UtilityText,
  styled,
} from '@leagueplatform/genesis-core';
import { FlatDataPoint, ParentId } from '../../types/flat-data-point.types';
import { FolderIcon } from '../../assets/folder.icon';
// import FolderIcon from 'assets/folder-icon.svg';

const StyledNavItem = styled('li', {
  padding: '$half 0',
  borderBottomColor: '$onSurfaceBorderSubdued',
  borderBottomStyle: 'solid',
  borderBottomWidth: '$thin',
  '&:last-child': {
    borderBottom: 'none',
  },
});

const StyledNavButton = styled('button', {
  alignItems: 'center',
  background: 'none',
  border: 'none',
  color: '$onSurfaceTextPrimary',
  cursor: 'pointer',
  display: 'flex',
  justifyItems: 'flex-start',
  padding: '$half 0',
  textAlign: 'left',
  width: '100%',
  '&:hover, &:focus': {
    '> span, > svg': {
      color: '$interactiveActionHovered',
    },
  },
});

export type PassIdFunction = (id: ParentId) => void;

export interface SelectDataPointProps {
  containerId: string;
  dataPoints: FlatDataPoint[];
  labelledBy: string;
  onNavigate: PassIdFunction;
  onSelectItem: PassIdFunction;
  selectedDataPoint?: ParentId;
}

export const SelectDataPoint = ({
  containerId,
  dataPoints,
  labelledBy,
  onNavigate,
  onSelectItem,
  selectedDataPoint,
}: SelectDataPointProps) => (
  <Box
    aria-labelledby={labelledBy}
    as="ul"
    css={{ width: '100%', margin: '$one 0 $two' }}
    id={containerId}
    tabIndex={-1}
  >
    {dataPoints.map(({ id, isCategory, label, parentId }, idx) => {
      const itemId = id || `item_${idx}`;
      return (
        <StyledNavItem key={itemId}>
          {isCategory ? (
            <StyledNavButton type="button" onClick={() => onNavigate(id)}>
              <Icon
                css={{
                  display: 'flex',
                  marginRight: '$half',
                  color: '$onSurfaceIconPrimary',
                }}
                size="$one"
                icon={<FolderIcon />}
              />
              <UtilityText css={{ textTransform: 'capitalize' }}>
                {label}
              </UtilityText>
              <Icon
                css={{ marginLeft: 'auto', color: '$onSurfaceIconPrimary' }}
                icon="interfaceChevronRight"
              />
            </StyledNavButton>
          ) : (
            <Radio
              checked={id === selectedDataPoint}
              css={{
                '> label': {
                  paddingLeft: '0',
                  textTransform: 'capitalize',
                  width: '100%',
                },
              }}
              id={itemId}
              label={label}
              name={parentId || 'category'}
              onChange={() => onSelectItem(id)}
              value={itemId}
            />
          )}
        </StyledNavItem>
      );
    })}
  </Box>
);
