import * as React from 'react';

export const UndoIcon = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="14"
    viewBox="0 0 13 14"
    fill="none"
  >
    <path
      d="M0.833008 1.33398V5.11053H4.60955"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.49767 12.6635C9.37893 12.6652 11.8024 10.5039 12.1293 7.64122C12.4561 4.77856 10.582 2.12663 7.77454 1.47888C4.96704 0.831125 2.12057 2.39394 1.16016 5.11041"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const RedoIcon = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="14"
    viewBox="0 0 13 14"
    fill="none"
  >
    <path
      d="M12.1662 1.33398V5.11053H8.38965"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.50152 12.6635C3.62027 12.6652 1.19675 10.5039 0.86994 7.64122C0.543132 4.77856 2.41716 2.12663 5.22466 1.47888C8.03216 0.831125 10.8786 2.39394 11.839 5.11041"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const UnorderedListIcon = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      d="M3 7.875C2.4 7.875 1.875 8.4 1.875 9C1.875 9.6 2.4 10.125 3 10.125C3.6 10.125 4.125 9.6 4.125 9C4.125 8.4 3.6 7.875 3 7.875ZM3 4.125C2.4 4.125 1.875 4.65 1.875 5.25C1.875 5.85 2.4 6.375 3 6.375C3.6 6.375 4.125 5.85 4.125 5.25C4.125 4.65 3.6 4.125 3 4.125ZM3 11.625C2.4 11.625 1.875 12.15 1.875 12.75C1.875 13.35 2.4 13.875 3 13.875C3.6 13.875 4.125 13.35 4.125 12.75C4.125 12.15 3.6 11.625 3 11.625ZM5.625 4.5V6H16.125V4.5H5.625ZM5.625 13.5H16.125V12H5.625V13.5ZM5.625 9.75H16.125V8.25H5.625V9.75Z"
      fill="currentColor"
    />
  </svg>
);

export const OrderedListIcon = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      d="M1.875 12H3.375V12.375H2.625V13.125H3.375V13.5H1.875V14.25H4.125V11.25H1.875V12ZM2.625 6.75H3.375V3.75H1.875V4.5H2.625V6.75ZM1.875 8.25H3.225L1.875 9.825V10.5H4.125V9.75H2.775L4.125 8.175V7.5H1.875V8.25ZM5.625 4.5V6H16.125V4.5H5.625ZM5.625 13.5H16.125V12H5.625V13.5ZM5.625 9.75H16.125V8.25H5.625V9.75Z"
      fill="currentColor"
    />
  </svg>
);

export const BoldIcon = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      d="M11.4373 8.85C11.8045 8.62638 12.1144 8.32006 12.3423 7.95547C12.5702 7.59087 12.7097 7.17808 12.7498 6.75C12.7568 6.36291 12.6874 5.97825 12.5457 5.61797C12.4039 5.2577 12.1926 4.92888 11.9238 4.65029C11.6549 4.3717 11.3338 4.14881 10.9788 3.99433C10.6238 3.83986 10.2419 3.75683 9.8548 3.75H4.9873V14.25H10.2373C10.6057 14.2461 10.9697 14.1696 11.3085 14.025C11.6473 13.8804 11.9543 13.6705 12.212 13.4073C12.4697 13.144 12.673 12.8326 12.8103 12.4907C12.9476 12.1489 13.0163 11.7834 13.0123 11.415V11.325C13.0126 10.8053 12.8645 10.2964 12.5855 9.85797C12.3065 9.41954 11.9082 9.06986 11.4373 8.85ZM6.4873 5.25H9.6373C9.95693 5.24011 10.2719 5.32821 10.5401 5.50249C10.8082 5.67677 11.0166 5.92888 11.1373 6.225C11.2595 6.62084 11.22 7.04894 11.0274 7.41574C10.8349 7.78254 10.505 8.05821 10.1098 8.1825C9.95634 8.22747 9.79722 8.25021 9.6373 8.25H6.4873V5.25ZM9.9373 12.75H6.4873V9.75H9.9373C10.2569 9.74011 10.5719 9.82821 10.8401 10.0025C11.1082 10.1768 11.3166 10.4289 11.4373 10.725C11.5595 11.1208 11.52 11.5489 11.3274 11.9157C11.1349 12.2825 10.805 12.5582 10.4098 12.6825C10.2563 12.7275 10.0972 12.7502 9.9373 12.75Z"
      fill="currentColor"
    />
  </svg>
);

export const ItalicIcon = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      d="M8.81992 6.75H10.3199L8.66992 14.25H7.16992L8.81992 6.75ZM10.0799 3.75C9.93159 3.75 9.78658 3.79399 9.66324 3.8764C9.53991 3.95881 9.44378 4.07594 9.38701 4.21299C9.33025 4.35003 9.31539 4.50083 9.34433 4.64632C9.37327 4.7918 9.4447 4.92544 9.54959 5.03033C9.65448 5.13522 9.78812 5.20665 9.9336 5.23559C10.0791 5.26453 10.2299 5.24968 10.3669 5.19291C10.504 5.13614 10.6211 5.04001 10.7035 4.91668C10.7859 4.79334 10.8299 4.64834 10.8299 4.5C10.8299 4.30109 10.7509 4.11032 10.6103 3.96967C10.4696 3.82902 10.2788 3.75 10.0799 3.75Z"
      fill="currentColor"
    />
  </svg>
);

export const HighlightingFormattingIcon = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      d="M7.43842 9.18082L13.2494 5.13819L14.1339 6.11012L9.56342 11.5157L7.43842 9.18082ZM5.69084 9.4892L9.09233 13.2267C9.16275 13.2964 9.24717 13.3504 9.34001 13.3851C9.43285 13.4199 9.532 13.4345 9.63091 13.428C9.72982 13.4216 9.82624 13.3942 9.91379 13.3478C10.0013 13.3013 10.078 13.2368 10.1388 13.1585L15.7274 6.54875C15.8387 6.42642 15.9017 6.26775 15.9047 6.10237C15.9076 5.93698 15.8503 5.77617 15.7433 5.64995L13.8586 3.57908C13.743 3.46079 13.5883 3.38861 13.4233 3.376C13.2584 3.3634 13.0945 3.41124 12.9623 3.51059L5.85703 8.45372C5.77336 8.50686 5.70192 8.57716 5.64743 8.65997C5.59294 8.74277 5.55664 8.83619 5.54093 8.93406C5.52523 9.03194 5.53047 9.13203 5.55631 9.22772C5.58215 9.32342 5.628 9.41254 5.69084 9.4892ZM2.0957 13.896L6.30872 14.6252L7.41807 13.6156L5.1464 11.1196L2.0957 13.896Z"
      fill="currentColor"
    />
  </svg>
);

export const InsertLinkIcon = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      d="M8.25 12.75H5.25C4.25544 12.75 3.30161 12.3549 2.59835 11.6517C1.89509 10.9484 1.5 9.99456 1.5 9C1.5 8.00544 1.89509 7.05161 2.59835 6.34835C3.30161 5.64509 4.25544 5.25 5.25 5.25H8.25V6.75H5.25C4.65326 6.75 4.08097 6.98705 3.65901 7.40901C3.23705 7.83097 3 8.40326 3 9C3 9.59674 3.23705 10.169 3.65901 10.591C4.08097 11.0129 4.65326 11.25 5.25 11.25H8.25V12.75ZM12.75 5.25H9.75V6.75H12.75C13.3467 6.75 13.919 6.98705 14.341 7.40901C14.7629 7.83097 15 8.40326 15 9C15 9.59674 14.7629 10.169 14.341 10.591C13.919 11.0129 13.3467 11.25 12.75 11.25H9.75V12.75H12.75C13.7446 12.75 14.6984 12.3549 15.4017 11.6517C16.1049 10.9484 16.5 9.99456 16.5 9C16.5 8.00544 16.1049 7.05161 15.4017 6.34835C14.6984 5.64509 13.7446 5.25 12.75 5.25ZM12 8.25H6V9.75H12V8.25Z"
      fill="currentColor"
    />
  </svg>
);

export const QuoteIcon = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      d="M7.73889 3.75L8.44252 4.74195C7.54419 5.37424 6.76027 6.15509 6.12446 7.05094C5.6508 7.72899 5.39018 8.53294 5.37596 9.35993V9.61293C5.5564 9.58907 5.72157 9.56807 5.87146 9.54993C6.01208 9.53229 6.15363 9.52305 6.29535 9.52225C6.58912 9.51364 6.88154 9.56528 7.1546 9.67398C7.42766 9.78268 7.67556 9.94613 7.88305 10.1543C8.08998 10.3589 8.2528 10.6037 8.3615 10.8736C8.4702 11.1436 8.52247 11.4329 8.51508 11.7238C8.53165 12.0594 8.48117 12.395 8.36658 12.7109C8.252 13.0268 8.07561 13.3166 7.84773 13.5636C7.61661 13.7942 7.34011 13.9743 7.03574 14.0925C6.73137 14.2106 6.40578 14.2643 6.07959 14.25C5.68772 14.2584 5.29919 14.1764 4.94408 14.0106C4.58896 13.8447 4.27679 13.5993 4.03173 13.2934C3.47687 12.5991 3.1917 11.7277 3.2288 10.8398C3.2267 9.4756 3.6218 8.14036 4.36587 6.997C5.2458 5.68652 6.39587 4.57942 7.73889 3.75ZM14.0332 3.75L14.7359 4.74195C13.8376 5.37424 13.0536 6.1551 12.4178 7.05094C11.9442 7.72899 11.6836 8.53294 11.6693 9.35993V9.61293C11.8374 9.58907 11.9968 9.56807 12.1476 9.54993C12.2943 9.53186 12.4419 9.52261 12.5897 9.52225C12.8815 9.51074 13.1726 9.56015 13.4443 9.66732C13.716 9.7745 13.9624 9.93709 14.1678 10.1447C14.3689 10.3543 14.526 10.602 14.6297 10.8732C14.7335 11.1444 14.782 11.4336 14.7722 11.7239C14.8063 12.3986 14.573 13.0597 14.123 13.5636C13.8962 13.7949 13.623 13.9757 13.3214 14.094C13.0198 14.2123 12.6966 14.2655 12.373 14.25C11.9787 14.257 11.588 14.1746 11.2301 14.0089C10.8723 13.8432 10.5567 13.5986 10.307 13.2934C9.74158 12.6039 9.44935 11.7307 9.48589 10.8398C9.49045 9.48948 9.88858 8.16984 10.6316 7.04235C11.5236 5.71984 12.6823 4.5984 14.0332 3.75Z"
      fill="currentColor"
    />
  </svg>
);

export const UpDownIcon = (): JSX.Element => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Vector"
      d="M12.45 10.95L9 14.4L5.55 10.95L4.5 12L9 16.5L13.5 12L12.45 10.95ZM12.45 7.05L9 3.6L5.55 7.05L4.5 6L9 1.5L13.5 6L12.45 7.05Z"
      fill="currentColor"
    />
  </svg>
);
