import * as React from 'react';
import { RichTextEditor } from '@web-config-app/core-react-ui';
import { ControlProps } from '../../../types/controls';
import { EntityFormControl } from '../../entity-form-control/entity-form-control.component';
import { useEntityDetailsProps } from '../../../hooks/use-entity-details-props/use-entity-details-props';

export const RichTextInputControl: React.FC<ControlProps> = (props) => {
  /**
   * use the current form path as a key to pass to the rich text editor so it knows when to re-instantiate it's
   * rich text editor. We need to use formPath here as it is unique, whereas id comes form json FORMS and will not be unique amongst the different rich-texts since they exist in different "schema slices"
   */
  const { formPath } = useEntityDetailsProps();
  return (
    <EntityFormControl
      {...props}
      renderControl={({
        data,
        id,
        handleChange,
        path,
        inputStatus,
        enabled,
      }) => {
        const handleOnChange = (value: string) => {
          handleChange(path, value);
        };

        return (
          <RichTextEditor
            id={id}
            value={data ?? ''}
            editorCacheKey={formPath}
            onChange={handleOnChange}
            readOnly={!enabled}
            inputStatus={inputStatus}
          />
        );
      }}
    />
  );
};
