import type { RelationshipItemTypeSchema } from '@web-config-app/core';

export const getConstantOrDefaultValueFromSchema = ({
  const: constValue,
  enum: enumValue,
  default: defaultValue,
}: RelationshipItemTypeSchema) => {
  if (Array.isArray(enumValue)) {
    if (enumValue.length !== 1) {
      console.warn(
        `Cannot determine a constant value, type schema enum has too many values. Using index 0`,
      );
    }

    const [typeFromEnum] = enumValue;
    return typeFromEnum;
  }

  return constValue ?? defaultValue;
};
