import * as React from 'react';
import { LeagueConfig, LeagueProvider } from '@leagueplatform/core';
import { loadTheme, GDSThemeProvider } from '@leagueplatform/genesis-core';
import { Router, createMemoryHistory } from '@leagueplatform/routing';
import {
  render,
  renderHook as baseRenderHook,
  RenderHookOptions,
  RenderHookResult,
} from '@testing-library/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { mockConfig } from '../mocks/mock-config';
import { leagueTheme } from '../theme/league';
import { testConfig } from '../setup-tests';

const coreTheme = loadTheme(leagueTheme, { useRems: true });

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

// Accepts an arbitrary number of (potentially undefined) wrapper components and
// composes them together into a single wrapper.
// The first wrapper will be the innermost, the last will be outermost.
// Lifted 100% from @league-platform/test-utils except they are intended to work
// with Jest

function composeWrappers<P extends {}>(
  ...wrappers: (React.ComponentType<P> | undefined)[]
): React.ComponentType<P> {
  // Filter out undefined wrappers
  const nonEmptyWrappers = wrappers.filter(
    (w): w is React.ComponentType<P> => !!w,
  );

  // Iterate through the wrappers and wrap them around each other (from the inside out)
  return nonEmptyWrappers.reduce(
    (prev, curr) =>
      ({ children }: { children?: React.ReactNode }) =>
        React.createElement<P>(
          curr,
          null,
          React.createElement(prev, null, children),
        ),
  );
}

export const defaultHistory = createMemoryHistory();
export type History = typeof defaultHistory;

const DefaultLeagueProvider = ({
  children,
  config = mockConfig,
}: React.PropsWithChildren<{ config?: LeagueConfig }>) => (
  <LeagueProvider config={config}>{children}</LeagueProvider>
);

const QueryClientWrapper = ({ children }: React.PropsWithChildren) => (
  <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
);

export function renderWithRouter(
  component: React.ReactElement,
  config = mockConfig,
  history: History = defaultHistory,
) {
  return render(
    <QueryClientProvider client={queryClient}>
      <DefaultLeagueProvider config={config}>
        <Router history={history}>{component}</Router>
      </DefaultLeagueProvider>
    </QueryClientProvider>,
  );
}

export function renderHook<HookProps, HookResult>(
  callback: (props: HookProps) => HookResult,
  options?: RenderHookOptions<HookProps>,
  customLeagueProvider?: React.ComponentType<any>,
): RenderHookResult<HookResult, HookProps> {
  return baseRenderHook(callback, {
    ...options,
    wrapper: composeWrappers(
      options?.wrapper,
      QueryClientWrapper,
      customLeagueProvider ?? DefaultLeagueProvider,
    ),
  });
}

export const renderWithTheme = (component: React.ReactElement) =>
  render(
    <LeagueProvider config={testConfig}>
      <GDSThemeProvider theme={coreTheme}>{component}</GDSThemeProvider>
    </LeagueProvider>,
  );
