import { GDSInputTokens } from '@leagueplatform/genesis-core';

const input: GDSInputTokens = {
  background: {
    default: '#FFFFFF',
    disabled: '#F0F0F0',
    hovered: '#F8F8F8',
    pressed: '#F0F0F0',
  },
  border: {
    default: '#949494',
    disabled: '#D6D6D6',
    hovered: '#501CD2',
    critical: '#BA1B3B',
    success: '#00A876',
    warning: '#FD9833',
  },
};

export const inputTokens = {
  input,
};
