import { useMemo } from 'react';
import { ControlProps } from '@jsonforms/core';
import { useAnnotatedSchemaProps } from '../use-annotated-schema-props/use-annotated-schema-props';
import { EntityFormControlProps } from '../../types/controls';
import { mapControlPropsToControlStatus } from '../../utils/map-control-props-to-control-status';

/**
 * This hook takes the default props passed to controls via @jsonforms/react
 * withJsonFormsControlProps and enhances them with props from our own custom
 * annotations implementation, which are present in `props.schema`
 *
 * @param props - ControlProps passed to all controls by JsonSchemaDispatch
 * @returns {@link EntityFormControlProps}
 */

export const useEntityFormControl = (
  props: ControlProps,
): EntityFormControlProps => {
  /**
   * transform custom annotations into props for our form controls and
   * get translated strings
   */
  const annotationProps = useAnnotatedSchemaProps(props.schema);
  const controlStatus = mapControlPropsToControlStatus(props);

  return useMemo(
    () => ({
      ...props,
      ...annotationProps,
      ...controlStatus,
    }),
    [props, annotationProps, controlStatus],
  );
};
