import * as React from 'react';
import { StackLayout, UtilityText } from '@leagueplatform/genesis-core';
import { CopyButton } from '../copy-button/copy-button.component';

interface ValueWIthCopyButtonProps {
  value: string;
  buttonLabel: string;
}

export const ValueWithCopyButton = ({
  value,
  buttonLabel,
}: ValueWIthCopyButtonProps) => (
  <StackLayout
    orientation="horizontal"
    verticalAlignment="center"
    spacing="$half"
    horizontalAlignment="spaceBetween"
  >
    <UtilityText
      emphasis="subtle"
      size="sm"
      css={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
    >
      {value}
    </UtilityText>
    <CopyButton
      label={buttonLabel}
      priority="tertiary"
      contents={value}
      hideLabel
    />
  </StackLayout>
);
