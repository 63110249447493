import React from 'react';
import {
  JSONEditor,
  Mode,
  MenuItem,
  MenuSeparator,
  Content,
} from 'vanilla-jsoneditor';
import { Box, StackLayout } from '@leagueplatform/genesis-core';
import { StyledJsonEditor } from './json-editor-styled.component';

// matches Content type from 'vanilla-json editor'
export type JsonEditorValue = {
  text: string;
  json: unknown;
};

interface JSONEditorProps {
  // json value of the editor, can be text or json
  editorValue: Content;
  // on change of the editorValue
  onChange?: (json: any) => void;
  // to render a topBar above the json editor
  topBarContents?: React.ReactNode;
  readOnly?: boolean;
  // unique if for the editor, can be used to attach a label
  editorId: string;
}

/**
 * Function to filter out menu items we do not want to show, and re-order buttons so that undo/redo is first matching the CACT rich text editor pattern
 */
const handleRenderMenu = (
  items: MenuItem[],
  context: { readOnly: boolean },
) => {
  const { readOnly } = context;
  const separator: MenuSeparator = {
    type: 'separator',
  };
  const modeButtons = items.filter((item) => {
    if (item.type === 'button') {
      return item.text === 'text' || item.text === 'tree';
    }
    return false;
  });
  const undoButtons = items.filter((item) => {
    if (item.type === 'button') {
      return item.className === 'jse-undo' || item.className === 'jse-redo';
    }
    return false;
  });
  const newButtons = !readOnly
    ? undoButtons.concat(separator, modeButtons)
    : modeButtons;

  return newButtons;
};

export const JsonEditor = ({
  editorValue,
  onChange,
  readOnly,
  topBarContents,
  editorId,
}: JSONEditorProps) => {
  const refContainer = React.useRef<HTMLDivElement | null>(null);
  const refEditor = React.useRef<JSONEditor | null>(null);

  const options = React.useMemo(
    () => ({
      mode: 'text' as Mode,
      onChange,
      content: editorValue,
      readOnly,
      onRenderMenu: handleRenderMenu,
    }),
    [editorValue, onChange, readOnly],
  );

  React.useEffect(() => {
    if (refContainer.current) {
      refEditor.current = new JSONEditor({
        target: refContainer.current,
        props: options,
      });
      const textbox = refContainer.current.querySelector('.cm-content');
      // add an id so we can associate a label to it
      if (textbox) textbox.id = editorId;
    }

    return () => {
      // destroy editor
      if (refEditor.current) {
        refEditor.current.destroy();
        refEditor.current = null;
      }
    };
    // only want to run once on mount - otherwise will re-render every time value changes which we won't want
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // update props
  React.useEffect(() => {
    if (refEditor.current) {
      refEditor.current.updateProps(options);
    }
  }, [editorValue, onChange, options, readOnly]);

  const showTopBar = !!topBarContents;

  return (
    <Box css={{ width: '100%' }}>
      {showTopBar && (
        <StackLayout
          data-testid="json-editor-top-bar"
          css={{
            border: '$borderWidths$thin solid $interactiveBorderDefault',
            borderBottom: 'none',
            borderStartEndRadius: '$medium',
            borderStartStartRadius: '$medium',
            backgroundColor: '$surfaceBackgroundSecondary',
            padding: '$quarter',
          }}
        >
          {topBarContents}
        </StackLayout>
      )}
      <StyledJsonEditor
        className="gds-theme"
        ref={refContainer}
        showTopBar={showTopBar}
      />
    </Box>
  );
};
