import { useQuery } from 'react-query';
import {
  FlowContractResponseData,
  getFlowContract,
} from '@web-config-app/rule-builder-api';
import { GET_FLOW_CONTRACT } from '../constants/query.constants';

export const useGetFlowContract = () =>
  useQuery<FlowContractResponseData, Error>(
    [GET_FLOW_CONTRACT],
    () => getFlowContract(),
    { staleTime: Infinity },
  );
