/* eslint-disable @typescript-eslint/no-use-before-define */
import { useState } from 'react';
import {
  RuleGroupType,
  RuleOrGroupArray,
  RuleType,
  isRuleGroup,
} from 'react-querybuilder';
import { DATA_TYPES } from '../constants/data-types.constants';
import { findDataPoint } from '../utils/find-data-point.util';
import { useGetDataPoints } from './use-get-data-points.hook';
import { PLACEHOLDER_MIN_VALUE } from '../constants/special-values.constants';

export interface ValidatedRule {
  ruleId: string;
  fieldMessage?: string;
  fieldType?: string;
}

export const validationMessageKeys = {
  emptyRule: 'FLOW_RULE_ENGINE_VALIDATION_EMPTY_RULE',
  incompleteRule: 'FLOW_RULE_ENGINE_VALIDATION_INCOMPLETE_RULE',
  invalidDataPoint: 'FLOW_RULE_ENGINE_VALIDATION_INVALID_DATA_POINT',
  invalidOperator: 'FLOW_RULE_ENGINE_VALIDATION_INVALID_OPERATOR',
  missingValue: 'FLOW_RULE_ENGINE_VALIDATION_MISSING_VALUE',
  invalidValue: 'FLOW_RULE_ENGINE_VALIDATION_INVALID_VALUE',
};

export const validationFieldTypes = {
  operator: 'operator',
  dataPoint: 'dataPoint',
  value: 'value',
};

const { NUMBER, INTEGER, DATETIME } = DATA_TYPES;

const noValueOperators = ['null', 'notNull'];
const multiValueOperators = ['between', 'notBetween'];
const numberOnlyOperators = ['>', '<', '>=', '<='];

export const isOperatorValidForDataType = (
  operator: string,
  dataType: string | undefined,
  validateDataPointOn: boolean = true,
) => {
  if (
    validateDataPointOn &&
    numberOnlyOperators.includes(operator) &&
    dataType !== NUMBER &&
    dataType !== INTEGER &&
    dataType !== DATETIME
  ) {
    return false;
  }

  return true;
};

// validateDataPointOn boolean implemented Sept 2023 so we can turn off validation to allow for user's to make rules with dataPoints outside of the single (league staging) environment CTA-1278
export const useValidateRuleQuery = (validateDataPointOn: boolean = true) => {
  const { dataPoints } = useGetDataPoints();
  const [validationMessage, setValidationMessage] = useState('' as string);
  const [invalidRules, setInvalidRules] = useState([] as ValidatedRule[]);

  const validateQueryRules = (queryRules: RuleOrGroupArray) => {
    const validatedRules: ValidatedRule[] = [];

    // @ts-ignore
    const validateRules = (rules: RuleOrGroupArray) => rules.map(validateRule);

    const validateRule = (rule: RuleType | RuleGroupType) => {
      if (isRuleGroup(rule)) {
        validateRules(rule.rules);
        return;
      }

      const { id: ruleId = '', operator, valueSource } = rule;
      const field = rule.field.trim();
      const value = rule.value.trim();

      // Is the entered Data Point a valid one?
      const fieldDataPoint = findDataPoint(field, dataPoints);
      if (!field || (validateDataPointOn && !fieldDataPoint)) {
        validatedRules.push({
          ruleId,
          fieldMessage: validationMessageKeys.invalidDataPoint,
          fieldType: validationFieldTypes.dataPoint,
        });
        return;
      }

      // Does the Operator match the Data Point type?
      if (
        !isOperatorValidForDataType(
          operator,
          fieldDataPoint?.dataType,
          validateDataPointOn,
        )
      ) {
        validatedRules.push({
          ruleId,
          fieldMessage: validationMessageKeys.invalidOperator,
          fieldType: validationFieldTypes.operator,
        });
        return;
      }

      // Is a Value expected and has it been set?
      if (!noValueOperators.includes(operator) && !value) {
        validatedRules.push({
          ruleId,
          fieldMessage: validationMessageKeys.missingValue,
          fieldType: validationFieldTypes.value,
        });
        return;
      }

      // Are multiple values expected and have they been set?
      const multiValues = value.split(',').filter((val: string) => val !== '');
      if (
        (multiValueOperators.includes(operator) && multiValues.length < 2) ||
        (multiValueOperators.includes(operator) &&
          multiValues.includes(PLACEHOLDER_MIN_VALUE))
      ) {
        validatedRules.push({
          ruleId,
          fieldMessage: validationMessageKeys.missingValue,
          fieldType: validationFieldTypes.value,
        });
        return;
      }

      // Are Values meant to be data points and are they valid?
      const isValueDataPoint = valueSource === 'field';
      const multiValueDataPoints = multiValues.map((val: string) =>
        findDataPoint(val, dataPoints),
      );
      if (isValueDataPoint && multiValueDataPoints.includes(undefined)) {
        validatedRules.push({
          ruleId,
          fieldMessage: validationMessageKeys.invalidValue,
          fieldType: validationFieldTypes.value,
        });
      }
    };

    validateRules(queryRules);
    return validatedRules;
  };

  const validateQuery = (query: any) => {
    // Clear form message text and invalid rules array before running validation.
    setValidationMessage('');
    setInvalidRules([]);

    // If no rules or groups have been added (empty rule)
    if (!query.rules || query.rules.length === 0) {
      setValidationMessage(validationMessageKeys.emptyRule);
      return false;
    }

    const validatedRules = validateQueryRules(query.rules);
    if (validatedRules.length > 0) {
      setValidationMessage(validationMessageKeys.incompleteRule);
      setInvalidRules(validatedRules);
      return false;
    }

    return true;
  };

  return { validateQuery, validationMessage, invalidRules };
};
