import * as React from 'react';
import {
  StatusBanner,
  StackLayout,
  ParagraphText,
  HeadingText,
  Box,
} from '@leagueplatform/genesis-core';
import { EntityFormArrayControlProps } from '../../types/controls';

export const ArrayPrimitiveGroup: React.FC<
  React.PropsWithChildren<Partial<EntityFormArrayControlProps>>
> = ({ hint, label, banner, children }) => {
  const showBanner = banner?.title || banner?.description;
  return (
    <StackLayout
      spacing="$half"
      data-testid="array-primitive-group"
      css={{ width: '100%' }}
    >
      {showBanner && (
        <StatusBanner
          status={banner.status}
          title={banner.title}
          css={{ width: '100%', marginBottom: '$half' }}
        >
          {banner.description && (
            <ParagraphText>{banner.description}</ParagraphText>
          )}
        </StatusBanner>
      )}
      <Box
        as="fieldset"
        css={{ width: '100%', border: '$none', padding: '$none' }}
      >
        <HeadingText
          size="sm"
          as="legend"
          level="display"
          emphasis="subtle"
          css={{ typography: '$label' }}
        >
          {label}
        </HeadingText>
        {hint && (
          <ParagraphText
            size="xs"
            emphasis="subtle"
            css={{ paddingTop: '$quarter' }}
          >
            {hint}
          </ParagraphText>
        )}
        <StackLayout
          as="ol"
          css={{ listStyleType: 'none', paddingInlineStart: '$none' }}
        >
          {children}
        </StackLayout>
      </Box>
    </StackLayout>
  );
};
