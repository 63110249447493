import { isProduction } from '@leagueplatform/app-environment';

// how to use - set local storage as follows in console
// // mocks on for all api paths
// localStorage.setItem("enable_all_content_authoring_api_mocks", true);
// // mocks on for one api path
// localStorage.setItem("get_all_data_points_api_mock", true);

const ENABLE_ALL_MOCKS = 'enable_all_content_authoring_api_mocks';

export enum MOCK_API_PATHS {
  GET_FLOW_CONTRACT = 'get_flow_contract',
}

export const isMockEnabled = (api_path: MOCK_API_PATHS) => {
  const isPathMockEnabled = Boolean(
    JSON.parse(`${localStorage.getItem(api_path)}`),
  );

  const isAllMocksEnabled = Boolean(
    JSON.parse(`${localStorage.getItem(ENABLE_ALL_MOCKS)}`),
  );

  if (isProduction()) {
    return false;
  }

  return isAllMocksEnabled || isPathMockEnabled;
};
