import * as React from 'react';
import { StackLayout, Button } from '@leagueplatform/genesis-core';
import { AddItemModalTriggersProps } from '../../types/components';

export const AddItemModalTriggers: React.FC<AddItemModalTriggersProps> = ({
  triggerList,
}) => (
  <StackLayout
    orientation="horizontal"
    spacing="$half"
    verticalAlignment="center"
    css={{ paddingY: '$half' }}
  >
    {triggerList.map(({ buttonLabel, openFn }) => (
      <Button
        priority="tertiary"
        size="small"
        onClick={openFn}
        key={buttonLabel}
      >
        {buttonLabel}
      </Button>
    ))}
  </StackLayout>
);
