import * as React from 'react';

export const ExpandIconSvg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M16.5 10H7.5L12 14.5L16.5 10Z" fill="currentColor" />
  </svg>
);
