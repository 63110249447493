import { ComponentType } from 'react';
import {
  withJsonFormsControlProps,
  withJsonFormsAllOfProps,
  withJsonFormsAnyOfProps,
  withJsonFormsArrayControlProps,
  withJsonFormsOneOfProps,
  withJsonFormsDetailProps,
  withJsonFormsArrayLayoutProps,
  withJsonFormsLayoutProps,
} from '@jsonforms/react';
import {
  CreateRendererProps,
  EntityFormRendererType,
} from '../types/renderers';

const rendererTypeWrapperMap: Record<
  EntityFormRendererType,
  (...args: any[]) => any
> = {
  primitive: withJsonFormsControlProps,
  object: withJsonFormsDetailProps,
  array: withJsonFormsArrayControlProps,
  anyOf: withJsonFormsAnyOfProps,
  allOf: withJsonFormsAllOfProps,
  oneOf: withJsonFormsOneOfProps,
  objectLayout: withJsonFormsLayoutProps,
  arrayLayout: withJsonFormsArrayLayoutProps,
};

export function createEntityFormRenderer<T>(
  renderer: ComponentType<CreateRendererProps<T>>,
  rendererType: EntityFormRendererType,
  memoize: boolean,
  omitPropsWrapperFn: boolean,
): ComponentType<CreateRendererProps<T>> {
  return omitPropsWrapperFn
    ? renderer
    : rendererTypeWrapperMap[rendererType](renderer, memoize);
}
