import * as React from 'react';
import { Box, StackLayout, Button } from '@leagueplatform/genesis-core';
import { JsonFormsDispatch } from '@jsonforms/react';
import {
  composePaths,
  findUISchema,
  JsonFormsUISchemaRegistryEntry,
} from '@jsonforms/core';
import { AnnotatedJsonSchema } from '@web-config-app/core';
import type {
  ArrayControl,
  EntityFormArrayControlProps,
  RelationshipValue,
  EntityReferenceControlSchema,
} from '../../../types/controls';
import { EntityFormArrayControl } from '../../entity-form-array-control/entity-form-array-control.component';
import { NumberIcon } from '../../number-icon/number-icon.component';
import { ArrayActionButtons } from '../../array-action-buttons/array-action-buttons.component';
import { CompoundPrimitiveGroupFieldset } from '../../compound-primitive-group-fieldset/compound-primitive-group-fieldset.component';
import { useEntityReferenceSchema } from '../../../hooks/use-entity-reference-schema/use-entity-reference-schema';
import { useEntityReferenceArrayItemActions } from '../../../hooks/use-entity-reference-array-item-actions/use-entity-reference-array-item-actions';

interface UseEntityReferenceItemsProps {
  schema: AnnotatedJsonSchema;
  data: string[] | undefined;
  relationshipValue: RelationshipValue[] | undefined;
  relationshipSchema: AnnotatedJsonSchema | undefined;
}

/**
 * If the reference is a string ID, we use `data`.
 * If the reference ONLY is a relationship, we use `relationshipValue`.
 * If the reference exists as both a string ID and a relationship, we use `data`.
 */

const getEntityReferenceItems = ({
  schema,
  data,
  relationshipValue,
}: UseEntityReferenceItemsProps) => {
  if (schema.type === 'string') {
    return data ?? [];
  }

  return Array.isArray(relationshipValue) ? relationshipValue : [];
};

/**
 * Renders for schema type 'array' where `schema.items` has a defined `x-entity-reference.relationship`
 */

export const ArrayEntityReferenceControlContents = ({
  data,
  schema,
  enabled,
  banner,
  hint,
  label,
  moveDown,
  moveUp,
  removeItems,
  addItem,
  arrayAddLabel,
  itemLabel,
  visuallyHiddenItemLabel,
  errors,
  path,
  uischema,
  uischemas,
  renderers,
  cells,
  rootSchema,
  ...props
}: EntityFormArrayControlProps) => {
  const {
    value,
    relationshipDataSchema,
    removeRelationshipItem,
    addRelationshipItem,
    moveRelationshipItemUp,
    moveRelationshipItemDown,
  } = useEntityReferenceSchema({
    schema: schema as EntityReferenceControlSchema,
  });
  const items = getEntityReferenceItems({
    schema,
    data,
    relationshipValue: value as RelationshipValue[],
    relationshipSchema: relationshipDataSchema,
  });

  const { handleMoveUp, handleMoveDown, handleAddItem, handleRemoveItem } =
    useEntityReferenceArrayItemActions({
      schema,
      relationshipDataSchema,
      addItem,
      moveUp,
      moveDown,
      removeItems,
      addRelationshipItem,
      removeRelationshipItem,
      moveRelationshipItemUp,
      moveRelationshipItemDown,
    });

  const childUiSchema = React.useMemo(
    () =>
      findUISchema(
        uischemas as JsonFormsUISchemaRegistryEntry[],
        schema,
        uischema.scope,
        path,
        undefined,
        uischema,
        rootSchema,
      ),
    [uischemas, schema, path, uischema, rootSchema],
  );

  return (
    <CompoundPrimitiveGroupFieldset
      label={label}
      banner={banner}
      hint={hint}
      error={errors}
    >
      <StackLayout
        as="ol"
        aria-label={label}
        css={{
          marginTop: '$threeQuarters',
          marginBlockStart: '$none',
          paddingInlineStart: '$none',
          border: `${
            items.length > 0
              ? `$borderWidths$thin solid $onSurfaceBorderSubdued`
              : `none`
          }`,
          borderRadius: '$medium',
          listStyleType: 'none',
        }}
        horizontalAlignment="stretch"
      >
        {items.map((item: any, index: number) => {
          const listItemNumber = index + 1;
          const lastItem = items.length === listItemNumber;
          const childPath = composePaths(path, `${index}`);
          const uniqueId = `${props.id}-${index}`;

          return (
            <StackLayout
              as="li"
              orientation="horizontal"
              verticalAlignment="center"
              horizontalAlignment="stretch"
              key={uniqueId}
              css={{
                width: '100%',
                flexGrow: 1,
                padding: '$half',
                marginTop: 0,
                borderBottom: `${
                  lastItem
                    ? 'none'
                    : '$borderWidths$thin solid $onSurfaceBorderSubdued'
                }`,
                '&:first-of-type': {
                  borderRadius: '$medium $medium 0 0',
                },
                '&:last-of-type': {
                  borderRadius: '0 0 $medium $medium',
                },
                '&:first-of-type:last-of-type': {
                  borderRadius: '$medium',
                },
                backgroundColor: '$surfaceBackgroundPrimary',
              }}
              aria-label={itemLabel}
            >
              <Box css={{ flexGrow: 0 }}>
                <NumberIcon
                  number={listItemNumber}
                  css={{ margin: 'auto $half' }}
                />
              </Box>

              <Box
                as="fieldset"
                css={{
                  paddingX: '$two',
                  borderWidth: '0',
                  flexGrow: 1,
                }}
              >
                <JsonFormsDispatch
                  enabled={enabled}
                  schema={schema}
                  uischema={childUiSchema}
                  path={childPath}
                  key={childPath}
                  renderers={renderers}
                  cells={cells}
                />
              </Box>

              <Box css={{ flexGrow: 0 }}>
                <ArrayActionButtons
                  showDeleteModal
                  onDelete={() => handleRemoveItem(item, path, index)}
                  onMoveDown={() => handleMoveDown(item, path, index)}
                  onMoveUp={() => handleMoveUp(item, path, index)}
                  itemLabel={itemLabel as string}
                  itemIndex={index}
                  arrayLength={items.length}
                />
              </Box>
            </StackLayout>
          );
        })}
      </StackLayout>

      <Button onClick={() => handleAddItem(path)}>{arrayAddLabel}</Button>
    </CompoundPrimitiveGroupFieldset>
  );
};

const renderArrayControl = (formContentsProps: EntityFormArrayControlProps) => (
  <ArrayEntityReferenceControlContents {...formContentsProps} />
);

export const ArrayEntityReferenceControl: ArrayControl = (props) => (
  <EntityFormArrayControl {...props} renderControl={renderArrayControl} />
);
