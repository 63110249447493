import React from 'react';
import {
  StackLayout,
  Popover,
  UtilityText,
  Button,
  StackItem,
} from '@leagueplatform/genesis-core';
import { IconButton } from '../icon-button/icon-button.component';

type UserProfilePopoverProps = {
  triggerLabel: string;
  userName: string;
  userEmail: string;
  ctaLabel: string;
  ctaAction: () => void;
};

export const UserProfilePopover = ({
  triggerLabel,
  userName,
  userEmail,
  ctaLabel,
  ctaAction,
}: UserProfilePopoverProps) => (
  <Popover.Root>
    <Popover.Trigger>
      <IconButton icon="illustrativePersonalInfo" label={triggerLabel} />
    </Popover.Trigger>
    <Popover.Content
      align="end"
      side="bottom"
      css={{ width: '375px' }}
      showCloseButton={false}
    >
      <StackLayout horizontalAlignment="start">
        <StackItem css={{ paddingLeft: '$threeQuarters' }}>
          <UtilityText size="sm">{userName}</UtilityText>
          <UtilityText size="sm">{userEmail}</UtilityText>
        </StackItem>
        <Button
          width="fillContainer"
          quiet
          priority="tertiary"
          size="toolbar"
          css={{
            marginTop: '$oneAndHalf',
            justifyContent: 'start',
            fontSize: '$bodyTwo',
            fontWeight: '$bodyTwo',
            padding: '$threeQuarters',
            '&:hover': { backgroundColor: '$inputBackgroundHovered' },
            '&:active': { backgroundColor: '$inputBackgroundPressed' },
          }}
          onClick={ctaAction}
        >
          {ctaLabel}
        </Button>
      </StackLayout>
    </Popover.Content>
  </Popover.Root>
);
