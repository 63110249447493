import { useMemo } from 'react';
import { Generate } from '@jsonforms/core';
import { EntityFormControlProps } from '../../types/controls';

export type UseCombinatorSchemasProps = Pick<
  EntityFormControlProps,
  'combinatorProperties' | 'rootSchema' | 'data'
>;

export const useCombinatorSchemas = ({
  combinatorProperties,
  rootSchema,
  data,
}: UseCombinatorSchemasProps) =>
  useMemo(() => {
    if (!combinatorProperties) {
      return {};
    }

    const { combinatorSubSchemaMap, combinatorDiscriminator } =
      combinatorProperties;
    const discriminatorValue = data?.[combinatorDiscriminator];
    const selectedSubSchema = combinatorSubSchemaMap[discriminatorValue];

    if (discriminatorValue && !selectedSubSchema) {
      console.error(
        `No subschema associated with option ${discriminatorValue}`,
      );
    }

    return {
      selectedSubSchema,
      detailUiSchema: Generate.uiSchema(
        selectedSubSchema,
        'Group',
        undefined,
        rootSchema,
      ),
    };
  }, [combinatorProperties, data, rootSchema]);
