import * as React from 'react';
import { useAuth } from '@leagueplatform/auth-utils';
import { useGetPermissions } from '@leagueplatform/permissions';
import { useFilterEntitiesBasedOnPermissions } from '@web-config-app/core-react';
import { AuthViewLogout } from '../../pages/auth/Auth.pages';
import { LoadingIndicator } from '../loading-indicator.component';

export const AuthorizedRoutes = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { isAuthenticated, error: authError } = useAuth({
    redirectToLogInWhenSignedOut: true,
  });
  const { status: permissionsRequestStatus } = useGetPermissions();

  useFilterEntitiesBasedOnPermissions({
    isAuthenticated,
    permissionsRequestStatus,
  });

  if (permissionsRequestStatus === 'error') {
    throw new Error('Unable to access user permissions');
  }

  if (authError) {
    return <AuthViewLogout />;
  }

  if (!isAuthenticated || permissionsRequestStatus === 'loading')
    return <LoadingIndicator />;

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
