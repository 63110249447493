import { CategoryType } from '../types/configuration';
import { Category } from '../constants';

const categoryMap: Record<string, CategoryType> = {
  [Category.Default]: Category.Default,
  [Category.Hidden]: Category.Hidden,
};

export const mapStringToCategoryType = (category: string): CategoryType =>
  categoryMap[category] ?? Category.Default; // Return default if no match is found
