import { JsonSchema7 } from '@jsonforms/core';

export const getLimitValue = (
  limitType: 'max' | 'min',
  value?: number,
  exclusiveValue?: number,
  step = 1,
) => {
  /*
  In JsonSchema7, exclusiveValue is a number | undefined. 
  If exclusiveValue is a number, we calculate the limit value by
  subtracting the `step` from the `exclusiveValue` (in the case of max), 
  or adding the `step` to the `exclusiveValue` (in the case of min).
  */
  if (typeof exclusiveValue === 'number') {
    return limitType === 'max' ? exclusiveValue - step : exclusiveValue + step;
  }

  // if exlusiveValue is false or undefined, we just return value
  return value;
};

/**
 * 
 *  In JsonSchema version 7, 'exclusiveMaximum' and 'exclusiveMinimum' are numbers. If `exclusiveMaximum` is 10, then
 the true maximum is 9. However, if `exlusiveMaximum` is not defined and `maximum` is 10, then the max is 10.
 * This function, in conjunction with the `getLimitValue` helper, gets the appropriate minimum and maximum
 taking into account the different potential options for exlusiveMaximum and exclusiveMinimum
 */
export const getMaxAndMinFromSchema = (schema: JsonSchema7) => {
  const { maximum, minimum, exclusiveMaximum, exclusiveMinimum, multipleOf } =
    schema;

  return {
    minimum: getLimitValue('min', minimum, exclusiveMinimum, multipleOf),
    maximum: getLimitValue('max', maximum, exclusiveMaximum, multipleOf),
  };
};
