export const EN_STRING_NODE_OVERRIDES = {
  NODES: 'Nodes',
  NODE_ENTITY_NAME: 'Node',
  NODE_DETAILS_ENTITY_LABEL: 'Node details',
  NODE_DETAILS_ENTITY_HINT:
    'The details section is where you can set some primary information about a node that makes up and powers the widget or page you would like to configure. The content in this section is not visible to end users.',
  NODE_DETAILS_NODE_ID_ENTITY_LABEL: 'Node ID',
  NODE_DETAILS_NODE_ID_ENTITY_HINT:
    'This will not be visible to end user and it should be unique',
  NODE_DETAILS_GROUPING_ENTITY_LABEL: 'Node grouping',
  NODE_DETAILS_GROUPING_ENTITY_HINT:
    'Select which capability this node should belong to. This will be used to group it under the right domain.',
  NODE_DETAILS_IS_NEW_GROUPING_ENTITY_LABEL: 'Create new grouping',
  NODE_DETAILS_NEW_GROUPING_ENTITY_LABEL: 'New grouping',
  NODE_DETAILS_NEW_GROUPING_ENTITY_HINT:
    'Set which domain you’d like to add this node under. If you want to set an additional level, separate each level with a $ symbol. Note that capitalization will affect the grouping. e.g., MyCapability$Subgroup',
  NODE_GROUPING_HOMESCREEN_OPTION: 'Homescreen',
  NODE_GROUPING_ABOUT_ME_OVERVIEW_OPTION: 'About me overview',
  NODE_GROUPING_ABOUT_ME_BUILD_YOUR_HEALTH_PROFILE_OPTION:
    'About me build your health profile',
  NODE_GROUPING_ABOUT_ME_RECENT_ACHIEVEMENTS_OPTION:
    'About me recent achievements',
  NODE_GROUPING_GET_CARE_LANDING_PAGE_OPTION: 'Get acre landing page',
  NODE_GROUPING_GET_CARE_OPTIONS_OPTION: 'Get care options',
  NODE_GROUPING_CREATE_NEW_OPTION: 'Create new grouping',
  NODE_DETAILS_NODE_NAME_ENTITY_LABEL: 'Node Name',
  NODE_DETAILS_NODE_NAME_ENTITY_HINT:
    'This will not be visible to end user and it should be unique enough to easily reference this node',
  NODE_DETAILS_NODE_DESCRIPTION_ENTITY_LABEL: 'Node Description',
  NODE_DETAILS_NODE_DESCRIPTION_ENTITY_HINT:
    'The node description can be used to further differentiate similar nodes',
  NODE_DETAILS_OBSERVABILITY_BANNER_TITLE: 'Observability',
  // @TODO - Add grafana link to this translation string when CACT will support it - CACT-1326
  NODE_DETAILS_OBSERVABILITY_BANNER_DESCRIPTION:
    'If you want to view observability metrics for this node, you can see those on the Grafana dashboard.',
  NODE_DETAILS_MONITORING_DETAILS_ENTITY_LABEL: 'Monitoring details',
  NODE_DETAILS_NODE_OWNER_ENTITY_LABEL: 'Node Owner',
  NODE_DETAILS_NODE_OWNER_ENTITY_HINT:
    'This should reflect the team who should be notified with observation data about this node.',
  MONITORING_TIER_OPTION_REGULAR: 'Regular',
  MONITORING_TIER_OPTION_BEST_EFFORT: 'Best-effort',
  MONITORING_TIER_OPTION_OFFLINE: 'Offline',
  NODE_DETAILS_MONITORING_TIER_ENTITY_LABEL: 'Monitoring Tier',
  NODE_DETAILS_MONITORING_TIER_ENTITY_HINT:
    'The tier is related to our SLOs. We have multiple levels of SLO. Each one of these have a threshold for errors or latency before an alert is sent.',
  NODE_DETAILS_ENABLE_CACHING_ENTITY_LABEL: 'Enable caching for this node',
  NODE_DETAILS_CACHING_ENTITY_LABEL: 'Caching',
  NODE_DETAILS_CACHING_ENTITY_HINT:
    "Caching stores a copy of the content on the server so it doesn't need to be fetched from the source every time. Choose a longer duration for content that doesn't change often.",
  NODE_DETAILS_CACHING_STRATEGY_LABEL: 'Caching strategy',
  NODE_DETAILS_CACHING_ENTITY_ADD_BUTTON_TEXT: 'Add another caching strategy',
  NODE_DETAILS_TIME_TO_LIVE_VALUE_LABEL: 'Time to live value',
  NODE_DETAILS_TIME_TO_LIVE_DURATION_LABEL: 'Time to live duration',
};
