import * as React from 'react';

export const EditDataIcon = () => (
  <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path
      d="m11.55,9.75c5.96,0,10.8-2.01,10.8-4.5S17.51.75,11.55.75.75,2.76.75,5.25s4.84,4.5,10.8,4.5Z"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="m22.35,9.01v-3.76"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="m.75,5.25v6c0,2.37,4.41,4.32,10,4.49"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="m.75,11.25v6c0,2.08,3.39,3.83,8,4.35"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <path
      d="m21.75,14.87l-7.32,7.63-3.6.75.72-3.75,7.32-7.63c.79-.83,2.08-.83,2.87,0h0c.38.41.59.94.59,1.51,0,.56-.21,1.1-.6,1.5Z"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);
