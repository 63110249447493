import { getConstantOrDefaultValueFromSchema } from '@web-config-app/schema-utils';
import type { AnnotatedJsonSchema, TranslationKey } from '@web-config-app/core';
import type {
  EntityFormControlAnnotationProps,
  Translate,
} from '../../types/controls';

const getPresentationAnnotations = (schema: AnnotatedJsonSchema) =>
  schema['x-entity-presentation'] ?? {};

const getCombinatorSubSchemas = ({
  items,
  oneOf,
  anyOf,
}: AnnotatedJsonSchema): {
  combinatorSubSchemas?: AnnotatedJsonSchema[];
} => {
  if (items && !Array.isArray(items)) {
    return getCombinatorSubSchemas(items);
  }
  return {
    combinatorSubSchemas: oneOf ?? anyOf,
  };
};

export function getCombinatorProperties(
  schema: AnnotatedJsonSchema,
  translate: Translate = (id?: TranslationKey) => id,
): EntityFormControlAnnotationProps['combinatorProperties'] | undefined {
  const { discriminator } = schema;
  const { combinatorSubSchemas } = getCombinatorSubSchemas(schema);

  if (!discriminator || !combinatorSubSchemas) {
    return undefined;
  }

  // get discriminator property from the schema
  const combinatorDiscriminator = discriminator.propertyName;

  // map over the subSchema options to generate value/label options that match the genesis core style
  const combinatorSelectOptions = combinatorSubSchemas
    .filter((value) => value?.['x-entity-presentation']?.hidden !== true)
    ?.map((subSchema) => {
      const discrimOption = subSchema?.properties?.[combinatorDiscriminator];
      return {
        value: getConstantOrDefaultValueFromSchema({
          const: discrimOption?.const,
          enum: discrimOption?.enum,
        }) as string, // TODO: resolve type casting if possible
        label: translate(discrimOption?.['x-entity-label']?.key),
      };
    });

  const { hint: discriminatorHint, banner: discriminatorBanner } =
    getPresentationAnnotations(discriminator);

  const combinatorSubSchemaMap: {
    [discriminator: string]: AnnotatedJsonSchema;
  } = {};
  if (Array.isArray(combinatorSubSchemas)) {
    combinatorSubSchemas.forEach((subSchema) => {
      const discriminatingSchema =
        subSchema.properties?.[combinatorDiscriminator];
      const discriminatorProperty = getConstantOrDefaultValueFromSchema({
        const: discriminatingSchema?.const,
        enum: discriminatingSchema?.enum,
      });
      if (typeof discriminatorProperty === 'string') {
        combinatorSubSchemaMap[discriminatorProperty] = subSchema;
      }
    });
  }

  return {
    combinatorDiscriminator,
    combinatorSelectOptions,
    combinatorSubSchemas,
    combinatorSubSchemaMap,
    combinatorPlaceholder: translate(
      discriminator['x-entity-presentation']?.enumLabels
        ?.enumControlPlaceholder,
    ),
    combinatorInputWrapperOptions: {
      label: translate(discriminator['x-entity-label']?.key),
      hint: translate(discriminatorHint),
      banner: discriminatorBanner && {
        ...discriminatorBanner,
        title: translate(discriminatorBanner.title),
        description: translate(discriminatorBanner.description),
      },
    },
  };
}
