import { FlowContractResponseData } from '../types/flow-contract.types';

export const mockFlowContractResponse: FlowContractResponseData = [
  {
    dataHandlers: [
      { description: 'LPF Data', id: 'league_lpf_data_handler', version: '1' },
    ],
    dataPoints: [
      {
        dataHandlerId: 'league_lpf_data_handler',
        dataPoints: [
          {
            dataHandlerId: 'league_lpf_data_handler',
            dataPoints: [],
            dataType: 'string',
            description: 'Country of primary residence',
            id: 'patient.home_country',
            label: 'Country',
          },
          {
            dataHandlerId: 'league_lpf_data_handler',
            dataPoints: [],
            dataType: 'string',
            description: 'State/province of primary residence',
            id: 'patient.home_state',
            label: 'State',
          },
          {
            dataHandlerId: 'league_lpf_data_handler',
            dataPoints: [],
            dataType: 'datetime',
            description: 'Date of birth',
            id: 'patient.birth_date',
            label: 'Birth Date',
          },
          {
            dataHandlerId: 'league_lpf_data_handler',
            dataType: 'object',
            dataPoints: [
              {
                dataHandlerId: 'league_lpf_data_handler',
                dataPoints: [],
                dataType: 'number',
                description: 'Latest Daily Energy Burned (kcal)',
                id: 'patient.stats.latest_daily_energy_burned_kcal',
                label: 'Energy Burned',
              },
            ],
            description: '',
            id: 'patient.stats',
            label: 'patient stats',
          },
        ],
        dataType: 'object',
        description: '',
        id: 'patient',
        label: 'patient',
      },
      {
        dataHandlerId: 'league_lpf_data_handler',
        dataPoints: [
          {
            dataHandlerId: 'league_lpf_data_handler',
            dataPoints: [
              {
                dataHandlerId: 'league_lpf_data_handler',
                dataPoints: [],
                dataType: 'string',
                description: '',
                id: 'user.profile.last_name',
                label: 'last name',
              },
              {
                dataHandlerId: 'league_lpf_data_handler',
                dataPoints: [],
                dataType: 'string',
                description: '',
                id: 'user.profile.first_name',
                label: 'first name',
              },
              {
                dataHandlerId: 'league_lpf_data_handler',
                dataPoints: [],
                dataType: 'datetime',
                description: 'Date of birth',
                id: 'user.profile.birth_date',
                label: 'date of birth',
              },
            ],
            dataType: 'object',
            description: '',
            id: 'user.profile',
            label: 'user profile',
          },
          {
            dataHandlerId: 'league_lpf_data_handler',
            dataPoints: [
              {
                dataHandlerId: 'league_lpf_data_handler',
                dataPoints: [],
                dataType: 'datetime',
                description: '',
                id: 'user.history.last_doctor',
                label: 'last family doctor visit',
              },
              {
                dataHandlerId: 'league_lpf_data_handler',
                dataPoints: [],
                dataType: 'datetime',
                description: '',
                id: 'user.history.last_dentist',
                label: 'last dentist visit',
              },
              {
                dataHandlerId: 'league_lpf_data_handler',
                dataPoints: [],
                dataType: 'datetime',
                description: '',
                id: 'user.history.last_specialist',
                label: 'last specialist visit',
              },
            ],
            dataType: 'object',
            description: '',
            id: 'user.history',
            label: 'user history',
          },
        ],
        dataType: 'object',
        description: '',
        id: 'user',
        label: 'user info',
      },
    ],
    description:
      'Flows that can be configured on campaigns product entity to control which activities user gets assigned, etc.',
    id: 'health_campaigns_lfrs_flow',
  },
];
