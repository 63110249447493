import * as React from 'react';
import {
  StackLayout,
  Box,
  TextAction,
  UtilityText,
  Button,
} from '@leagueplatform/genesis-core';
import {
  Entity,
  EntityDetail,
  getEntityInstanceName,
} from '@web-config-app/core';
import { useIntl } from '@leagueplatform/locales';
import { EntityStatusBadge } from './entity-status-badge.component';

export interface EntityReferenceValueProps {
  entityDetail: EntityDetail;
  entity: Entity;
  openChooseFromList: () => void;
}

export const EntityReferenceValue: React.FC<EntityReferenceValueProps> = ({
  entityDetail,
  entity,
  openChooseFromList,
}) => {
  const { formatMessage } = useIntl();
  /**
   * TODO: If we stick to our intensions here, the below URL should be customizable to not be specific
   * to our config app routing. To do this properly, it would have to be some kind of function
   * param to pass to configuration. That feels like a lot of work for now, so instead let's file a
   * ticket and deal with it when we get back to extensibility.
   *
   * https://everlong.atlassian.net/browse/CACT-1035
   */
  const entityUrl = `/domains/${entity.domain}/${entity.id}/${entityDetail.id}`;
  return (
    <StackLayout
      orientation="horizontal"
      horizontalAlignment="start"
      verticalAlignment="center"
      spacing="$one"
      css={{ paddingY: '$half', width: '100%' }}
    >
      <Box css={{ flexGrow: 1 }}>
        <TextAction indicator="newTabInternal" target="_blank" href={entityUrl}>
          {getEntityInstanceName(entityDetail, entity)}
        </TextAction>
      </Box>
      <UtilityText emphasis="subtle">{entityDetail.id}</UtilityText>
      <EntityStatusBadge
        status={entityDetail.attributes.entityMetadata.status}
      />
      <Button
        size="medium"
        priority="secondary"
        quiet
        onClick={() => openChooseFromList()}
        icon="interfaceChange"
        hideLabel
      >
        {formatMessage({ id: 'CHANGE_SELECTION' })}
      </Button>
    </StackLayout>
  );
};
