import { useCallback, useMemo } from 'react';
import type { EntityRootData, AnnotatedJsonSchema } from '@web-config-app/core';

function checkRelationshipExists(
  schema: AnnotatedJsonSchema,
  relationship: string,
) {
  if (!schema?.properties?.relationships?.properties?.[relationship]) {
    // eslint-disable-next-line no-console
    console.error(`Relationship ${relationship} not defined in schema`);
  }
}

interface UseRelationshipsArgs {
  rootData: EntityRootData | undefined;
  onRelationshipChange: (data?: any) => void;
  schema: AnnotatedJsonSchema;
}

export const useRelationshipsData = ({
  rootData,
  onRelationshipChange,
  schema,
}: UseRelationshipsArgs) => {
  const relationships = useMemo(
    () => rootData?.relationships ?? {},
    [rootData],
  );

  const setRelationships = useCallback(
    (relationship: string, value: any) => {
      checkRelationshipExists(schema, relationship);
      if (relationships) {
        onRelationshipChange({
          ...relationships,
          [relationship]: { data: value },
        });
      }
    },
    [onRelationshipChange, schema, relationships],
  );

  const getRelationship = useCallback(
    (relationship: string) => {
      checkRelationshipExists(schema, relationship);
      return relationships?.[relationship]?.data;
    },
    [schema, relationships],
  );

  return {
    getRelationship,
    setRelationships,
  };
};

export type UseRelationshipsDataResult = ReturnType<
  typeof useRelationshipsData
>;
