import React, { useState, useRef, ChangeEvent } from 'react';
import {
  styled,
  StackLayout,
  UtilityText,
  Box,
  TextAction,
  focusOutlineOuter,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { formatBytes } from './format-bytes.util';

const isFileSizeValid = (fileObject: File, maxSize: number) => {
  const fileSize = fileObject.size;
  const numberMaxSize = Number(maxSize);

  return !Number.isNaN(numberMaxSize) && fileSize <= numberMaxSize;
};

const isFileTypeValid = (fileObject: File, accepts: string) => {
  const fileExtension = fileObject.name.split('.').pop();
  return (
    !accepts ||
    accepts.toLowerCase().includes(`.${fileExtension?.toLowerCase()}`)
  );
};

const DEFAULT_MAX_SIZE = 1024 ** 2 * 5;
const DEFAULT_MAX_NAME_LENGTH = 256;

type FileUploadProps = {
  file: File | null;
  onSelect: (file: File) => void;
  onError: (error: Error | null) => void;
  accepts?: string;
  maxSize?: number; // size in Bytes
  maxNameLength?: number;
  disabled?: boolean;
};

const FileUploadArea = styled(StackLayout, {
  backgroundColor: '$surfaceBackgroundSecondary',
  padding: '$twoAndHalf',
  borderWidth: 'thin',
  borderStyle: 'dashed',
  borderColor: '$onSurfaceBorderSubdued',
  borderRadius: '$medium',
});

export const FileUpload = ({
  file,
  onSelect,
  onError,
  accepts = '.pdf,.png,.jpeg,.jpg',
  maxSize = DEFAULT_MAX_SIZE,
  maxNameLength = DEFAULT_MAX_NAME_LENGTH,
  disabled,
}: FileUploadProps) => {
  const { formatMessage } = useIntl();
  const inputRef = useRef<HTMLInputElement>(null);
  const clearRef = useRef<HTMLButtonElement>(null);

  React.useEffect(() => {
    if (file) {
      clearRef.current?.focus();
    } else {
      inputRef.current?.focus();
    }
  }, [file]);

  const [isDragging, setIsDragging] = useState(false);

  const trimFileName = (fileObject: File) => {
    let finalFile = fileObject;
    if (fileObject.name.length > maxNameLength) {
      // slice file name
      finalFile = new File(
        [fileObject],
        fileObject.name.slice(0, maxNameLength),
        { type: fileObject.type },
      );
    }
    return finalFile;
  };

  const isFileValid = (fileObject: File) => {
    if (!isFileTypeValid(fileObject, accepts)) {
      onError({
        name: 'fileType',
        message: formatMessage(
          { id: 'FILE_TYPE_NOT_SUPPORTED' },
          {
            fileType: fileObject.type,
            allowedFileTypes: accepts,
          },
        ),
      });
      return false;
    }

    if (!isFileSizeValid(fileObject, maxSize)) {
      onError({
        name: 'fileSize',
        message: formatMessage(
          { id: 'FILE_TOO_LARGE_ERROR' },
          {
            maxSize: formatBytes(maxSize),
            currentSize: formatBytes(fileObject.size),
          },
        ),
      });
      return false;
    }

    return true;
  };

  const handleDragOver = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    onError(null);
    if (!isDragging) setIsDragging(true);
  };

  const handleDragLeave = () => setIsDragging(false);

  const handleDragDrop = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    setIsDragging(false);

    const fileReference = e?.dataTransfer?.items[0];
    const fileObject = fileReference?.getAsFile();

    if (fileObject && isFileValid(fileObject)) {
      const finalFile = trimFileName(fileObject);
      onSelect(finalFile);
      if (inputRef.current) {
        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(finalFile);
        inputRef.current.files = dataTransfer.files;
      }
    }
  };

  const handleFileSearch = (e: ChangeEvent<HTMLInputElement>) => {
    if (e?.currentTarget?.files) {
      onError(null);
      const fileObject = e?.currentTarget?.files[0];

      if (fileObject && isFileValid(fileObject)) {
        const finalFile = trimFileName(fileObject);
        onSelect(finalFile);
      }
    }
  };

  return (
    <FileUploadArea
      css={{
        opacity: isDragging ? 0.4 : 1,
      }}
      horizontalAlignment="center"
      verticalAlignment="center"
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDragDrop}
      data-testid="dropzone"
    >
      <Box
        as="input"
        css={{
          width: '0.1px',
          height: '0.1px',
          opacity: 0,
          overflow: 'hidden',
          position: 'absolute',
          zIndex: -1,
          // some funky selectors to add focus outline to the label for the 2 scenarios
          '&:focus ~ label': focusOutlineOuter,
          '&:focus + * > label': focusOutlineOuter,
        }}
        ref={inputRef}
        type="file"
        accept={accepts}
        onChange={handleFileSearch}
        aria-describedby="file_upload_description"
        id="file_upload"
        disabled={disabled}
        required
      />
      {file ? (
        <StackLayout
          orientation="horizontal"
          horizontalAlignment="spaceBetween"
          spacing="$one"
          css={{ width: '100%' }}
        >
          <UtilityText
            css={{
              wordBreak: 'break-all',
            }}
          >
            {file.name}
          </UtilityText>
          <TextAction
            as="label"
            css={{
              flexShrink: 0,
              borderRadius: '$button',
            }}
            htmlFor="file_upload"
          >
            {formatMessage({ id: 'CHANGE_IMAGE' })}
          </TextAction>
        </StackLayout>
      ) : (
        <UtilityText
          as="label"
          htmlFor="file_upload"
          css={{
            borderRadius: '$button',
          }}
        >
          {formatMessage({ id: 'DRAG_FILES_HERE_OR' })}
          <TextAction as="span" css={{ marginLeft: '$quarter' }}>
            {formatMessage({ id: 'BROWSE_FILES' })}
          </TextAction>
        </UtilityText>
      )}
    </FileUploadArea>
  );
};
