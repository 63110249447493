import { useIntl } from '@leagueplatform/locales';
import type { ConfigErrorType } from '@web-config-app/core';
import { useConfigUserFeedback } from '../../state/config-user-feedback-state/config-user-feedback-state';

/**
 *
 *
 */

export const useConfigError = () => {
  const { formatMessage } = useIntl();

  const { hasConfigError, errorType, configErrors } = useConfigUserFeedback(
    (state) => ({
      hasConfigError: state.configError !== null,
      errorType: state.configError?.type,
      configErrors: state.configError?.errors,
    }),
  );
  const errorArrayAmount = configErrors?.length;

  const bannerTitle: Record<ConfigErrorType, string> = {
    'server-error-response': 'SERVER_ERROR',
    'client-error-response': 'CONFIGURATION_ERROR',
    'form-validation-error': 'CONFIGURATION_ERROR',
  };

  // Get the banner title based on errorType, defaulting to 'ERROR'
  const errorBannerTitle = formatMessage(
    {
      id: errorType ? bannerTitle[errorType] : 'ERROR',
    },
    { count: errorArrayAmount },
  );
  // Get the banner contents based on errorType, the default is an empty array
  const errorBannerContents =
    errorType === 'server-error-response'
      ? {
          errorMessage: configErrors?.[0]?.detail || '',
        }
      : { errorList: configErrors || [] };
  return {
    hasConfigError,
    errorBannerContents,
    errorBannerTitle,
  };
};
