import React from 'react';
import { Icon, StackLayout } from '@leagueplatform/genesis-core';

export const StatusIconMatch = ({
  messageKey,
  showIcon,
}: {
  messageKey: string;
  showIcon: boolean;
}) => (
  <StackLayout horizontalAlignment="center" orientation="horizontal">
    {showIcon && (
      <Icon
        icon="tinyValidationCheck"
        css={{ paddingRight: '$quarter', color: '$successIcon' }}
      />
    )}
    {messageKey}
  </StackLayout>
);
