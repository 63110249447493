import * as React from 'react';
import { Tooltip } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { EntityDetailsConfirmationModal } from '@web-config-app/core-react-ui';
import { EntityMenuItemText } from '../entity-menu-item-text/entity-menu-item-text.component';

interface EntityMenuItemProps {
  disabled?: boolean;
  label: string;
  displayAsModal?: boolean;
  entityConfigName?: string;
  entityListPath: string;
  tooltipContent?: string;
}

export const EntityMenuItem: React.FC<EntityMenuItemProps> = ({
  disabled,
  label,
  displayAsModal,
  entityConfigName,
  entityListPath,
  tooltipContent,
}) => {
  const { formatMessage } = useIntl();
  if (displayAsModal) {
    return (
      <EntityDetailsConfirmationModal
        modalTrigger={
          <EntityMenuItemText
            disabled={disabled}
            label={label}
            color="$onSurfaceTextCritical"
          />
        }
        title={formatMessage(
          { id: 'EXIT_ENTITY_DETAIL_TITLE' },
          { name: entityConfigName },
        )}
        description={formatMessage({
          id: 'EXIT_ENTITY_DETAIL_DESCRIPTION',
        })}
        primaryButtonText={formatMessage({
          id: 'EXIT_ENTITY_DETAIL_PRIMARY_CTA',
        })}
        primaryButtonPath={entityListPath}
      />
    );
  }
  return disabled && tooltipContent ? (
    <Tooltip
      align="center"
      avoidCollisions
      content={tooltipContent}
      side="left"
      sideOffset={30}
    >
      <EntityMenuItemText disabled={disabled} label={label} />
    </Tooltip>
  ) : (
    <EntityMenuItemText disabled={disabled} label={label} />
  );
};
