import type { AnnotatedJsonSchema } from '@web-config-app/core';
import { isPrimitiveArraySchema } from '../is-primitive-array-schema/is-primitive-array-schema';
import { isPrimitiveObjectSchema } from '../is-primitive-object-schema/is-primitive-object-schema';

export const isPrimitiveArrayOrPrimitiveObjectSchema = (
  schema: AnnotatedJsonSchema,
) => {
  switch (schema.type) {
    case 'object':
      return isPrimitiveObjectSchema(schema);
    case 'array':
      return isPrimitiveArraySchema(schema);
    default:
      return false;
  }
};
