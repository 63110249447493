import * as React from 'react';
import { Accordion } from '@leagueplatform/web-common-components';
import { Icon, StackLayout } from '@leagueplatform/genesis-core';
import { ChevronRightFilledIcon } from '../../assets/chevron-right-filled-icon';
import { AccordionSingleItemProps } from '../../types/components';

/**
 * AccordionSingleItem is built with the web-commons-component accordion that uses Radix UI's Accordion and follows the same anatomy and API.
 * This renders a single collapsible accordion that must be rendered within Accordion.Root
 *
 * The AccordionSingleItem accepts optional additional actions that are rendered outside of the Accordion Trigger on the right.
 *
 * A string or an element can be passed as the heading incase additional markup is needed
 *
 */

export const AccordionSingleItem: React.FC<
  React.PropsWithChildren<AccordionSingleItemProps>
> = ({
  accordionHeading,
  id,
  children,
  additionalActions,
  headingLevel = 3,
}) => (
  <Accordion.Item value={id} data-testid="accordion-item">
    <StackLayout
      orientation="horizontal"
      horizontalAlignment="spaceBetween"
      verticalAlignment="center"
    >
      <Accordion.Header
        level={headingLevel}
        size="xs"
        css={{
          flexGrow: '1',
          marginRight: '$one',
          width: '100%',
        }}
      >
        <Accordion.Trigger
          css={{
            '.cc-accordion-trigger-icon': {
              display: 'none',
            },
            '.object-accordion-trigger-icon': {
              transition: 'transform 200ms',
            },
            '&[aria-expanded=true] .object-accordion-trigger-icon': {
              transform: 'rotate(90deg)',
            },
          }}
        >
          <StackLayout orientation="horizontal" verticalAlignment="center">
            <Icon
              css={{
                display: 'flex',
                marginX: '$half',
                color: '$onSurfaceIconPrimary',
              }}
              className="object-accordion-trigger-icon"
              size="0.5rem"
              icon={<ChevronRightFilledIcon />}
            />
            {accordionHeading}
          </StackLayout>
        </Accordion.Trigger>
      </Accordion.Header>
      {additionalActions}
    </StackLayout>
    <Accordion.Content data-testid="accordion-content">
      {children}
    </Accordion.Content>
  </Accordion.Item>
);
