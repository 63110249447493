import { FlowContract } from '@web-config-app/rule-builder-api';
import { useGetFlowContract } from './use-get-flow-contract.hook';

// At this time (July 2023), we're only supporting the Health Campaigns LFRS flow.
//
// When a flow selector is implemented for the rule builder, this hook should filter
// based on the user's selection instead of a static value.
//
// See this Slack thread for more context:
// https://everlong.slack.com/archives/C056BG2G33R/p1689622974369209

export const HEALTH_CAMPAIGNS_LFRS_FLOW_ID = 'health_campaigns_lfrs_flow';

export const useGetSelectedFlow = (
  selectedFlowId = HEALTH_CAMPAIGNS_LFRS_FLOW_ID,
) => {
  const { data, isError, isLoading } = useGetFlowContract();

  const selectedFlow: FlowContract | undefined =
    data?.find((flow) => flow.id === selectedFlowId) || undefined;

  return { isError, isLoading, selectedFlow };
};
