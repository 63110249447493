import * as React from 'react';
import { GroupLayout } from '@jsonforms/core';
import { Box } from '@leagueplatform/genesis-core';
import { EntityFormLayout } from '../../components/entity-form-layout/entity-form-layout.component';
import { CompoundComplexHeading } from '../../components/compound-complex-heading/compound-complex-heading.component';
import { EntityFormDispatchChildren } from '../../components/entity-form-dispatch-children/entity-form-dispatch-children.component';
import { EntityFormLayoutProps, LayoutComponent } from '../../types/layouts';

const ObjectCompoundLayoutContents = ({
  hint,
  label,
  banner,
  schema,
  path,
  enabled,
  uischema,
  renderers,
  cells,
}: EntityFormLayoutProps) => {
  const group = uischema as GroupLayout;

  return (
    // TODO :: Path as ID temporary for now until object tree
    <Box css={{ width: '100%' }} id={path}>
      <CompoundComplexHeading label={label} banner={banner} hint={hint} />
      <EntityFormDispatchChildren
        layout={group}
        schema={schema}
        path={path}
        enabled={enabled}
        renderers={renderers}
        cells={cells}
      />
    </Box>
  );
};

const renderLayout = (formContentsProps: EntityFormLayoutProps) => (
  <ObjectCompoundLayoutContents {...formContentsProps} />
);

export const ObjectCompoundLayout: LayoutComponent = (props) => (
  <EntityFormLayout {...props} renderLayout={renderLayout} />
);
