import * as React from 'react';
import { getUserNameAndEmail } from '../../utilities/get-user-name-and-email/get-user-name-and-email.util';

export const useUserNameAndEmail = () => {
  const [userName, setUserName] = React.useState('');
  const [userEmail, setUserEmail] = React.useState('');
  React.useEffect(() => {
    const getAndSetUserInfo = async () => {
      const userInfo = await getUserNameAndEmail();
      if (userInfo) {
        setUserName(userInfo.name);
        setUserEmail(userInfo.email);
      }
    };
    getAndSetUserInfo();
  }, []);

  return {
    userName,
    userEmail,
  };
};
