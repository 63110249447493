import { Entity } from '@web-config-app/core';
import { useDomain } from '../use-domain/use-domain';

interface UseEntityProps {
  domainId?: string;
  entityId?: string;
}

export const useEntity = ({ domainId, entityId }: UseEntityProps): Entity => {
  if (!domainId) {
    throw new Error(`Missing domain id`);
  }

  const domain = useDomain(domainId);

  if (!entityId) {
    throw new Error(
      'useEntity requires either entityType or entityId and neither was passed',
    );
  }

  const entityFromId = domain.entities.get(entityId);

  if (!entityFromId) {
    throw new Error(`Unknown entity: ${entityId}`);
  }

  return entityFromId;
};
