import * as React from 'react';
import { Box, StackLayout, Divider } from '@leagueplatform/genesis-core';
import { TOP_BAR_LINEAR_GRADIENT } from '../../constants';

type BasePageLayoutProps = {
  topBar: React.ReactNode | JSX.Element;
  sidePanel: React.ReactNode | JSX.Element;
};

const SIDE_PANEL_WIDTH = '16rem';
const TOP_BAR_HEIGHT = '3.75rem';

export const BasePageLayout: React.FC<
  React.PropsWithChildren<BasePageLayoutProps>
> = ({ topBar, sidePanel, children }) => (
  <Box
    css={{
      width: '100%',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <Box
      css={{
        position: 'fixed',
        top: 0,
        height: TOP_BAR_HEIGHT,
        minHeight: TOP_BAR_HEIGHT,
        borderBottom: 'solid $colors$onSurfaceBorderSubdued $borderWidths$thin',
        zIndex: '$sticky',
        background: '$surfaceBackgroundPrimary',
        width: '100%',
      }}
    >
      {/* wrapping the topBar in a div helps it avoid dealing with all the flex nonsense that comes with nested StackLayouts */}
      {topBar}
    </Box>
    <StackLayout
      orientation="horizontal"
      spacing="$none"
      css={{ width: '100%', flexGrow: 1 }}
      verticalAlignment="stretch"
    >
      <Box
        css={{
          borderRight:
            'solid $colors$onSurfaceBorderSubdued $borderWidths$thin',
          width: SIDE_PANEL_WIDTH,
          minWidth: SIDE_PANEL_WIDTH,
          padding: '$one',
          position: 'fixed',
          top: TOP_BAR_HEIGHT,
          height: '100%',
        }}
      >
        {sidePanel}
      </Box>
      <Divider orientation="vertical" />
      <Box
        as="main"
        css={{
          flexGrow: 1,
          paddingLeft: SIDE_PANEL_WIDTH,
          width: '100%',
          marginTop: TOP_BAR_HEIGHT,
        }}
      >
        <Box
          css={{
            height: '$one',
            backgroundImage: TOP_BAR_LINEAR_GRADIENT,
            position: 'fixed',
            width: '100%',
          }}
        />
        {children}
      </Box>
    </StackLayout>
  </Box>
);
