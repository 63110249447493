import { FlatDataPoint } from '../types/flat-data-point.types';

export const findDataPoint = (
  textInputValue: string,
  sourceDataPoints: FlatDataPoint[] = [],
): FlatDataPoint | undefined => {
  const filteredDataPoints = sourceDataPoints.filter(
    (dataPoint) => dataPoint.isCategory === false,
  );

  const validDataPoint = filteredDataPoints.find(
    (dataPoint: any) => dataPoint.id === textInputValue,
  );

  return validDataPoint;
};
