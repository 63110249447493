import { DomainConfig } from '@web-config-app/core';

export const filterDomainsAndEntitiesByPermissions = (
  domains: DomainConfig[],
  hasAllPermissions: (permissionsToCheck: string[]) => boolean,
) =>
  domains.map((domain) => ({
    ...domain,
    entities: domain.entities.filter((entity) => {
      const getEndpointPermissions =
        entity.endpoints?.get?.security?.[0]?.accessToken;

      // If the entity has permissions associated with its get endpoint,
      // we check and make sure the user has all the permissions required.
      // If the user does not have appropriate permissions, we filter out the entity.

      // If there are no permissions associated with the entity's get endpoint,
      // we do not filter out the entity
      return getEndpointPermissions
        ? hasAllPermissions(getEndpointPermissions)
        : true;
    }),
  }));
