import * as React from 'react';
import { GDSSelectProps, Select } from '@leagueplatform/genesis-core';
import { ControlComponent } from '../../../types/controls';
import { EntityFormControl } from '../../entity-form-control/entity-form-control.component';

// In the case of an optional select input, we want the placeholder to be selectable
// with an empty value such that the user can de-select a value
// In the case of a required select input, we want the placeholder to not be selectable
// such that once a user has selected a required value, they cannot deselect it
const getSelectOptionsWithPlaceholder = ({
  enumOptions,
  enumPlaceholder,
  required,
}: {
  enumOptions?: GDSSelectProps['options'];
  required?: boolean;
  enumPlaceholder?: string;
}) => {
  if (!required && enumOptions) {
    return { options: [{ value: '', label: enumPlaceholder }, ...enumOptions] };
  }
  return { placeholder: enumPlaceholder, options: enumOptions };
};

export const SelectInputControl: ControlComponent = (props) => (
  <EntityFormControl
    {...props}
    renderControl={({
      data,
      handleChange,
      path,
      inputStatus,
      enabled,
      enumOptions,
      enumPlaceholder,
      required,
    }) => {
      const selectOptionsWithPlaceholder = getSelectOptionsWithPlaceholder({
        enumOptions,
        required,
        enumPlaceholder,
      });
      return (
        <Select
          value={data ?? ''}
          onChange={(evt) =>
            // if the selected value is falsey, we want to set `data` to undefined
            // as this passes ajv validation and allows for a successful clear of the
            // value in our POST/PATCH calls
            handleChange(path, evt.currentTarget.value || undefined)
          }
          readOnly={!enabled}
          inputStatus={inputStatus}
          required={required}
          {...selectOptionsWithPlaceholder}
        />
      );
    }}
  />
);
