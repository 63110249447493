import { GDSStyleObject } from '@leagueplatform/genesis-core';

export const toolbarDropdownCss = {
  width: 'max-content',
  height: 'max-content',
  zIndex: '$dropdown',
  background: '$surfaceBackgroundPrimary',
  boxShadow: '$dropdown',
  borderRadius: '$small',
  border: 'solid $colors$onSurfaceBorderSubdued $borderWidths$thin',
  '.richtext-dropdown-item': {
    display: 'flex',
    typography: '$label',
    color: '$interactiveActionSubdued',
    padding: '$half $oneAndHalf',
    borderBottom: 'solid $colors$onSurfaceBorderSubdued $borderWidths$thin',
    '&:last-child': {
      borderBottom: 'none',
    },
    '&:hover': {
      backgroundColor: '$tertiaryBackgroundHovered',
      color: '$tertiaryTextHovered',
      borderColor: 'transparent',
    },
    '&:active': {
      backgroundColor: '$tertiaryBackgroundHovered',
      color: '$tertiaryTextHovered',
      borderColor: 'transparent',
    },
  },
  '.richtext-dropdown-item-indicator': {
    height: '$one',
  },
} as GDSStyleObject;
