import { useEffect } from 'react';
import { setConfig } from '@leagueplatform/core';
import { useHasPermissionsHelpers } from '@leagueplatform/permissions';
import { useConfigApp } from '../use-config-app/use-config-app';
import { filterDomainsAndEntitiesByPermissions } from './filter-domains-and-entities-by-permissions.util';

export const useFilterEntitiesBasedOnPermissions = ({
  isAuthenticated,
  permissionsRequestStatus,
}: {
  isAuthenticated?: boolean;
  // We can't use QueryStatus from react query because the version used
  // in league-web is incompatible with the version in the cact app, leading
  // to a type mismatch. As a result, I've hard coded the statuses here.
  permissionsRequestStatus?: 'error' | 'idle' | 'loading' | 'success';
}) => {
  const { domains } = useConfigApp();
  const { hasAllPermissions } = useHasPermissionsHelpers();

  useEffect(() => {
    // make sure the user is authenticated, and we are not currently fetching the
    // users permissions before we attempt to filter entities out of domains.
    if (isAuthenticated && permissionsRequestStatus === 'success') {
      const filteredDomains = filterDomainsAndEntitiesByPermissions(
        domains,
        hasAllPermissions,
      );
      setConfig((draft) => {
        // @ts-ignore
        // eslint-disable-next-line no-param-reassign
        draft.configApp.domains = filteredDomains;
      });
    }
    // We don't want to include `domains` as a dependency as it would cause an
    // infinite re-rendering loop, calling setConfig each time the domains change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, permissionsRequestStatus]);
};
