import * as React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { StackLayout } from '@leagueplatform/genesis-core';

import { ArrayActionButtonsProps } from '../../types/components';
import { DeleteConfirmationModal } from '../delete-confirm-modal/delete-confirm-modal.component';
import { IconButtonWithTooltip } from '../icon-button-with-tooltip/icon-button-with-tooltip.component';

/**
 These are the Array Action buttons that control deleting and changing the position of the individual array items. 
 */

export const ArrayActionButtons = ({
  showDeleteModal = false,
  onDelete,
  onMoveUp,
  onMoveDown,
  itemLabel,
  itemIndex,
  arrayLength,
}: ArrayActionButtonsProps) => {
  const { formatMessage } = useIntl();

  const itemListPosition = itemIndex + 1;

  const lastItem = arrayLength === itemListPosition;
  const firstItem = itemIndex === 0;

  return (
    <StackLayout spacing="$quarter" orientation="horizontal">
      <IconButtonWithTooltip
        tooltipContent={formatMessage(
          { id: 'MOVE_ITEM_DOWN_TOOLTIP' },
          { item: itemLabel },
        )}
        buttonLabel={formatMessage({ id: 'DOWN' })}
        onClick={onMoveDown}
        disabled={lastItem}
        icon="interfaceChevronDown"
        size="small"
        priority="tertiary"
        quiet
        css={{
          border: 'none',
          visibility: `${lastItem ? 'hidden' : 'visible'}`,
        }}
      />
      <IconButtonWithTooltip
        tooltipContent={formatMessage(
          { id: 'MOVE_ITEM_UP_TOOLTIP' },
          { item: itemLabel },
        )}
        buttonLabel={formatMessage({ id: 'UP' })}
        onClick={onMoveUp}
        disabled={firstItem}
        icon="interfaceChevronUp"
        size="small"
        priority="tertiary"
        quiet
        css={{
          border: 'none',
          visibility: `${firstItem ? 'hidden' : 'visible'}`,
        }}
      />
      {showDeleteModal ? (
        <DeleteConfirmationModal
          modalDescription={formatMessage(
            { id: 'DELETE_ARRAY_ITEM_MODAL_DESCRIPTION' },
            { item: itemLabel },
          )}
          onDelete={onDelete}
        >
          <IconButtonWithTooltip
            tooltipContent={formatMessage(
              { id: 'DELETE_ITEM_ARRAY_TOOLTIP' },
              { item: itemLabel },
            )}
            buttonLabel={formatMessage({ id: 'DELETE' })}
            icon="interfaceDelete"
            size="small"
            priority="secondary"
            quiet
            destructive
          />
        </DeleteConfirmationModal>
      ) : (
        <IconButtonWithTooltip
          tooltipContent={formatMessage(
            { id: 'DELETE_ITEM_ARRAY_TOOLTIP' },
            { item: itemLabel },
          )}
          buttonLabel={formatMessage({ id: 'DELETE' })}
          onClick={onDelete}
          icon="interfaceDelete"
          size="small"
          priority="secondary"
          quiet
          destructive
        />
      )}
    </StackLayout>
  );
};
