import * as React from 'react';
import { useEntityDetailsProps } from '../use-entity-details-props/use-entity-details-props';

export const useParentSchema = () => {
  const { formPath, nodeMap } = useEntityDetailsProps();

  const parentSchema = React.useMemo(() => {
    if (formPath) {
      const parentPath = formPath.split('.').slice(0, -1).join('.');
      const parentNode = nodeMap.get(parentPath);
      return parentNode?.data?.schema;
    }
    return null;
  }, [formPath, nodeMap]);

  return { parentSchema };
};
