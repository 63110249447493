import { Entity } from '@web-config-app/core';
import { useEntityGet } from '@web-config-app/core-react';

/**
 * This hook fetches data for a referenced entity based on its ID. It's used in entity reference controls to display the details of the referenced entity.
 *
 * When used in an entity reference control, this hook is passed the `entity` object (representing the type of entity being referenced) and the `instanceId` (the ID of the specific instance being referenced).
 *
 * The hook then uses the `useEntityGet` hook from `@web-config-app/core-react` to fetch the entity data. The `useEntityGet` hook handles the fetching logic and returns the fetched data, loading state, and error state.
 *
 * This hook returns an object containing the `referencedEntity` (the fetched data), `isLoading` (a boolean indicating if the data is still being fetched), and `isError` (a boolean indicating if an error occurred during fetching).
 *
 * Example Usage:
 *
 * ```typescript
 * const { referencedEntity, isLoading, isError } = useReferencedEntityData({
 *   entity: {
 *     name: 'Article',
 *     nameTranslationKey: 'article.name',
 *   },
 *   instanceId: '123',
 * });
 *
 * if (isLoading) {
 *   // Display loading indicator
 * } else if (isError) {
 *   // Display error message
 * } else {
 *   // Display referencedEntity data
 * }
 * ```
 */

export const useReferencedEntityData = ({
  entity,
  instanceId,
}: {
  entity: Entity;
  instanceId?: string;
}) => {
  /**
   * TODO: https://everlong.atlassian.net/browse/CACT-1008
   * This will also need to ingest data from `included`
   */
  const {
    data: referencedEntity,
    isLoading,
    isError,
  } = useEntityGet({
    entity,
    instanceId,
  });

  return {
    referencedEntity,
    isLoading,
    isError,
  };
};
