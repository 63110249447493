import React from 'react';
import { GDSColor, UtilityText } from '@leagueplatform/genesis-core';

interface EntityMenuItemTextProps {
  disabled?: boolean;
  label: string;
  color?: GDSColor;
}

export const EntityMenuItemText = React.forwardRef(
  (
    {
      disabled,
      label,
      color = '$onSurfaceTextPrimary',
      ...rest
    }: EntityMenuItemTextProps,
    ref: React.ForwardedRef<HTMLParagraphElement>,
  ) => (
    <UtilityText
      ref={ref}
      data-testid="entity-menu-item-text"
      as="p"
      css={{
        '&:hover': {
          backgroundColor: disabled
            ? '$interactiveBackgroundPressed'
            : '$inputBackgroundHovered',
          cursor: disabled ? 'default' : 'pointer',
        },
        color: disabled ? '$onSurfaceTextSubdued' : color,
        padding: '$threeQuarters $oneAndHalf',
        backgroundColor: disabled ? '$interactiveBackgroundPressed' : 'inherit',
        'aria-disabled': disabled,
      }}
      {...rest}
    >
      {label}
    </UtilityText>
  ),
);
