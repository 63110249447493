import * as React from 'react';
import { GroupLayout } from '@jsonforms/core';
import { EntityFormLayout } from '../../components/entity-form-layout/entity-form-layout.component';
import { EntityFormDispatchChildren } from '../../components/entity-form-dispatch-children/entity-form-dispatch-children.component';
import { EntityFormLayoutProps, LayoutComponent } from '../../types/layouts';
import { CompoundPrimitiveGroupFieldset } from '../../components/compound-primitive-group-fieldset/compound-primitive-group-fieldset.component';

const ObjectPrimitiveLayoutContents = ({
  hint,
  label,
  banner,
  schema,
  path,
  enabled,
  uischema,
  renderers,
  cells,
}: EntityFormLayoutProps) => {
  const group = uischema as GroupLayout;
  return (
    <CompoundPrimitiveGroupFieldset
      label={label}
      hint={hint}
      banner={banner}
      childWrapperCss={{
        paddingX: '$oneAndQuarter',
        border: '$borderWidths$thin solid $onSurfaceBorderSubdued',
        borderRadius: '$medium',
      }}
    >
      <EntityFormDispatchChildren
        layout={group}
        schema={schema}
        path={path}
        enabled={enabled}
        renderers={renderers}
        cells={cells}
      />
    </CompoundPrimitiveGroupFieldset>
  );
};

const renderLayout = (formContentsProps: EntityFormLayoutProps) => (
  <ObjectPrimitiveLayoutContents {...formContentsProps} />
);

export const ObjectPrimitiveLayout: LayoutComponent = (props) => (
  <EntityFormLayout {...props} renderLayout={renderLayout} />
);
