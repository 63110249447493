import * as React from 'react';
import {
  EntityDetailsContext,
  EntityDetailsContextType,
} from '../../components/entity-details-provider/entity-details-provider.component';

export const useEntityDetailsProps = (): EntityDetailsContextType => {
  const ctx = React.useContext(
    EntityDetailsContext,
  ) as EntityDetailsContextType;

  return ctx;
};
