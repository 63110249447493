import React from 'react';
import { EntityListPageView } from '@web-config-app/core-react-ui';
import { EntityListPageContainer } from '@web-config-app/core-react-containers';

export function EntityListPage() {
  return (
    <EntityListPageContainer
      entityListPageContentsRenderer={EntityListPageView}
    />
  );
}
