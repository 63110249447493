import { useIntl } from '@leagueplatform/locales';
import { Entity, EntityRootData, Operation } from '@web-config-app/core';
import {
  UseEntityCreateOptions,
  useEntityCreate,
} from '../use-entity-create/use-entity-create';
import { duplicateEntityAndPrefixName } from '../../utilities/duplicate-entity-and-prefix-name/duplicate-entity-and-prefix-name.util';

export type UseEntityDuplicateResult = ReturnType<typeof useEntityDuplicate>;
export interface UseEntityDuplicateProps {
  entity: Entity;
  data?: EntityRootData;
  headers?: HeadersInit;
  options?: UseEntityCreateOptions;
}

export const useEntityDuplicate = ({
  entity,
  data,
  options,
}: UseEntityDuplicateProps) => {
  const { formatMessage } = useIntl();
  const { endpoints } = entity;
  const entityNamePath = entity.schema?.['x-entity-metadata']?.entityNamePath;
  const prefix = formatMessage({ id: 'DUPLICATE_OF' });

  const duplicateEntity = useEntityCreate({
    entity,
    data: duplicateEntityAndPrefixName({ data, entityNamePath, prefix }),
    options,
    headers: {
      'Content-Type': 'application/vnd.api+json',
    },
  });

  const duplicateAsDraft = {
    ...duplicateEntity,
    enableAction: !!endpoints[Operation.DuplicateAsDraft],
  };
  return duplicateAsDraft;
};
