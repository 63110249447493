import { useMemo } from 'react';
import type { AnnotatedJsonSchema } from '@web-config-app/core';
import type { Translate } from '../../types/controls';
import { EntityFormControlProps } from '../../types/controls';
import { useTranslate } from '../use-translate/use-translate';
import { getCombinatorProperties } from '../../utils/get-combinator-properties/get-combinator-properties.util';

const getLabelAnnotations = (schema: AnnotatedJsonSchema) =>
  schema['x-entity-label'] ?? {};
const getPresentationAnnotations = (schema: AnnotatedJsonSchema) =>
  schema['x-entity-presentation'] ?? {};

const ENUM_CONTROL_PLACEHOLDER_VALUE = 'enumControlPlaceholder';

export function mapAnnotatedSchemaToProps(
  schema: AnnotatedJsonSchema,
  translate: Translate,
): Partial<EntityFormControlProps> {
  const { key: labelTranslationKey, propertyRef: labelPropertyRef } =
    getLabelAnnotations(schema);
  const {
    hint,
    banner,
    hidden,
    enumLabels,
    fieldGroups,
    fieldOrder,
    arrayAddLabel,
  } = getPresentationAnnotations(schema);

  const localized = schema['x-entity-localized'] ?? false;

  /**
   * enumOptions apply to the SelectInputControl and provide localized
   * labels for each raw value in the schema's `enum`.
   */

  const enumOptions =
    enumLabels && Array.isArray(schema.enum)
      ? Object.entries(enumLabels)
          .filter(([value]) => {
            if (schema.enum?.includes(value)) {
              return true;
            }

            if (value === ENUM_CONTROL_PLACEHOLDER_VALUE) {
              return false;
            }

            /**
             * We have an enumLabels map that contains a value that doesn't exist in the actual
             * schema's enum value
             */
            console.warn(
              `received enumLabel for value ${value} that does not exist in schema.enum`,
            );
            return false;
          })
          .map(([value, translationKey]) => ({
            label: translate(translationKey),
            value,
          }))
      : schema.enum?.map((item) => ({ label: item, value: item }));

  const enumPlaceholder = translate(
    enumLabels?.enumControlPlaceholder ?? 'DEFAULT_ENUM_PLACEHOLDER',
  );

  /**
   * combinatorProperties are created when the schema includes a discriminator property and either a `oneOf` or `anyOf` array
   * The combinator properties include:
   * - the discriminator property name
   * - the options for the select input used for selecting a subschema option
   * - the subschemas to be rendered upon option selection
   * - a placeholder for the select input
   * - properties to be added to the primtive control form field wrapping the combinator select input
   */

  const combinatorProperties = getCombinatorProperties(schema, translate);

  /**
   * When virtually grouping properties in an Object control, this returns
   * an array of field groups with a localized label for the fieldset's legend
   */
  const fieldGroupsWithLegends = fieldGroups?.map(
    ({ labelKey, ...fieldGroup }) => ({
      ...fieldGroup,
      legend: translate(labelKey)!,
    }),
  );

  return {
    banner: banner && {
      ...banner,
      title: translate(banner.title),
      description: translate(banner.description),
    },
    enumOptions,
    enumPlaceholder,
    fieldGroups: fieldGroupsWithLegends,
    fieldOrder,
    hidden: Boolean(hidden), // if undefined, coerce to false
    hint: translate(hint),
    label: translate(labelTranslationKey) ?? schema.title,
    arrayAddLabel: translate(arrayAddLabel),
    labelPropertyRef,
    localized,
    combinatorProperties,
  };
}

export type UseAnnotatedSchemaProps = Partial<EntityFormControlProps>;

export type AnnotatedSchemaProps = ReturnType<typeof useAnnotatedSchemaProps>;

export const useAnnotatedSchemaProps = (schema: AnnotatedJsonSchema) => {
  const translate = useTranslate();
  return useMemo(
    () => mapAnnotatedSchemaToProps(schema, translate),
    [schema, translate],
  );
};
