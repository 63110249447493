import * as React from 'react';
import {
  StackLayout,
  StackItem,
  TextAction,
} from '@leagueplatform/genesis-core';
import { FlatDataPoint } from '../../types/flat-data-point.types';

export interface DataPointBreadcrumbsProps {
  breadcrumbs: FlatDataPoint[];
  onSelectCrumb: Function;
}

export const DataPointBreadcrumbs = ({
  breadcrumbs,
  onSelectCrumb,
}: DataPointBreadcrumbsProps) => {
  const lastCrumb = breadcrumbs.length - 1;

  if (lastCrumb < 0) return null;

  return (
    <StackLayout
      as="nav"
      orientation="horizontal"
      css={{ flex: '0', margin: '$half 0 $one' }}
    >
      {breadcrumbs.map((crumb, idx) => (
        <StackItem key={crumb.id ? crumb.id : 'category'}>
          <TextAction
            aria-current={idx < lastCrumb ? 'false' : 'true'}
            as="button"
            css={{
              textTransform: 'capitalize',
              '.GDS-icon': {
                margin: '0 $quarter 0 $half',
              },
              '&[aria-current="true"]': {
                textDecoration: 'underline',
                '.GDS-icon': {
                  display: 'none',
                },
              },
            }}
            emphasis="subtle"
            indicator="disclosure"
            onClick={() => onSelectCrumb(crumb.id)}
            size="medium"
          >
            {crumb.label}
          </TextAction>
        </StackItem>
      ))}
    </StackLayout>
  );
};
