import { useParams } from '@leagueplatform/routing';

export interface ConfigAppParams {
  entityId?: string;
  domainId?: string;
  entityInstanceId?: string;
}

export function useConfigAppParams<
  Params extends { [K in keyof Params]?: string } = ConfigAppParams,
>() {
  const params = useParams<Params>();

  return params;
}
