import * as React from 'react';
import { useHistory } from '@leagueplatform/routing';
import { useIntl } from '@leagueplatform/locales';
import { environmentKeys } from '@web-config-app/core';
import {
  AppHeader,
  IconButton,
  UserProfilePopover,
} from '@web-config-app/core-react-ui';
import { useUserNameAndEmail, useConfigApp } from '@web-config-app/core-react';

export const AppHeaderContainer = () => {
  const { tenantLogo, tenantName, env } = useConfigApp();
  const { formatMessage } = useIntl();
  //  TODO :: replace this with settings navigation
  const handleSettingsClick = () => null;

  const environmentName = formatMessage({
    id: environmentKeys[env],
  });
  const history = useHistory();
  const { userName, userEmail } = useUserNameAndEmail();
  return (
    <AppHeader
      logo={tenantLogo}
      tenant={tenantName}
      environment={environmentName}
    >
      <IconButton
        icon="illustrativeSettings"
        onClick={handleSettingsClick}
        label={formatMessage({ id: 'SETTINGS' })}
      />
      <UserProfilePopover
        triggerLabel={formatMessage({ id: 'PROFILE' })}
        userName={userName}
        userEmail={userEmail}
        ctaLabel={formatMessage({ id: 'LOG_OUT' })}
        ctaAction={() => history.push('/sign-out')}
      />
    </AppHeader>
  );
};
