import {
  UseQueryOptions,
  UseQueryResult,
  useQuery,
} from '@tanstack/react-query';
import {
  Entity,
  GetEndpointResponse,
  EntityDetail,
  EntityDetailSchemaWithRelationships,
  EndpointPathParameter,
  EndpointQueryParameter,
  EndpointParameterValue,
  Nullable,
} from '@web-config-app/core';
import { getRelationshipSchemaItemType } from '@web-config-app/schema-utils';
import { getEntityEndpoint } from '../../utilities/get-entity-endpoint/get-entity-endpoint.util';

type EntityGetUseQueryResult = UseQueryResult<GetEndpointResponse>;

export type UseEntityGetResult = Omit<EntityGetUseQueryResult, 'data'> & {
  /**
   * There's a clash between `useQuery` returning a `data` property and our endpoints that ALSO include a `data` property
   * in the response. So, to avoid have `data.data`, hoist the endpoint's `response.data` to the top level here.
   */
  data?: EntityDetail;
};

/**
 * useEntityGet - The following hook is responsible for calling the BE API associated to the Get (GET) service of an entity.
 * @param props
 * @param props.entity - the whole {@link Entity} that exposes the needed values including the id, endpoints, etc.
 * @param props.instanceId - the entity ID needed to call the GET api with.
 * @param props.options - optional config object
 * @param props.options.fetchFn - You can pass in your own custom fetch function to be used, instead of the default leagueFetch
 * @returns the result of useQuery, which included the fetching, loading statuses and the data returned.
 */
export interface UseEntityGetOptions extends Partial<UseQueryOptions> {
  fetchFn?: (path: string, options: any) => Promise<Response>;
  getIncludedEntities?: boolean;
}
interface UseEntityGetProps {
  entity: Entity;
  instanceId?: string;
  options?: UseEntityGetOptions;
}

type ComputedEndpointParam = Nullable<EndpointParameterValue>;

/**
 * the path parameters name value is used in the params prop of endpointFetch and is
 * used to replace the id placeholder in the path with the value of instanceId
 */
const getIdParam = (
  pathParameters: EndpointPathParameter[],
  path: string,
  instanceId: string | undefined,
): ComputedEndpointParam => {
  /**
   * Fine the param whose name matches the placeholder pattern in the endpoint path,
   * @example
   * path = `v1/config-cactuses/{cactusId}
   * param = { name: `cactusId`, ... }
   */
  const entityInstanceIdParam = pathParameters?.find(
    (param) => path.indexOf(`${param.name}`) > -1,
  );

  const entityInstanceIdParamName = entityInstanceIdParam?.name;

  return instanceId && entityInstanceIdParamName
    ? {
        name: entityInstanceIdParamName,
        value: instanceId,
      }
    : null;
};

/**
 * The include param is used to return any entities included in the fetched relationships
 *  entity's relationships.
 *
 * {@link https://jsonapi.org/format/#fetching-includes}
 */

const getIncludeEntityTypes = (
  relationships: string[],
  schema: EntityDetailSchemaWithRelationships,
) => {
  const types = relationships.map((relationship) => {
    const { type: entityType } = getRelationshipSchemaItemType(
      relationship,
      schema,
    );
    return entityType;
  });

  return Array.from(new Set(types));
};

const getIncludeParam = (
  queryParameters: EndpointQueryParameter[],
  schema: EntityDetailSchemaWithRelationships,
  getIncludedEntities: boolean,
): ComputedEndpointParam => {
  if (!getIncludedEntities) {
    return null;
  }
  const includeEntitiesParam = queryParameters?.find(
    (param) => param.name === 'include',
  );
  const relationshipSchemas = schema.properties.relationships?.properties;
  const includeEntityTypes =
    includeEntitiesParam && typeof relationshipSchemas === 'object'
      ? getIncludeEntityTypes(Object.keys(relationshipSchemas), schema)
      : [];
  return includeEntityTypes.length > 0
    ? { name: 'include', value: includeEntityTypes.join(',') }
    : null;
};

const isEndpointParameterValue = (
  value: ComputedEndpointParam,
): value is EndpointParameterValue => value !== null;

const getParams = (paramResults: Array<ComputedEndpointParam>) =>
  paramResults.filter(isEndpointParameterValue);

export const useEntityGet = ({
  entity,
  instanceId,
  options,
}: UseEntityGetProps): UseEntityGetResult => {
  const {
    schema,
    id: entityId,
    endpoints: { get },
  } = entity;

  if (!get) {
    throw new Error(`Entity ${entityId} has no defined get endpoint`);
  }

  const params = getParams([
    getIdParam(get.pathParameters ?? [], get.path, instanceId),
    getIncludeParam(
      get.queryParameters ?? [],
      schema,
      options?.getIncludedEntities ?? false,
    ),
  ]);

  const { fetchFn, enabled = true, retry } = options ?? {};

  const { endpointFetch } = getEntityEndpoint<GetEndpointResponse>(
    get,
    fetchFn,
  );

  /**
   * Uses the endpoint root URL
   *
   * We rename the data returned by useQuery so we can differentiate the data from our API response which
   * also has a `data` property. This avoids a result with a `result.data.data` structure.
   */

  const { data: queryResultData, ...queryResult } = useQuery({
    queryFn: () =>
      endpointFetch({
        params,
      }),
    queryKey: [get.path, instanceId],
    enabled: enabled && Boolean(instanceId),
    retry,
    staleTime: Infinity,
  });

  return {
    ...queryResult,
    data: queryResultData?.data,
  };
};
