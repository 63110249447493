import * as React from 'react';
import { TextAction, StackLayout, Divider } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { ListEndpointPaginationLinks, Nullable } from '@web-config-app/core';

const PaginationLink = ({
  url,
  onClick,
  children,
}: React.PropsWithChildren<{
  url?: Nullable<string>;
  onClick?: (...args: any[]) => void;
}>) => (
  <TextAction
    css={{
      color: url ? '$interactiveActionPrimary' : '$interactiveActionDisabled',
    }}
    as="button"
    disabled={!url}
    onClick={() => onClick?.(url)}
  >
    {children}
  </TextAction>
);

type EntityListTablePaginationProps = {
  pagination: ListEndpointPaginationLinks;
  loadPage?: (page: string) => void;
};

export const EntityListTablePagination = ({
  pagination,
  loadPage,
}: EntityListTablePaginationProps) => {
  const { formatMessage } = useIntl();
  const { first, last, next, prev } = pagination;

  const isFirst = !prev;
  const isLast = !next;

  return (
    <StackLayout
      css={{ marginTop: '$three' }}
      orientation="horizontal"
      horizontalAlignment="center"
      spacing="$half"
      divider={<Divider orientation="vertical" />}
    >
      <PaginationLink url={isFirst ? null : first} onClick={loadPage}>
        {formatMessage({ id: 'FIRST' })}
      </PaginationLink>
      <PaginationLink url={prev} onClick={loadPage}>
        &larr; {formatMessage({ id: 'PREVIOUS' })}
      </PaginationLink>
      <PaginationLink url={next} onClick={loadPage}>
        {formatMessage({ id: 'NEXT' })} &rarr;
      </PaginationLink>
      <PaginationLink url={isLast ? null : last} onClick={loadPage}>
        {formatMessage({ id: 'LAST' })}
      </PaginationLink>
    </StackLayout>
  );
};
