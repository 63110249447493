import * as React from 'react';
import {
  StackLayout,
  Modal,
  Button,
  HeadingText,
  HeadingBar,
  TextAction,
  InputStatusMessage,
  Box,
  StackItem,
  ParagraphText,
  SkeletonRectangle,
  VisuallyHidden,
  Icon,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { useNavigateDataPoints } from '../../hooks/use-navigate-data-points.hook';
import { SelectDataPoint } from '../select-data-point/select-data-point.component';
import { ParentId } from '../../types/flat-data-point.types';
import { DataPointBreadcrumbs } from '../data-point-breadcrumbs/data-point-breadcrumbs.component';
import { EditDataIcon } from '../../assets/edit-data.icon';
import { AddDataIcon } from '../../assets/add-data-icon';

const headingId = 'data-point-modal-heading';
const containerId = 'select-data-point-container';

const assignFocusToContainer = () => {
  document.getElementById(containerId)?.focus();
};

export interface DataPointModalProps {
  editDataPoint: boolean;
  inputLabel: string;
  onSelected: Function;
  inputIndex?: number; // inputIndex passed if operator is 'between', or 'notBetween' for updating value onSelectDataPoint
}

export const DataPointModal = ({
  editDataPoint,
  inputLabel,
  onSelected,
  inputIndex,
}: DataPointModalProps) => {
  const { formatMessage } = useIntl();
  const {
    currentCategory,
    categoryDataPoints,
    categoryBreadcrumbs,
    hasSelectableDataPoint,
    isTopLevelCategory,
    isError,
    isLoading,
    onGoBack,
    onNavigate,
    selectableDataPoints,
  } = useNavigateDataPoints();

  const [modalOpen, setModalOpen] = React.useState(false);
  const [selectedDataPoint, setSelectedDataPoint] = React.useState(
    null as ParentId,
  );
  const [errorStatusMsg, setErrorStatusMsg] = React.useState(
    null as string | null,
  );

  const headingText = isTopLevelCategory
    ? formatMessage({ id: 'DATA_POINT_MODAL_HEADING' })
    : currentCategory?.label;

  const onNavigateBack = () => {
    onGoBack();
    setSelectedDataPoint(null);
    assignFocusToContainer();
  };

  const onNavigateCategory = (categoryId: ParentId) => {
    onNavigate(categoryId);
    setSelectedDataPoint(null);
    assignFocusToContainer();
  };

  const onSelectDataPoint = (dataPointId: ParentId) => {
    if (errorStatusMsg) setErrorStatusMsg(null);
    setSelectedDataPoint(dataPointId);
  };

  const buttonLabel = formatMessage({
    id: editDataPoint ? 'EDIT_DATA' : 'ADD_DATA',
  });
  const buttonAriaLabel = formatMessage(
    {
      id: editDataPoint
        ? 'EDIT_DATA_BUTTON_ARIA_LABEL'
        : 'ADD_DATA_BUTTON_ARIA_LABEL',
    },
    { inputLabel },
  );

  const onApplySelectedDataPoint = React.useCallback(() => {
    if (!selectedDataPoint) {
      setErrorStatusMsg('DATA_POINT_EMPTY_SELECTION');
      return;
    }

    if (inputIndex === undefined) {
      onSelected(selectedDataPoint);
    } else {
      onSelected(selectedDataPoint, inputIndex);
    }
    setModalOpen(false);
  }, [selectedDataPoint, inputIndex, onSelected]);

  const onModalOpenChange = React.useCallback(() => {
    setModalOpen((previous) => !previous);
  }, []);

  // Reset messaging, selections, and current category level on close modal
  React.useEffect(() => {
    if (!modalOpen) {
      setErrorStatusMsg(null);
      setSelectedDataPoint(null);
      onNavigate(null);
    }
  }, [modalOpen, onNavigate]);

  // Reset selectedDataPoint to first item when navigating categories
  React.useEffect(() => {
    if (modalOpen && hasSelectableDataPoint && !selectedDataPoint) {
      setSelectedDataPoint(selectableDataPoints[0].id);
    }
  }, [
    modalOpen,
    selectedDataPoint,
    hasSelectableDataPoint,
    selectableDataPoints,
  ]);

  return (
    <Modal.Root open={modalOpen} onOpenChange={onModalOpenChange}>
      <Modal.Trigger>
        <TextAction
          as="button"
          type="button"
          aria-label={buttonAriaLabel}
          size="medium"
        >
          <Icon
            css={{
              display: 'flex',
              marginRight: '$quarter',
            }}
            size="1.4rem"
            icon={editDataPoint ? <EditDataIcon /> : <AddDataIcon />}
          />
          {buttonLabel}
        </TextAction>
      </Modal.Trigger>
      <Modal.Content
        showCloseButton={false}
        padding="$none"
        css={{
          '.GDS-modal': { minWidth: '45vw' },
          '.GDS-modal-content': {
            paddingTop: '$none',
            paddingBottom: '$one',
          },
        }}
      >
        <HeadingBar
          rightAction={
            <Modal.Close>
              <Button
                priority="tertiary"
                quiet
                icon="tinyClose"
                hideLabel
                size="medium"
              >
                {formatMessage({ id: 'CLOSE' })}
              </Button>
            </Modal.Close>
          }
          leftAction={
            !isTopLevelCategory && (
              <Button
                hideLabel
                icon="tinyBackButton"
                onClick={onNavigateBack}
                priority="tertiary"
                quiet
                size="medium"
              >
                {formatMessage({ id: 'BACK' })}
              </Button>
            )
          }
        />
        <StackLayout
          css={{
            width: '100%',
            paddingRight: '$two',
            paddingLeft: '$two',
            minHeight: '50vh',
            justifyContent: 'flex-start',
          }}
        >
          <DataPointBreadcrumbs
            breadcrumbs={categoryBreadcrumbs}
            onSelectCrumb={onNavigateCategory}
          />

          <Modal.Title>
            <HeadingText
              css={{
                marginBottom: '$half',
                textTransform: 'capitalize',
              }}
              id={headingId}
              level="2"
              size="lg"
            >
              {headingText}
            </HeadingText>
          </Modal.Title>

          {isTopLevelCategory && !isLoading && (
            <ParagraphText>
              {formatMessage({ id: 'DATA_POINT_MODAL_INSTRUCTIONS' })}
            </ParagraphText>
          )}

          {isLoading && (
            <Box data-testid="loading-state" css={{ width: '100%' }}>
              <VisuallyHidden>
                {formatMessage({ id: 'LOADING' })}
              </VisuallyHidden>
              <SkeletonRectangle height="35vh" css={{ marginTop: '$two' }} />
            </Box>
          )}

          {isError && (
            <ParagraphText
              data-testid="error-state"
              css={{ marginTop: '$two' }}
            >
              {formatMessage({ id: 'DATA_POINT_LOADING_ERROR' })}
            </ParagraphText>
          )}

          <SelectDataPoint
            containerId={containerId}
            dataPoints={categoryDataPoints}
            labelledBy={headingId}
            onNavigate={onNavigateCategory}
            onSelectItem={onSelectDataPoint}
            selectedDataPoint={selectedDataPoint}
          />

          {hasSelectableDataPoint && (
            <StackItem
              css={{
                marginTop: 'auto',
                marginLeft: 'auto',
                alignItems: 'flex-end',
              }}
            >
              <Button
                css={{ width: 'max-content' }}
                priority="primary"
                size="medium"
                onClick={onApplySelectedDataPoint}
              >
                {formatMessage({ id: 'ADD_TO_RULE' })}
              </Button>

              <Box
                aria-live="polite"
                css={{
                  marginLeft: 'auto',
                  marginTop: '$half',
                  height: '$one',
                }}
                id="statusMessage"
              >
                {errorStatusMsg ? (
                  // TODO:: Remove with genesis core change for react 18
                  // @ts-ignore
                  <InputStatusMessage inputStatus="error" id="statusComponent">
                    {formatMessage({ id: errorStatusMsg })}
                  </InputStatusMessage>
                ) : null}
              </Box>
            </StackItem>
          )}
        </StackLayout>
      </Modal.Content>
    </Modal.Root>
  );
};
