import * as React from 'react';
import { debounce } from 'lodash';
import {
  UtilityText,
  StackLayout,
  Box,
  InputStatusMessage,
  TextInput,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { DataSourceFormField } from '../data-source-form-field/data-source-form-field.component';
import { useValidateDataPoints } from '../../hooks/use-validate-data-points.hook';
import { ValidatedRule } from '../../hooks/use-validate-rule-query.hook';
import { DATA_POINT_VALIDATION } from '../../constants/validation.constants';

export interface TextInputFieldProps {
  fieldHint?: string;
  inputIndex?: number; // inputIndex passed if operator is 'between', or 'notBetween' for updating value onSelectDataPoint
  invalidRuleField?: ValidatedRule;
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
  handleSelectDataPoint: Function;
  label: string;
  mustBeDataPoint: boolean;
  uniqueId: string;
  validationMsgId?: string;
  value: string | undefined;
}

export const TextInputField = ({
  fieldHint,
  inputIndex,
  invalidRuleField,
  handleChange,
  handleSelectDataPoint,
  label,
  mustBeDataPoint,
  uniqueId,
  validationMsgId,
  value,
}: TextInputFieldProps) => {
  const { formatMessage } = useIntl();
  const showFieldHint = !mustBeDataPoint && fieldHint !== undefined;
  const describedById = `${uniqueId}-description ${validationMsgId}`;

  const {
    validateInputMsg,
    inputStatusMsg,
    isValueDataPoint,
    checkValueForDataPoint,
  } = useValidateDataPoints();

  // invalidRuleField field validation for `between` and `notBetween` (inputIndex will be defined) is on both inputs combined, since it is technically one value on the query split by ',' into two.
  // we only want to show the validation message if the individual input value is also empty, which is why we do a value check
  const invalidField = React.useMemo(() => {
    if (inputIndex === undefined) {
      return invalidRuleField;
    }
    if (value) {
      return undefined;
    }
    return invalidRuleField;
  }, [inputIndex, invalidRuleField, value]);

  const inputStatusMessage = invalidField?.fieldMessage || inputStatusMsg?.msg;

  const handleDebounce = debounce((inputValue) => {
    checkValueForDataPoint(inputValue);
    validateInputMsg({
      textInputValue: inputValue,
      mustBeDataPoint,
      validateDataPointOn: DATA_POINT_VALIDATION,
    });
  }, 1000);

  React.useEffect(() => {
    handleDebounce(value);
    // only want to call this when value changes, not when the debounce function changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleBlur = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value;
      checkValueForDataPoint(inputValue);
      validateInputMsg({
        textInputValue: inputValue,
        mustBeDataPoint,
        validateDataPointOn: DATA_POINT_VALIDATION,
      });
    },
    [checkValueForDataPoint, mustBeDataPoint, validateInputMsg],
  );

  return (
    <StackLayout css={{ marginLeft: `${mustBeDataPoint ? `0` : `$one`}   ` }}>
      <DataSourceFormField
        uniqueId={uniqueId}
        label={formatMessage({ id: label })}
        editDataPoint={isValueDataPoint}
        onSelectDataPoint={handleSelectDataPoint}
        inputIndex={inputIndex}
      >
        <TextInput
          aria-describedby={describedById}
          css={{ width: '100%' }}
          id={uniqueId}
          name={uniqueId}
          onBlur={handleBlur}
          onChange={handleChange}
          value={value}
          inputStatus={invalidField ? 'error' : undefined}
        />
      </DataSourceFormField>
      <Box
        aria-live="polite"
        id={describedById}
        css={{ minHeight: '$twoAndHalf' }}
      >
        {showFieldHint && (
          <UtilityText
            data-testid="field-hint"
            css={{ marginTop: '$quarter', display: 'block' }}
            emphasis="subtle"
            size="xs"
          >
            {fieldHint}
          </UtilityText>
        )}
        {inputStatusMessage && (
          // TODO: Remove with Genesis fix for react 18
          // @ts-ignore
          <InputStatusMessage
            data-testid="status-message"
            inputStatus={inputStatusMsg?.status || 'error'}
            id={`${describedById}-text`}
          >
            {formatMessage({ id: inputStatusMessage })}
          </InputStatusMessage>
        )}
      </Box>
    </StackLayout>
  );
};
