import { useMemo } from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  EntityStatus,
  EntityStatusValue,
  EntityDetailAction,
  WorkflowAction,
  MenuItemWorkflowAction,
} from '@web-config-app/core';
import { UseEntityOperationsResult } from '@web-config-app/core-react';

interface EntityWorkflowActionsProps {
  operations?: UseEntityOperationsResult;
  status?: EntityStatus;
  isEntitySaved: boolean;
  entityConfigName: string;
}

export type UseEntityWorkflowActionsResult = ReturnType<
  typeof useEntityWorkflowActions
>;

/**
 * useEntityWorkflowActions - a hub that handles all things entity top bar workflow actions including label, enabled, tooltip, etc. for `EntityStatusValue`
 * @param operations - used in this hook where needed, like to determine which mutate function to call for the save button
 * @param status - used to determine different states and statuses of top bar things. Defaults to New when the status is undefined
 * @param isEntitySaved - based on if the entity is in sync with the data from the GET api call
 * @param entityConfigName - used to construct the tooltip of a menu item
 * @returns top bar workflows to be used by the header container
 */
export const useEntityWorkflowActions = ({
  operations,
  status = EntityStatusValue.New,
  isEntitySaved,
  entityConfigName,
}: EntityWorkflowActionsProps) => {
  const { formatMessage } = useIntl();
  const isStatusDraft = status === EntityStatusValue.Draft;

  // When an entity is published, status could be `published` or `changed`
  const isStatusPublished =
    status === EntityStatusValue.Published ||
    status === EntityStatusValue.Changed;

  // The publish button can be displayed when status is new or draft
  const displayPublish = isStatusDraft || status === EntityStatusValue.New;
  // The publish button is enabled if status is draft, entity is saved, and publish.enableAction is true
  const enablePublish =
    isStatusDraft && isEntitySaved && Boolean(operations?.publish.enableAction);

  const mainWorkflowActions: WorkflowAction[] = useMemo(
    () => [
      {
        // secondary action
        type: EntityDetailAction.Create,
        label: formatMessage({
          id: operations?.create.status === 'pending' ? 'SAVING' : 'SAVE_DRAFT',
        }),
        display: status === EntityStatusValue.New,
        action: operations?.create.mutate,
        actionStatus: operations?.create.status,
      },
      {
        // secondary action
        type: EntityDetailAction.Update,
        label: formatMessage({
          id:
            operations?.update?.status === 'pending'
              ? 'SAVING'
              : 'SAVE_CHANGES',
        }),
        display: isStatusDraft && Boolean(operations?.update.enableAction),
        action: operations?.update?.mutate,
        actionStatus: operations?.update?.status,
      },
      {
        // primary action
        type: EntityDetailAction.Publish,
        label: formatMessage({
          id:
            operations?.publish?.status === 'pending'
              ? 'PUBLISHING'
              : 'PUBLISH',
        }),
        disabled: !enablePublish,
        display: displayPublish,
        action: operations?.publish?.mutate,
        actionStatus: operations?.publish?.status,
      },
      {
        // primary action
        type: EntityDetailAction.Republish,
        label: formatMessage({
          id:
            operations?.republish?.status === 'pending'
              ? 'RE_PUBLISHING'
              : 'PUBLISH_CHANGES',
        }),
        display:
          isStatusPublished && Boolean(operations?.republish.enableAction),
        action: operations?.republish?.mutate,
        actionStatus: operations?.republish?.status,
      },
      {
        // secondary action
        type: EntityDetailAction.Unarchive,
        label: formatMessage({
          id:
            operations?.unarchive?.status === 'pending'
              ? 'UN_ARCHIVING'
              : 'UN_ARCHIVE',
        }),
        display:
          status === EntityStatusValue.Archived &&
          Boolean(operations?.unarchive?.enableAction),
        action: operations?.unarchive?.mutate,
        actionStatus: operations?.unarchive?.status,
      },
    ],
    [
      displayPublish,
      enablePublish,
      formatMessage,
      isStatusDraft,
      isStatusPublished,
      operations?.create.mutate,
      operations?.create.status,
      operations?.publish?.mutate,
      operations?.publish?.status,
      operations?.republish.enableAction,
      operations?.republish?.mutate,
      operations?.republish?.status,
      operations?.unarchive?.enableAction,
      operations?.unarchive?.mutate,
      operations?.unarchive?.status,
      operations?.update.enableAction,
      operations?.update?.mutate,
      operations?.update?.status,
      status,
    ],
  );

  const menuItemWorkflowActions: MenuItemWorkflowAction[] = useMemo(
    () => [
      // MENU ITEMS --------------------
      {
        type: EntityDetailAction.Unpublish,
        label: formatMessage({ id: 'UNPUBLISH' }),
        disabled: false,
        display:
          isStatusPublished && Boolean(operations?.unpublish.enableAction),
        action: operations?.unpublish?.mutate,
      },
      {
        type: EntityDetailAction.DuplicateAsDraft,
        label: formatMessage({ id: 'DUPLICATE_AS_DRAFT' }),
        tooltip: formatMessage(
          { id: 'SAVE_BEFORE_YOU_DUPLICATE' },
          { entity: entityConfigName },
        ),
        disabled: !isEntitySaved,
        // show the duplicateAsDraft menu item for these two states
        display:
          (isStatusDraft || isStatusPublished) &&
          Boolean(operations?.duplicateAsDraft.enableAction),
        // only allow the user to action on the duplicateAsDraft menu item when the entity is saved
        action: isEntitySaved ? operations?.duplicateAsDraft.mutate : null,
      },
      // TODO: Review archive and unarchive logic in CACT-1144
      {
        type: EntityDetailAction.Archive,
        label: formatMessage({ id: 'ARCHIVE' }),
        tooltip: formatMessage(
          { id: 'UNPUBLISH_BEFORE_YOU_ARCHIVE' },
          { entity: entityConfigName },
        ),
        disabled: isStatusPublished,
        // show the archive menu item for these two states
        display:
          Boolean(operations?.archive?.enableAction) &&
          (isStatusDraft || isStatusPublished),
        // only allow the user to action on the archive menu item when the entity is in draft
        action: isStatusDraft ? operations?.archive?.mutate : null,
      },
      {
        type: EntityDetailAction.ExitAndDiscardChanges,
        label: formatMessage({ id: 'EXIT_AND_DISCARD_CHANGES' }),
        disabled: false,
        display: status === EntityStatusValue.New && !isEntitySaved,
        displayAsModal: true,
        // No action for the exitAndDiscardChanges because it will open a modal instead
      },
    ],
    [
      entityConfigName,
      formatMessage,
      isEntitySaved,
      isStatusDraft,
      isStatusPublished,
      operations?.archive?.enableAction,
      operations?.archive?.mutate,
      operations?.unpublish.enableAction,
      operations?.unpublish?.mutate,
      operations?.duplicateAsDraft.enableAction,
      operations?.duplicateAsDraft.mutate,
      status,
    ],
  );

  return {
    mainWorkflowActions,
    menuItemWorkflowActions,
  };
};
