import type {
  RelationshipItemTypeSchema,
  EntityDetailSchemaWithRelationships,
} from '@web-config-app/core';
import { getRelationshipDataSchema } from '../get-relationship-data-schema/get-relationship-data-schema';
import { isRelationshipArrayDataSchema } from '../is-relationship-array-data-schema/is-relationship-array-data-schema';
import { getConstantOrDefaultValueFromSchema } from '../get-constant-or-default-value-from-schema/get-constant-or-default-value-from-schema';

export const getRelationshipSchemaItemType = (
  relationship: string,
  schema: EntityDetailSchemaWithRelationships,
) => {
  const dataSchema = getRelationshipDataSchema(relationship, schema);

  /**
   * Since a relationship's `data` property can be either an object or array in JsonAPI, the relationship's schema
   * will be defined in `.items` for array or simply `dataSchema` for object
   */

  const relationshipItemSchema = isRelationshipArrayDataSchema(dataSchema)
    ? dataSchema.items
    : dataSchema;

  /**
   * JsonAPI uses a `type` that is a constant value.
   */
  const relationshipItemTypeSchema = relationshipItemSchema?.properties
    ?.type as RelationshipItemTypeSchema;

  const itemTypeIsRequired = (relationshipItemSchema?.required ?? []).includes(
    'type',
  );

  const itemTypeValue = getConstantOrDefaultValueFromSchema(
    relationshipItemTypeSchema,
  );

  if (itemTypeIsRequired && !itemTypeValue) {
    // eslint-disable-next-line no-console
    console.error(
      'Relationship schema requires a type but a value could not be computed',
    );
  }

  return {
    type: itemTypeValue ?? '',
    isRequired: itemTypeIsRequired,
  };
};
