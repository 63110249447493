import * as React from 'react';
import * as Toolbar from '@radix-ui/react-toolbar';
import { Editor } from '@tiptap/react';
import { useIntl } from '@leagueplatform/locales';
import { RichtextToolbarButton } from '../richtext-toolbar-button/richtext-toolbar-button.component';
import { RedoIcon, UndoIcon } from '../../../toolbar-icons';

export const UndoRedoRichTextToolbar = ({
  editor,
}: {
  editor: Editor | null;
}): JSX.Element => {
  const { formatMessage } = useIntl();

  return (
    <Toolbar.ToggleGroup
      type="multiple"
      aria-label="History"
      className="toolbar-toggle-group"
    >
      <RichtextToolbarButton
        as={Toolbar.ToggleItem}
        onClick={() => editor?.chain().focus().undo().run()}
        disabled={!editor?.can().undo()}
        icon={<UndoIcon />}
        label={formatMessage({ id: 'UNDO_LABEL' })}
      />
      <RichtextToolbarButton
        as={Toolbar.ToggleItem}
        onClick={() => editor?.chain().focus().redo().run()}
        disabled={!editor?.can().redo()}
        icon={<RedoIcon />}
        label={formatMessage({ id: 'REDO_LABEL' })}
      />
    </Toolbar.ToggleGroup>
  );
};
