import { leagueTheme } from '@web-config-app/common';

// TENANT_ID matches sentry path configured in kubernetes repo
export const TENANT_ID = 'content-and-config-tool';

export const APP_ID = `${TENANT_ID}-web`;

const { VITE_ENV, VITE_AUTH0_DOMAIN, VITE_AUTH0_CLIENT_ID } = import.meta.env;

const customMethodsConfig = {
  handleLink: async (url: string) => {
    await window.open(url, '_blank', 'noreferrer');
  },
};

export const commonCoreConfig = {
  tenantId: TENANT_ID,
  clientId: APP_ID,
  customMethods: customMethodsConfig,
  routing: { isUsingLeagueRouting: true },
  ui: {
    theme: leagueTheme,
    themeOptions: {
      useRems: true,
      noLegacyTheming: true,
    },
  },
};

export const commonCoreAuthClientOptionsDomain = VITE_AUTH0_DOMAIN;
export const commonCoreAuthClientOptionsClientId = VITE_AUTH0_CLIENT_ID;
export const commonCoreAuthClientOptionsRedirectUri = window.location.origin;
export const commonAppEnvironment = VITE_ENV;
