import { globalCss } from '@leagueplatform/genesis-core';

export const globalStyles = globalCss({
  html: {
    fontSize: '$bodyOne',
  },
  body: {
    margin: 0,
  },
  ul: {
    paddingInlineStart: 0,
    listStyleType: 'none',
  },
});
