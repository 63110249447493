import { Level } from '@tiptap/extension-heading';
import { Editor } from '@tiptap/react';

export const blockElementToName = {
  paragraph: 'NORMAL_LABEL',
  h1: 'HEADING_1_LABEL',
  h2: 'HEADING_2_LABEL',
  h3: 'HEADING_3_LABEL',
  h4: 'HEADING_4_LABEL',
  h5: 'HEADING_5_LABEL',
  h6: 'HEADING_6_LABEL',
  orderedList: 'NUMBER_LIST_LABEL',
  bulletList: 'BULLET_LIST_LABEL',
  blockquote: 'QUOTE_LABEL',
};

export type BlockElementType = keyof typeof blockElementToName;

export type FormatBlockElementProps = {
  newBlockElement: BlockElementType;
  editor: Editor | null;
};

export type FormatterProps = {
  editor: Editor;
  headingLevel?: Level;
};

export type BlockFormatter = ({ editor, headingLevel }: FormatterProps) => void;

// Editor can be null as we want to render the toolbar before the editor is created by useEditor - this allows the toolbar to be rendered early on the page
export type ToolbarProps = {
  editor: Editor | null;
  readOnly: boolean;
};
