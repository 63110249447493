// The combinatorSelector is the and / or that is in the rule /group header
import React from 'react';
import {
  StackLayout,
  ChoiceButton,
  Fieldset,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { ValueSelectorProps } from 'react-querybuilder';
import { generateFormComponentId } from '../../utils/generate-form-component-id.util';

export const CombinatorSelector = ({
  handleOnChange,
  options,
  title,
  value,
  level,
  path,
}: ValueSelectorProps) => {
  const { formatMessage } = useIntl();
  const uniqueId = generateFormComponentId(title, level, path);
  return (
    <Fieldset
      key={value}
      legend={formatMessage({ id: 'COMBINATOR_SELECTOR_LABEL' })}
      data-testid="combinator-selector"
      hideLegend
    >
      <StackLayout spacing="$half" orientation="horizontal">
        {options.map((option: { name?: string }) => (
          <ChoiceButton
            key={`${option.name}-${uniqueId}`}
            label={formatMessage({ id: option.name?.toUpperCase() })}
            inputType="radio"
            name={`${title}-${uniqueId}`}
            id={`${option.name}-${uniqueId}`}
            checked={value === option.name}
            onChange={() => handleOnChange(option.name)}
            css={{
              '.GDS-choicebutton-label': { padding: '$half' },
            }}
          />
        ))}
      </StackLayout>
    </Fieldset>
  );
};
