import { setConfig, LeagueConfig } from '@leagueplatform/config';
import { mockConfig } from './mocks/mock-config';

interface SetupTestsConfig {
  initConfig?: LeagueConfig;
}

export const testConfig = {
  ...mockConfig,
  core: { ...mockConfig.core },
};

export function setupTests({ initConfig = testConfig }: SetupTestsConfig = {}) {
  // TODO: copy over server/wsServer stuff in CACT-657
  // wsServer.listen(initConfig.core.api.wsUrl);
  setConfig(initConfig);

  // beforeAll(() => {
  //   server.listen();
  // });

  // afterAll(() => {
  //   wsServer.close();
  //   server.close();
  // });
}
