// utility used to check whether a string matches the dataPoint format - checks for pattern of letters or underscores before / after a dot (or multiple dots) ex abc.goo_bar.test , but a float number would fail

// used for the value input - to warn user's for when it looks like they are trying to enter a dataPoint but it is not actually a data point

export const dataPointRegexMatch = (textInputValue: string): boolean => {
  const dataPointRegex =
    /^(?!^\d+\.\d*$)[a-zA-Z0-9!@#$%^&*()[\]{}\-_=+\\|;:‘“,.<>?`~/]+(\.[a-zA-Z0-9!@#$%^&*()[\]{}\-_=+\\|;:’“,.<>?`~/]+)+$/;

  const dataPointFormat = dataPointRegex.test(textInputValue);

  return dataPointFormat;
};
