import jp from 'jsonpath';
import { EntityRootData } from '@web-config-app/core';
import { cloneDeep } from 'lodash-es';

/**
 * This functions helps to override the entity internal name
 * @param data - entity root data
 * @param entityNamePath - the json name path to add prefix to
 * @param prefix - the prefix value to add to the entity name
 * @returns - updated entity root data
 */
export const duplicateEntityAndPrefixName = ({
  data,
  entityNamePath,
  prefix,
}: {
  data?: EntityRootData;
  entityNamePath?: string; // JSON path
  prefix: string;
}) => {
  if (typeof entityNamePath === 'undefined') {
    return data;
  }

  if (typeof data === 'undefined') {
    return undefined;
  }

  // use `value` function from `jsonpath` to get the instance Name at the path specified
  const instanceName = jp.value(data, entityNamePath);

  // Build a new name from the prefix
  const newName = `${prefix} ${instanceName}`;

  // Need to clone the data object because .value will alter the current data which updates the current entity and we don't want that.
  const isolatedData = cloneDeep(data);
  jp.value(isolatedData, entityNamePath, newName);

  return isolatedData as EntityRootData;
};
