import type {
  AnnotatedJsonSchema,
  SchemaTransformerArgs,
} from '@web-config-app/core';

/**
 * This strips out some tags from stoplight that we don't use and just clutters up the data when inspecting
 */

export const removeExtraAnnotations = ({ schema }: SchemaTransformerArgs) => {
  const allPropertyNames = Object.keys(schema);
  const cleanedPropertyNames = allPropertyNames.filter(
    (propertyName: string) =>
      !['x-stoplight', 'x-tags', 'x-examples'].includes(propertyName),
  );

  if (allPropertyNames.length === cleanedPropertyNames.length) {
    return schema;
  }

  const cleanedSchema: AnnotatedJsonSchema = {};

  cleanedPropertyNames.forEach((propertyName: string) => {
    const propertyNameKey = propertyName as keyof AnnotatedJsonSchema;
    cleanedSchema[propertyNameKey] = schema?.[propertyNameKey];
  });

  return cleanedSchema;
};
