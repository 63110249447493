import * as React from 'react';
import { Button } from '@leagueplatform/genesis-core';
import { ArrayAddButtonProps } from '../../types/components';

/**
 This component contains the "Add [item]" button 
 */

export const ArrayAddButton = ({
  addButtonLabel,
  addItem,
  path,
  createDefaultValue,
}: ArrayAddButtonProps) => (
  <Button
    icon="interfacePlus"
    size="medium"
    priority="secondary"
    onClick={() => addItem(path, createDefaultValue())()}
    css={{ marginTop: '$one' }}
  >
    {addButtonLabel}
  </Button>
);
