import * as React from 'react';
import { useIntl } from 'react-intl';
import * as Toolbar from '@radix-ui/react-toolbar';
import { RichtextToolbarButton } from '../richtext-toolbar-button/richtext-toolbar-button.component';
import {
  OrderedListIcon,
  QuoteIcon,
  UnorderedListIcon,
} from '../../../toolbar-icons';
import {
  formatBlockQuote,
  formatOrderedList,
  formatBulletList,
} from '../../../utils/format-helpers.util';
import {
  ToolbarProps,
  BlockElementType,
} from '../../../rich-text-editor.types';

const layoutElements: BlockElementType[] = [
  'bulletList',
  'orderedList',
  'blockquote',
];

export const LayoutRichtextToolbar = ({
  editor,
  readOnly,
}: ToolbarProps): JSX.Element => {
  const { formatMessage } = useIntl();

  const [selectedValue, setSelectedValue] = React.useState<
    (typeof layoutElements)[number] | undefined
  >(undefined);

  React.useLayoutEffect(() => {
    const updateSelectedValue = () => {
      const activeType = layoutElements.find((type) => editor?.isActive(type));
      setSelectedValue(activeType);
    };
    updateSelectedValue();
    editor?.on('update', updateSelectedValue);
    return () => {
      editor?.off('update', updateSelectedValue);
    };
  }, [editor]);

  return (
    <Toolbar.ToggleGroup
      type="single"
      aria-label="Layout style"
      role="radiogroup"
      className="toolbar-toggle-group"
      value={selectedValue}
      onValueChange={(value: any) => setSelectedValue(value)}
    >
      <RichtextToolbarButton
        as={Toolbar.ToggleItem}
        onClick={() => formatBulletList({ editor })}
        label={formatMessage({ id: 'BULLET_LIST_LABEL' })}
        icon={<UnorderedListIcon />}
        active={editor?.isActive('bulletList')}
        disabled={readOnly}
        value="bulletList"
      />
      <RichtextToolbarButton
        as={Toolbar.ToggleItem}
        onClick={() => formatOrderedList({ editor })}
        label={formatMessage({ id: 'NUMBER_LIST_LABEL' })}
        icon={<OrderedListIcon />}
        active={editor?.isActive('orderedList')}
        disabled={readOnly}
        value="orderedList"
      />
      <RichtextToolbarButton
        as={Toolbar.ToolbarToggleItem}
        onClick={() => formatBlockQuote({ editor })}
        label={formatMessage({ id: 'QUOTE_LABEL' })}
        icon={<QuoteIcon />}
        active={editor?.isActive('blockquote')}
        disabled={readOnly}
        value="blockquote"
      />
    </Toolbar.ToggleGroup>
  );
};
