import * as React from 'react';
import { DomainNav } from '@web-config-app/core-react-ui';
import { useConfigApp, useConfigAppParams } from '@web-config-app/core-react';
import { Category } from '@web-config-app/core';

export const DomainNavContainer = () => {
  const { domains } = useConfigApp();
  const { domainId } = useConfigAppParams();

  const navigationDomains = domains
    .filter(
      (domain) =>
        domain.category !== Category.Hidden &&
        !domain.entities.every((entity) => entity.category === Category.Hidden),
    )
    .map((domain) => ({
      ...domain,
      entities: domain.entities.filter(
        (entity) => entity.category !== Category.Hidden,
      ),
    }));

  const navDomains = navigationDomains.map((domain) => ({
    ...domain,
    domainPath: `/domains/${domain.id}`,
    isActive: domainId === domain.id,
  }));

  return <DomainNav domains={navDomains} />;
};
