import { GDSDecorativeTokens } from '@leagueplatform/genesis-core';

const decorative: GDSDecorativeTokens = {
  brand: {
    primary: {
      darkest: '#250D53',
      dark: '#3E16A3',
      default: '#501CD2',
      bright: '#B9A4ED',
      brightest: '#DCD2F6',
      pastel: '#EDE8FA',
    },
    secondary: {
      darkest: '#15846D',
      dark: '#00A886',
      default: '#01C3A2',
      bright: '#A5E7DA',
      brightest: '#D2F3EC',
      pastel: '#E8F8F5',
    },
    tertiary: {
      darkest: '#15846D',
      dark: '#00A886',
      default: '#01C3A2',
      bright: '#A5E7DA',
      brightest: '#D2F3EC',
      pastel: '#E8F8F5',
    },
  },
};

export const decorativeTokens = {
  decorative,
};
