import { useMemo } from 'react';
import { transformAndFlattenDataPoints } from '../utils/transform-and-flatten-data-points.util';
import { useGetSelectedFlow } from './use-get-selected-flow.hook';

export const useGetDataPoints = () => {
  const { isError, isLoading, selectedFlow } = useGetSelectedFlow();

  const dataPoints = useMemo(
    () => transformAndFlattenDataPoints(selectedFlow?.dataPoints),
    [selectedFlow],
  );

  return { isError, isLoading, dataPoints };
};
