import * as React from 'react';
import { Button, StackLayout } from '@leagueplatform/genesis-core';
import { ErrorState } from '@leagueplatform/web-common-components';
import { Link } from '@leagueplatform/routing';
import { useIntl } from '@leagueplatform/locales';

export function ErrorPage() {
  const { formatMessage } = useIntl();
  return (
    <StackLayout
      css={{
        height: '100vh',
      }}
      horizontalAlignment="center"
      verticalAlignment="center"
      spacing="$one"
    >
      <ErrorState headingId="SORRY_WE_CANT_FIND_PAGE" />
      <Button as={Link} to="/" priority="secondary">
        {formatMessage({ id: 'RETURN_HOME' })}
      </Button>
    </StackLayout>
  );
}
