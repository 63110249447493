import type {
  SchemaTransformer,
  SchemaTransformerArgs,
} from '@web-config-app/core';

/**
 * Takes an array of transformer functions that operate on the schema and conditionally
 * operate on and modify the schema before returning it and passing it on to the next
 * transformer function.
 */
const composeTransformers =
  (...transformers: SchemaTransformer[]) =>
  ({ schema, data, options }: SchemaTransformerArgs) =>
    transformers.reduceRight(
      (prevResult, transformer) =>
        transformer({ schema: prevResult, data, options }),
      schema,
    );

export const applySchemaTransformers = (
  schemaTransformerArgs: SchemaTransformerArgs,
  transformers: SchemaTransformer[],
) => composeTransformers(...transformers)(schemaTransformerArgs);
