import * as React from 'react';
import * as Toolbar from '@radix-ui/react-toolbar';
import { useIntl } from '@leagueplatform/locales';
import { RichtextToolbarButton } from '../richtext-toolbar-button/richtext-toolbar-button.component';
import { BoldIcon, ItalicIcon } from '../../../toolbar-icons';
import { LinkEditPopover } from '../link-edit-popover/link-edit-popover.component';
import { ToolbarProps } from '../../../rich-text-editor.types';

export const TextFormatRichtextToolbar = ({
  editor,
  readOnly,
}: ToolbarProps): JSX.Element => {
  const { formatMessage } = useIntl();

  return (
    <Toolbar.ToggleGroup
      type="multiple"
      aria-label="Text formatting"
      className="toolbar-toggle-group"
    >
      <RichtextToolbarButton
        as={Toolbar.ToggleItem}
        onClick={() => editor?.chain().focus().toggleBold().run()}
        icon={<BoldIcon />}
        label={formatMessage({ id: 'BOLD_LABEL' })}
        active={editor?.isActive('bold')}
        disabled={readOnly}
      />
      <RichtextToolbarButton
        as={Toolbar.ToggleItem}
        onClick={() => editor?.chain().focus().toggleItalic().run()}
        icon={<ItalicIcon />}
        label={formatMessage({ id: 'ITALIC_LABEL' })}
        active={editor?.isActive('italic')}
        disabled={readOnly}
      />
      <LinkEditPopover editor={editor} readOnly={readOnly} />
    </Toolbar.ToggleGroup>
  );
};
