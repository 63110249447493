import { KeywordDefinition } from 'ajv';
import { isAfter, addDays } from 'date-fns';

export function validateAfterDate(
  compareDateValue?: string,
  fieldDateValue?: string,
) {
  if (
    typeof compareDateValue === 'string' &&
    typeof fieldDateValue === 'string'
  ) {
    const fieldDate = new Date(fieldDateValue);
    /**
     * Checks the value of the date against some day relative to the current date
     */
    if (compareDateValue?.indexOf('$today') === 0) {
      const [, relativeDaysString] = compareDateValue.split(':');
      const relativeDays: number = relativeDaysString
        ? parseInt(relativeDaysString, 10)
        : 0;
      const now = new Date();
      const compareRelativeDate = addDays(now, relativeDays);
      return isAfter(fieldDate, compareRelativeDate);
    }

    /**
     * Check the value of the property against the JSON pointer to another property's value
     */

    const compareJSONPointerDate = new Date(compareDateValue);
    return isAfter(fieldDate, compareJSONPointerDate);
  }

  return false;
}

export const afterDate: KeywordDefinition = {
  keyword: 'afterDate',
  type: 'string',
  schemaType: ['string', 'object'],
  $data: true,
  validate: validateAfterDate,
};
