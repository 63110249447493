import type {
  AnnotatedJsonSchema,
  EntityDetailSchema,
  SchemaTransformer,
  SchemaTransformerArgs,
} from '@web-config-app/core';
import { set } from 'lodash-es';
import {
  isCombinatorArraySchema,
  isCombinatorObjectSchema,
} from '@web-config-app/schema-utils';
import { applySchemaTransformers } from './apply-schema-transformers';
import { removeExtraAnnotations } from './transformers/remove-extra-annotations';
import { hideCombinatorDiscriminatorProperty } from './transformers/hide-combinator-discriminator-property';
import { getCombinatorProperties } from '../get-combinator-properties/get-combinator-properties.util';

type CombinatorKeys = keyof Pick<
  AnnotatedJsonSchema,
  'oneOf' | 'anyOf' | 'allOf'
>;

/**
 * Operates on a single subSchema node. Any non-primitive properties will be
 * recursively passed back into `traverseSchema` to continue traverse throughout
 * the entire schema to return whichever version of the schema corresponds to the
 * current entity data.
 */

const traverseSchema = (
  { schema, data, options }: SchemaTransformerArgs,
  transformers: SchemaTransformer[],
  // path and propertyName are used for composing more usable error
  // messages only and don't affect schema computation.
  // tho we might use them in the future to compute a dynamic property map
  // instead of inefficiently recomputing the whole schema
  path: string = '',
  propertyName: string = '',
) => {
  const computedSchema = { ...schema };
  const currentPath =
    propertyName.length > 0
      ? `${path}${path.length > 0 ? '.' : ''}properties.${propertyName}`
      : path;

  try {
    // currently a weird issue with the entity status being "read only" except
    // that... it's JSON? :0
    // But, it's also fine and I don't *think* we'll need to be transforming
    // `entityMetadata` anytime soon
    if (computedSchema && propertyName !== 'entityMetadata') {
      if (
        schema.type === 'object' &&
        (typeof schema.properties === 'object' ||
          isCombinatorObjectSchema(schema))
      ) {
        if (isCombinatorObjectSchema(schema)) {
          const combinatorKeys: CombinatorKeys[] = ['anyOf', 'oneOf', 'allOf'];
          const { combinatorDiscriminator } =
            getCombinatorProperties(schema) ?? {};

          combinatorKeys.forEach((combinator: CombinatorKeys) => {
            const combinatorItems = schema[combinator];
            if (Array.isArray(combinatorItems)) {
              set(
                computedSchema,
                combinator,
                combinatorItems?.map((itemSchema: AnnotatedJsonSchema) =>
                  traverseSchema(
                    {
                      schema: itemSchema,
                      data,
                      options: { discriminator: combinatorDiscriminator },
                    },
                    transformers,
                    `${currentPath}.${combinator}`,
                    undefined,
                  ),
                ),
              );
            }
          });
        } else {
          const properties: [string, AnnotatedJsonSchema][] = Object.entries(
            schema.properties ?? {},
          ).map(([property, propertySchema]: [string, AnnotatedJsonSchema]) => [
            property,
            traverseSchema(
              {
                schema: propertySchema,
                data,
                options: undefined,
              },
              transformers,
              currentPath,
              property,
            ),
          ]);

          const computedProperties: AnnotatedJsonSchema['properties'] = {};

          properties.forEach(
            ([property, propertySchema]: [string, AnnotatedJsonSchema]) => {
              computedProperties[property] = propertySchema;
            },
          );

          computedSchema.properties = computedProperties;
        }
      } else if (schema.type === 'array') {
        if (isCombinatorArraySchema(schema)) {
          const combinatorKeys: CombinatorKeys[] = ['anyOf', 'oneOf', 'allOf'];
          const { combinatorDiscriminator } =
            getCombinatorProperties(schema) ?? {};
          const schemaItems = schema.items as AnnotatedJsonSchema;

          combinatorKeys.forEach((combinator: CombinatorKeys) => {
            const combinatorItems = schemaItems[combinator];
            if (Array.isArray(combinatorItems)) {
              computedSchema.items = {
                ...computedSchema.items,
                [combinator]: combinatorItems?.map(
                  (itemSchema: AnnotatedJsonSchema) =>
                    traverseSchema(
                      {
                        schema: itemSchema,
                        data,
                        options: { discriminator: combinatorDiscriminator },
                      },
                      transformers,
                      `${currentPath}.items.${combinator}`,
                      undefined,
                    ),
                ),
              };
            }
          });
        } else {
          computedSchema.items = traverseSchema(
            {
              schema: schema.items as AnnotatedJsonSchema,
              data,
              options: undefined,
            },
            transformers,
            `${currentPath}.items`,
            undefined,
          );
        }
      }
    }
  } catch (err) {
    console.error(
      `Error traversing schema at ${currentPath} for property ${propertyName}`,
      err,
    );
  }

  /**
   * Apply the passed array of transformations to current property's sub-schema. The
   * transforms are performed in the same order as the passed array and are passed
   * the result of the previous transform.
   */

  return applySchemaTransformers(
    { schema: computedSchema, data, options },
    transformers,
  );
};

/**
 * Since the Entity schema includes annotations that can alter its shape (via, for example,
 * conditional fields) based on associated data, we need to dynamically compute the schema
 * before passing it forms and validation.
 *
 * The source schema is traversed recursively and each property sub-schema is recomputed
 * according to any annotations present using the current entity data as a secondary input.
 *
 * @param sourceSchema - the source schema that implements or extends the {@link AnnotatedJsonSchema} interface
 * @param data - data that corresponds to the schema
 * @param transformers - (optional) an array of custom {@link SchemaTransformer} functions
 * @returns {@link AnnotatedJsonSchema} (or extension interface)
 */

export const computeSchema = (
  sourceSchema: EntityDetailSchema,
  data: any,
  transformers?: SchemaTransformer[],
): EntityDetailSchema => {
  const schema = { ...sourceSchema };
  return traverseSchema(
    { schema, data, options: undefined },
    transformers ?? [
      /**
       * More efficient if `removeExtraAnnotations` is done only once, in the config generating script.
       * TODO: https://everlong.atlassian.net/browse/CACT-1296 move this out o
       */
      removeExtraAnnotations,
      hideCombinatorDiscriminatorProperty,
    ],
  ) as EntityDetailSchema;
};
