import { useState } from 'react';
import { InputStatusMsgTypes } from '../types/status-message.types';
import { findDataPoint } from '../utils/find-data-point.util';
import { dataPointRegexMatch } from '../utils/data-point-regex-match.utils';
import { useGetDataPoints } from './use-get-data-points.hook';

export interface ValidateDataPointsProps {
  textInputValue: string | undefined;
  mustBeDataPoint: boolean;
  // validateDataPointOn boolean implemented Sept 2023 so we can turn off validation to allow for user's to make rules with dataPoints outside of the single (league staging) environment CTA-1278
  validateDataPointOn?: boolean;
}

export const useValidateDataPoints = () => {
  // At this time (July 2023), we're only supporting the Health Campaigns LFRS flow.
  // useGetDataPoints hook is automatically setting the selected flow in this instance to be 'health_campaigns_lfrs_flow'
  // this will need to be revisited in the future, when more flows are supported and requirements around what flows a user has access to within the "Flow Builder" changes
  const { dataPoints } = useGetDataPoints();

  const [isValueDataPoint, setIsValueDataPoint] = useState(false);

  const [inputStatusMsg, setInputStatusMsg] =
    useState<InputStatusMsgTypes | null>(null);

  const checkValueForDataPoint = (value = '') => {
    const validDataPoint = findDataPoint(value, dataPoints);
    setIsValueDataPoint(!!validDataPoint);
  };

  const getDataType = (value: string) => {
    const validDataPoint = findDataPoint(value, dataPoints);
    const dataType = validDataPoint
      ? validDataPoint.dataType || 'string'
      : undefined;
    return dataType;
  };

  const validateInputMsg = ({
    textInputValue,
    mustBeDataPoint,
    validateDataPointOn = true,
  }: ValidateDataPointsProps) => {
    if (!textInputValue || !validateDataPointOn) {
      setInputStatusMsg(null);
      return;
    }

    const dataPointFormat = dataPointRegexMatch(textInputValue);

    const validDataPoint = findDataPoint(textInputValue, dataPoints);

    if (mustBeDataPoint) {
      if (!textInputValue) return;
      const status = validDataPoint ? 'success' : 'error';
      const msg = validDataPoint
        ? 'VALID_DATA_POINT'
        : 'FLOW_RULE_ENGINE_VALIDATION_INVALID_DATA_POINT';
      setInputStatusMsg({
        status,
        msg,
      });
    } else {
      if (!dataPointFormat) {
        setInputStatusMsg(null);
        return;
      }
      const status = dataPointFormat && !validDataPoint ? 'warning' : 'success';
      const msg =
        dataPointFormat && !validDataPoint
          ? 'NOT_VALID_DATA_POINT'
          : 'VALID_DATA_POINT';
      setInputStatusMsg({
        status,
        msg,
      });
    }
  };

  return {
    validateInputMsg,
    inputStatusMsg,
    setInputStatusMsg,
    getDataType,
    isValueDataPoint,
    checkValueForDataPoint,
  };
};
