import type { AnnotatedJsonSchema } from '@web-config-app/core';
import { getConstantOrDefaultValueFromSchema } from '@web-config-app/schema-utils';

const traverseProperties = (
  schema: AnnotatedJsonSchema,
  isRequired: boolean = false,
) => {
  const defaultObjectValue: { [key: string]: any } = {};
  const objectValues: { [key: string]: any } = {};

  switch (schema.type) {
    case 'object':
      /**
       * Step 1: traverse the entire object and apply default values according to the rules in this switch.
       * This will recursively traverse the down to the depths of a deeply nested property and then make it's
       * way back up, returning defaults for that level of the object __including__ `undefined`. So that means that
       * objectValues could look like:
       *
       * @example
       *
       * {
       *   type: 'special-thing' // string with a default/const/enum
       *   name: undefined, // string with no default/const/enum
       *   isEnabled: false,
       *   description: undefined,
       *   etc: undefined
       * }
       */
      Object.entries(schema.properties ?? {}).forEach(
        ([property, propertySchema]) => {
          objectValues[property] = traverseProperties(
            propertySchema,
            schema.required?.includes(property),
          );
        },
      );
      /**
       * Step 2: at each object nesting level, check to see which properties
       * in `objectValues` have a value !== undefined. If (and only if) there's a value then we
       * set `defaultObjectValue[key]` to that value! So, the resulting `defaultObjectValue`
       * if we use the same example as Step 1. Note that any undefined properties are
       * OMITTED from the final `defaultObjectValue`
       *
       * @example
       *
       * {
       *   type: 'special-thing',
       *   isEnabled: false,
       * }
       *
       */
      Object.keys(objectValues).forEach((key) => {
        const val = objectValues[key];
        if (typeof val !== 'undefined') {
          defaultObjectValue[key] = val;
        }
      });
      /**
       * Only return `defaultObjectValue` is it has any properties with values
       */
      if (Object.keys(defaultObjectValue).length > 0) {
        return defaultObjectValue;
      }

      if (isRequired) return {};

      // This will probably be `undefined`. Can object properties HAVE a default?
      return schema.default;

    case 'array':
      /**
       * We need to ensure that any nested arrays have a default value of empty array. JsonForms assumes
       * that any array control is operating on an existing array, so the array being `undefined` results
       * in both a validation error ("must be array") and a TypeError when trying to add an item to the
       * array (since you can't call .push() on `undefined`!)
       */
      return [];
    case 'boolean':
      if (schema.default) return schema.default;
      /**
       * If a boolean is required, allowing `undefined` as a value will produce a suboptimal authoring experience
       * since the checkbox/toggle will APPEAR to have the value `false` but the real value does not exist which
       * will result in a validation error + need to check then UNcheck the box to achieve the desired value.
       *
       * This presumes that it's ok to send undefined for a boolean that is not required.
       */
      return isRequired ? false : undefined;
    case 'string':
      if (schema.enum?.length === 1 || schema.const)
        return getConstantOrDefaultValueFromSchema(schema);
      return schema.default;
    default:
      /**
       * Explicitly not handling types with the assumption that these do not require defaults
       * - number
       * - integer
       * - null
       */
      return schema.default;
  }
};

export function createDefaultValueForSchema<T>(
  schema: AnnotatedJsonSchema,
): Partial<T> {
  const defaultValue: Partial<T> = traverseProperties(schema);

  return defaultValue;
}
