import { useMemo } from 'react';
import { findDataPoint } from '../utils/find-data-point.util';
import { FlatDataPoint } from '../types/flat-data-point.types';
import { DEFAULT_DATA_TYPE } from '../constants/data-types.constants';
import { useGetDataPoints } from './use-get-data-points.hook';

/*
    Components within a Rule will be passed their parent `rule` as a prop by default.
    This hook relies on the `field` value of that `rule` prop within the Operator and
    Value fields to asynchronously update the value of the Data Point field.
    Steps are taken with `useMemo` to prevent unnecessary rerunning.

    See React Query Builder docs for more context on the `rule` passing:
    https://react-querybuilder.js.org/docs/components/querybuilder#controlelements
 */

export const useGetDataType = (fieldValue: string = '') => {
  const { dataPoints } = useGetDataPoints();

  const dataType: string | undefined = useMemo(() => {
    if (!fieldValue) return undefined;

    const dataPoint: FlatDataPoint | undefined = findDataPoint(
      fieldValue,
      dataPoints,
    );

    return !dataPoint?.dataType ? DEFAULT_DATA_TYPE : dataPoint.dataType;
  }, [fieldValue, dataPoints]);

  return {
    dataType,
  };
};
