import { useCallback } from 'react';
import { useEntityDetailsProps } from '../use-entity-details-props/use-entity-details-props';

export const useObjectCardLink = () => {
  const entityDetailProps = useEntityDetailsProps();
  const currentPath = entityDetailProps?.formPath;

  const getLinkForObjectCard = useCallback(
    (objectSubPath: string) => {
      const newPath = currentPath
        ? `${currentPath}.${objectSubPath}`
        : objectSubPath;
      return `?path=${newPath}`;
    },
    [currentPath],
  );

  return { getLinkForObjectCard };
};
