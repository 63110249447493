import * as React from 'react';
import * as Toolbar from '@radix-ui/react-toolbar';
import { UndoRedoRichTextToolbar } from './components/undo-redo-richtext-toolbar/undo-redo-rich-text-toolbar.component';
import { BlockElementDropdownRichtextToolbar } from './components/block-element-dropdown-richtext-toolbar/block-element-dropdown-richtext-toolbar.component';
import { TextFormatRichtextToolbar } from './components/text-format-richtext-toolbar/text-format-richtext-toolbar.component';
import { LayoutRichtextToolbar } from './components/layout-richtext-toolbar/layout-richtext-toolbar.component';
import { ToolbarProps } from '../rich-text-editor.types';

export const RichTextEditorToolbar = ({
  editor,
  readOnly,
}: ToolbarProps): JSX.Element => (
  <Toolbar.Root className="toolbar-root">
    <UndoRedoRichTextToolbar editor={editor} />
    <Toolbar.Separator className="toolbar-separator" />
    <BlockElementDropdownRichtextToolbar editor={editor} readOnly={readOnly} />
    <Toolbar.Separator className="toolbar-separator" />
    <TextFormatRichtextToolbar editor={editor} readOnly={readOnly} />
    <Toolbar.Separator className="toolbar-separator" />
    <LayoutRichtextToolbar editor={editor} readOnly={readOnly} />
  </Toolbar.Root>
);
