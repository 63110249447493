import * as React from 'react';
import { Redirect, useParams } from '@leagueplatform/routing';
import { useDomain, useAppRedirectPaths } from '@web-config-app/core-react';

/**
 * We aren't building a page at the domain-level yet so for the time being
 * return a Redirect to the domain's first entity in the `entities` map
 */

export const DomainPage = () => {
  const { domainRedirectPath } = useAppRedirectPaths();
  const { domainId } = useParams<{ domainId: string }>();
  const domain = useDomain(domainId);

  return <Redirect to={domainRedirectPath(domain)} />;
};

export default DomainPage;
