import { ConfigErrorType } from '../types/errors';

/**
 * Returns the ConfigErrorType based on the api error response - http status
 */
export const mapStatusToErrorType = (
  status: number,
): ConfigErrorType | null => {
  if (status >= 500) {
    return 'server-error-response';
  }
  if (status >= 400) {
    return 'client-error-response';
  }
  return null;
};
