import type { GDSStatus } from '@leagueplatform/genesis-core';
import { EntityStatus } from '../types/entities';

interface EntityStatusBadge {
  genesisStatus: GDSStatus;
  labelKey: string;
}

const defaultStatus: EntityStatusBadge = {
  genesisStatus: 'neutral',
  labelKey: 'DEFAULT',
};

export const entityStatusToGenesisStatusMap: Record<
  EntityStatus,
  EntityStatusBadge
> = {
  published: { genesisStatus: 'success', labelKey: 'PUBLISHED' },
  archived: { genesisStatus: 'neutral', labelKey: 'ARCHIVED' },
  draft: { genesisStatus: 'info', labelKey: 'DRAFT' },
  changed: { genesisStatus: 'success', labelKey: 'PUBLISHED' },
  new: { genesisStatus: 'brand', labelKey: 'NEW' },
  default: defaultStatus,
};

export const getEntityStatusBadgeProps = (status?: EntityStatus | string) =>
  entityStatusToGenesisStatusMap[status as EntityStatus] ?? defaultStatus;
