import { leagueFetch } from '@leagueplatform/league-fetch';
import { GET_FLOW_CONTRACT_PATH } from '../../constants/requests.constants';
import { FlowContractResponseData } from '../../types/flow-contract.types';
import {
  MOCK_API_PATHS,
  isMockEnabled,
} from '../../utils/is-mock-enabled.utils';
import { mockFlowContractResponse } from '../../__mocks__/mock-flow-contract-response.mock';

export const getFlowContract = async (): Promise<FlowContractResponseData> => {
  if (isMockEnabled(MOCK_API_PATHS.GET_FLOW_CONTRACT)) {
    return new Promise((resolve) =>
      // eslint-disable-next-line no-promise-executor-return
      setTimeout(() => resolve(mockFlowContractResponse), 1000),
    );
  }

  const response = await leagueFetch(GET_FLOW_CONTRACT_PATH);
  const responseJson: FlowContractResponseData = await response.json();

  if (response.ok) {
    return responseJson;
  }

  throw new Error('Something went wrong while fetching!');
};
