export const HIGHMARK_STRINGS_EN = {
  /* Rules template names */
  HIGHMARK_ACTIVITY_ELIGIBILITY_MEDICAL: 'Medical',
  HIGHMARK_ACTIVITY_ELIGIBILITY_ASSOCIATE: 'Associate',
  HIGHMARK_ACTIVITY_ELIGIBILITY_SWORDMSK: 'SwordMsk',
  HIGHMARK_ACTIVITY_ELIGIBILITY_HEALTHMAPCKDSECONDPHASE:
    'HealthmapCKDSecondPhase',
  HIGHMARK_ACTIVITY_ELIGIBILITY_ONDUOT2D: 'OnduoT2D',
  HIGHMARK_ACTIVITY_ELIGIBILITY_LARK_HTN: 'Lark HTN',
  HIGHMARK_ACTIVITY_ELIGIBILITY_LARK_WELLNESS: 'Lark Wellness',
  HIGHMARK_ACTIVITY_ELIGIBILITY_LARK_HTN_OR_LARK_WELLNESS:
    'Lark HTN or Lark Wellness',
  HIGHMARK_ACTIVITY_ELIGIBILITY_MALE: 'Male',
  HIGHMARK_ACTIVITY_ELIGIBILITY_FEMALE: 'Female',
  HIGHMARK_ACTIVITY_ELIGIBILITY_MEDICAL_AND_FEMALE: 'Medical and Female',
  HIGHMARK_CAMPAIGN_ELIGIBILITY_REWARD_PROGRAM_SEGMENTATION:
    'Reward Program Segmentation',
  HIGHMARK_CAMPAIGN_ELIGIBILITY_GROUP_CHALLENGE_SEGMENTATION:
    'Group Challenge Segmentation',
  HIGHMARK_CAMPAIGN_ELIGIBILITY_HEALTH_ASSESSMENT: 'Health Assessment',
  HIGHMARK_CAMPAIGN_ELIGIBILITY_CHALLENGE_WITH_CLIENT_AND_GROUP_SEGMENTATION:
    'Challenge with client_id and group_id segmentation',
};
