import * as React from 'react';
import { TextInput } from '@leagueplatform/genesis-core';
import { ControlComponent } from '../../../types/controls';
import { EntityFormControl } from '../../entity-form-control/entity-form-control.component';

export const DateInputControl: ControlComponent = (props) => (
  <EntityFormControl
    {...props}
    renderControl={({
      data,
      handleChange,
      path,
      inputStatus,
      enabled,
      required,
    }) => (
      <TextInput
        type="date"
        value={data ?? ''}
        readOnly={!enabled}
        required={required}
        clearable={enabled && data && data.length > 0}
        onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
          handleChange(path, evt.currentTarget.value)
        }
        onClear={() => handleChange(path, undefined)}
        inputStatus={inputStatus}
        css={{
          backgroundColor: '$inputBackgroundDefault',
        }}
      />
    )}
  />
);
