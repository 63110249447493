import { cloneDeep } from 'lodash-es';
import type { EntityDetailSchema } from '@web-config-app/core';

/**
 * Applies some default annotations to schemas to simplify the schema authoring process and
 * avoid requiring manual annotation for things that apply to every entity.
 */

export const applyDefaultSchemaAnnotations = (schema: EntityDetailSchema) => {
  /**
   * setup a fresh reference to avoid mutating the original
   */
  const clonedSchema: EntityDetailSchema = cloneDeep(schema);

  /**
   * Automatically apply the x-entity-presentation.hidden annotation to the entire
   * entityMetadata property. These properties are not intended to be edited by content authors
   * directly as they are part of the publishing workflow and should only be modified when
   * performing a workflow operation outside of the Entity Form
   */

  const { entityMetadata: entityMetadataSchema } =
    schema.properties.attributes.properties;

  const hiddenEntityMetadataSchema = {
    ...entityMetadataSchema,
    'x-entity-presentation': {
      ...entityMetadataSchema['x-entity-presentation'],
      hidden: true,
    },
  };

  clonedSchema.properties.attributes.properties.entityMetadata =
    hiddenEntityMetadataSchema;

  return clonedSchema;
};
