import * as React from 'react';
import * as Toolbar from '@radix-ui/react-toolbar';
import {
  Button,
  FormField,
  Popover,
  StackLayout,
  TextInput,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { RichtextToolbarButton } from '../richtext-toolbar-button/richtext-toolbar-button.component';
import { InsertLinkIcon } from '../../../toolbar-icons';
import { ToolbarProps } from '../../../rich-text-editor.types';

export const LinkEditPopover = ({
  editor,
  readOnly,
}: ToolbarProps): JSX.Element => {
  const { formatMessage } = useIntl();

  const [linkUrl, setLinkUrl] = React.useState<string>('');

  const [popoverOpen, setPopoverOpen] = React.useState(false);

  const updateSelectedValue = React.useCallback(() => {
    const hasLink = editor?.isActive('link') || false;
    setPopoverOpen(hasLink);

    if (hasLink) {
      setLinkUrl(editor?.getAttributes('link').href);
    } else {
      setLinkUrl('');
    }
  }, [editor]);

  React.useEffect(() => {
    editor?.on('selectionUpdate', updateSelectedValue);
    return () => {
      editor?.off('selectionUpdate', updateSelectedValue);
    };
  }, [editor, updateSelectedValue]);

  // Link Change Handler
  const handleLinkChange = () => {
    if (linkUrl) {
      editor?.commands.setLink({ href: linkUrl });
    }
    setPopoverOpen(false);
  };

  // Insert Link
  const insertLink = React.useCallback(() => {
    setLinkUrl('https://');
    editor?.commands.toggleLink({ href: 'https://' });
    setPopoverOpen(true);
  }, [editor]);

  // Remove Link (called by the "Remove" button)
  const removeLink = () => {
    editor?.commands.unsetLink();
    setPopoverOpen(false);
  };

  // Toolbar Link Button Click
  const onToolbarLinkButtonClick = () => {
    if (editor?.isActive('link')) {
      setPopoverOpen(true); // Open popover if a link is selected
    } else {
      insertLink(); // Insert a new link if no link exists
    }
  };

  return (
    <Popover.Root open={popoverOpen}>
      <Popover.Trigger>
        <RichtextToolbarButton
          as={Toolbar.ToggleItem}
          icon={<InsertLinkIcon />}
          label={formatMessage({ id: 'INSERT_LINK_LABEL' })}
          active={editor?.isActive('link')}
          onClick={onToolbarLinkButtonClick}
          disabled={readOnly}
        />
      </Popover.Trigger>
      <Popover.Content
        align="center"
        side="bottom"
        showCloseButton={false}
        css={{ width: '20rem' }}
      >
        <StackLayout horizontalAlignment="center">
          <FormField id="link" label="Insert link" hideLabel>
            <TextInput
              value={linkUrl}
              onChange={(event) => setLinkUrl(event.target.value)}
            />
          </FormField>
          <StackLayout
            orientation="horizontal"
            css={{ marginLeft: 'auto', marginTop: '$quarter' }}
          >
            <Button
              size="toolbar"
              priority="secondary"
              quiet
              destructive
              onClick={removeLink}
            >
              {formatMessage({ id: 'REMOVE' })}
            </Button>
            <Button
              size="toolbar"
              priority="secondary"
              quiet
              onClick={handleLinkChange}
              css={{ marginLeft: '$quarter' }}
            >
              {formatMessage({ id: 'SAVE' })}
            </Button>
          </StackLayout>
        </StackLayout>
      </Popover.Content>
    </Popover.Root>
  );
};
