import * as React from 'react';
import { Rule, RuleProps } from 'react-querybuilder';
import { Box, GDSStyleObject } from '@leagueplatform/genesis-core';
import { ValidatedRule } from '../../hooks/use-validate-rule-query.hook';

export interface SingleRuleProps extends RuleProps {}

const ruleContainerStyles: GDSStyleObject = {
  '.rule': {
    display: 'flex',
    paddingTop: '$one',
  },
};

export const SingleRule = (props: SingleRuleProps) => {
  // Since 'rule' is a required prop defined in 'RuleProps', we will use it to avoid
  // mocking and rendering the Rule component for our tests of focus attributes.
  const { id, rule, context } = props;

  const ruleValidation = context?.invalidRules.find(
    ({ ruleId }: ValidatedRule) => ruleId === id,
  );

  // If the rule shows up in our invalidRules context, give it a critical border
  if (ruleValidation) {
    ruleContainerStyles['> .rule'] = { borderColor: '$criticalBorderDefault' };
  }

  return (
    <Box
      css={ruleContainerStyles}
      data-testid="single-rule-container"
      id={id}
      tabIndex={-1}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      {!!rule && <Rule {...props} />}
    </Box>
  );
};
