import { useCallback } from 'react';
import { useRouteMatch } from '@leagueplatform/routing';
import { getDomainRoute, Domain } from '@web-config-app/core';
import { useConfigApp } from '../use-config-app/use-config-app';

export const useAppRedirectPaths = () => {
  const { domains } = useConfigApp();
  const { url } = useRouteMatch();

  const landingRedirectPath = useCallback(() => {
    const firstDomainId = domains[0]?.id;
    if (!firstDomainId)
      throw new Error(
        'No domains found! Please add a domain to your tenants config object.',
      );
    return `/domains/${firstDomainId}`;
  }, [domains]);

  const domainRedirectPath = useCallback(
    (domain: Domain) => {
      const [firstEntityId] = Array.from(domain?.entities ?? [])[0];

      /**
       * In practice `entities` should not ever be empty so throw an error if it is
       */
      if (!firstEntityId)
        throw new Error(
          `Domain ${domain?.id} does not have any entities. Please add in an entity.`,
        );

      return getDomainRoute(url, firstEntityId);
    },
    [url],
  );

  return {
    landingRedirectPath,
    domainRedirectPath,
  };
};
